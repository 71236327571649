import * as API from '../utilities/Api';

export function All() {
    return API.GET(`suggestionCategory/all`);
}

export function Get(suggestionCategoryID: number) {
    return API.GET(`suggestionCategory/${suggestionCategoryID}`);
}

export function Post(
    name: string
) {
    return API.POST(
        `suggestionCategory`,
        {
            body: JSON.stringify({
                name
            })
        }
    );
}

export function Put(
    suggestionCategoryID: number,
    name: string
) {
    return API.PUT(
        `suggestionCategory/${suggestionCategoryID}`,
        {
            body: JSON.stringify({
                name
            })
        }
    );
}

export function Delete(suggestionCategoryID: number) {
    return API.DELETE(`suggestionCategory/${suggestionCategoryID}`);
}