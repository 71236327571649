import { Permission } from 'permission/Permission';
import * as API from '../utilities/Api';

export function All(
    args?: {
        permissions?: Array<Permission>,
        clientID?: number
    }
) {
    args = {
        permissions: new Array(),
        ...args
    }

    const { permissions } = args as Required<typeof args>;

    const params = new URLSearchParams();
    permissions.forEach(permission => params.append('permissions', permission.toString()));
    args.clientID && params.append('clientID', args.clientID.toString());

    return API.GET(`user/all?${params}`);
}

export function Get(userID: number) {
    return API.GET(`user/${userID}`);
}

export function Post(
    args: {
        username: string,
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        clientID: number
    }
) {
    return API.POST(
        `user`,
        {
            body: JSON.stringify(args)
        }
    );
}

export function Put(
    args: {
        userID: number,
        username: string,
        firstName: string,
        lastName: string,
        email: string,
        password?: string,
        clientID: number,
        active: boolean
    }
) {
    return API.PUT(
        `user/${args.userID}`,
        {
            body: JSON.stringify(args)
        }
    );
}

export function Delete(
    userID: number
) {
    return API.DELETE(`user/${userID}`);
}