import TDataTable, { TableProps } from 'react-data-table-component';
import { useTranslation } from "react-i18next";
export * from 'react-data-table-component';

export type DataTableProps<t> = TableProps<t>;

// for customization
// https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.ts
export default function DataTable<t = any>(props: DataTableProps<t>) {
    const { t } = useTranslation();

    const customStyles = {
        subHeader: {
            style: {
                justifyContent: 'unset',
                paddingLeft: 16
            }
        },
        head: {
            style: {
                color: '#3d6a86',
                fontWeight: '400'
            }
        },
        headRow: {
            style: {
                backgroundColor: '#eef5fc',
                borderBottomWidth: 0,
                fontSize: '14px'
            }
        },
        rows: {
            style: {
                color: '#3d6a86',
                fontWeight: 600,
                fontSize: '14px',
                '&:not(:last-of-type)': {
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '4px',
                    borderBottomColor: '#eef5fc',
                },
            }
        }
    };

    return <div
        className="mb-5"
    >
        <TDataTable 
            customStyles={customStyles}
            noDataComponent={
                <div
                    className="
                        p-6
                        text-[#3d6a86]
                    "
                >
                    {t('dataTable.noData')}
                </div>
            }
            {...props}
        />
    </div>;
};