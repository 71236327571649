import React from 'react'
import { twMerge } from 'tailwind-merge';

export type SelectOptionProps<T> = Omit<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    'value' | 'selected'
> & {
    selectedValue?: T;
    value: T;
}

const SelectOption = <T extends string | number | undefined>({
    selectedValue,
    children,
    value,
    className,
    ...props
}: SelectOptionProps<T>): JSX.Element => {
    return (
        <option
            {...props}
            className={twMerge(
                `bg-white`,
                className
            )}
            value={value}
            selected={value === selectedValue}
        >
            {children}
        </option>
    )
}

export default SelectOption