import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import Box from 'components/Box';
import TextField from 'components/TextField';
import Button from 'components/Button';
import * as LoginEndpoints from './LoginEndpoints';
import Title from 'components/Title';
import { FaLock } from 'react-icons/fa';
import Link from 'components/Link';
import Error from 'components/Error';

export default function TwoFactorAuthentication(props: RouteComponentProps) {
    const [error, setError] = useState<string>();
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);
    const params = new URLSearchParams(useLocation().search);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisabled(true);

        const formData = new FormData(event.currentTarget);
        const { result } = await LoginEndpoints.twoFactor(
            params.get('requestGuid')!,
            formData.get('code') as string
        );
        setDisabled(false);

        if (!result?.success) {
            setError(result?.message);
            return;
        }

        const { data } = result!;

        var date = new Date();
        date.setDate(date.getDate() + 6);
        document.cookie = `token=${data.token}; expires=${date.toUTCString()}; path=/`;
        
        const publicationId = params.get('publicationId');

        props.history.push('/' + (publicationId ? `publication/show/${publicationId}` : ''));
    };
    return (
        <>
            <Box
                className="md:bg-white/80"
            >
                <Title
                    icon={FaLock}
                >
                    {t('login.twoStepVerification')}
                </Title>
                <p
                    className="mb-5"
                >
                    {params.get('deliveryMethod') == '0' && t('login.tfaEmailDescription')}
                    {params.get('deliveryMethod') == '1' && t('login.tfaSMSDescription')}
                    <b>{params.get('to')}</b>
                </p>
                <form
                    onSubmit={onSubmit}
                >
                    <TextField
                        name="code"
                        placeholder={t('login.enterCode')}
                        required
                        autoFocus
                        className="mb-5"
                        type="number"
                    />
                    <Error>
                        {t(error!)}
                    </Error>
                    <div
                        className="flex justify-end items-center gap-6"
                    >
                        <Link
                            to="/login"
                        >
                            {t('login.return')}
                        </Link>
                        <Button
                            type="submit"
                            disabled={disabled}
                        >
                            {t('next')}
                        </Button>
                    </div>
                </form>
            </Box>
        </>
    );
}