import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import Breadcrumbs from '../components/Breadcrumbs';
import * as SuggestionCategoryEndpoints from '../suggestionCategory/SuggestionCategoryEndpoints';
import Box from 'components/Box';
import Title from 'components/Title';
import { FaChevronRight, FaComment, FaList } from 'react-icons/fa';
import Link from 'components/Link';
import * as Path from '../utilities/Path'; 
import SubmitSuggestionModal from './SubmitSuggestionModal';
import Button from 'components/Button';

export default function SuggestionCategoriesView(props: RouteComponentProps) {
    const { t } = useTranslation();

    const [categories, setCategories] = useState(new Array());

    useEffect(() => {
        SuggestionCategoryEndpoints.All()
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                setCategories(result.data);
            });
    }, []);

    const whiteHr = (
        <hr 
            className="h-1 bg-white border-none mb-4"
        />
    );

    return (
        <>
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('suggestion.suggestions')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-5
                    "
                >
                    <Title
                        icon={FaComment}
                    >
                        {t('suggestion.suggestions')}
                    </Title>
                    <div
                        className="
                            mb-5
                            flex items-center justify-end gap-x-2.5 2xl:gap-x-6 gap-y-2 flex-wrap
                        "
                    >
                        <Button
                            icon={FaList}
                            variant="outlined"
                            onClick={() => props.history.push(Path.Resolve(props.match.url, 'mySuggestions'))}
                        >
                            {t('suggestion.mySuggestions')}
                        </Button>
                        <SubmitSuggestionModal />
                    </div>
                </div>
                <p
                    dangerouslySetInnerHTML={{  __html: t('suggestion.tldr') }}
                    className="mb-8"
                />
                <div
                    className="
                        grid
                        grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
                        sm:gap-x-7 2xl:gap-x-8
                    "
                >
                    {categories.map(category => (
                        <div
                            key={category.suggestionCategoryID}
                            className="
                                bg-[#eef5fc]
                                px-4 py-5
                                mb-8
                                w-full
                            "
                        >
                            <div
                                className="
                                    mb-4
                                    font-bold
                                    text-lg
                                    break-words
                                "
                            >
                                {category.name}
                            </div>
                            {whiteHr}
                            <div>
                                <Link 
                                    to={Path.Resolve(props.match.url, 'categories', category.suggestionCategoryID)}
                                    className="flex flex-row-reverse items-center justify-between"
                                    icon={FaChevronRight}
                                    iconClassName="827679
                                        fill-[#4dc7f5]
                                        group-hover:fill-[#3d6a86]
                                        group-hover:-translate-x-1.5
                                    "
                                >
                                    {category.suggestionCount} {(category.suggestionCount > 1 ? t('suggestion.suggestions') : t('suggestion.suggestion')).toLowerCase()}
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className="
                        flex
                        mt-4 mb-8
                    "
                >
                    <Link
                        className="m-auto underline"
                        to={Path.Resolve(props.match.url, 'all')}
                    >
                        {t('suggestionCategory.viewAllSuggestions')}
                    </Link>
                </div>
            </Box>
        </>
    );
};