import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
};

const Label = forwardRef<HTMLLabelElement, LabelProps>(
    (
        {
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <label
                ref={ref}
                {...props}
                className={twMerge(
                    `
                        block
                        mb-1.5
                    `,
                    className && className
                )}
            />
        );
    }
);

Label.displayName = 'Label';

export default Label;