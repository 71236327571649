import Label, { LabelProps } from "components/Label";
import TextField, { TextFieldProps } from "components/TextField";
import { twMerge } from "tailwind-merge";

export type LabeledTextFieldProps = {
    name: string;
    label: string;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    type?: TextFieldProps['type'];
    min?: TextFieldProps['min'];

    defaultValue?: string;
    labelProps?: Partial<Omit<LabelProps, 'children' | 'htmlFor'>>;
    textFieldProps?: Partial<Omit<TextFieldProps, 'type' | 'id' | 'name' | 'required' | 'autoFocus' | 'defaultValue' | 'disabled' | 'pattern'>>;
    className?: string;
    pattern?: string;
    showCopyButton?: boolean;
    autoComplete?: 'off' | 'on';
};

export default function LabeledTextField({
    name,
    label,
    type,
    required,
    autoFocus,
    disabled,
    defaultValue,
    labelProps,
    textFieldProps,
    className,
    pattern,
    showCopyButton,
    autoComplete,
    min,
    ...props
}: LabeledTextFieldProps) {
    return (
        <div
            className={twMerge(
                `
                    mb-5
                `,
                className
            )}
        >
            <Label
                {...labelProps}
                htmlFor={name}
            >
                {label}
            </Label>
            <TextField
                {...textFieldProps}
                id={name}
                name={name}
                type={type}
                required={required}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
                pattern={pattern}
                showCopyButton={showCopyButton}
                autoComplete={autoComplete}
                min={min}
            />
        </div>
    );
};