import Label, { LabelProps } from "components/Label";
import Select, { SelectProps } from "components/OldSelect";
import { SelectOptionProps } from "components/OldSelect/SelectOption";
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

export type LabeledSelectProps<T> = {
    name: string;
    label: string;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    defaultValue?: T;
    value?: T;
    labelProps?: Partial<Omit<LabelProps, 'children' | 'name'>>;
    selectProps?: Partial<Omit<SelectProps<T>, 'id' | 'name' | 'required' | 'autoFocus' | 'defaultValue' | 'onChange' | 'disabled'>>;
    children?: ReactElement<SelectOptionProps<T>> | ReactElement<SelectOptionProps<T>>[];
    onChange?(value: T): void;
    className?: string;
};

export default function LabeledSelect<T extends string | number | undefined>({
    name,
    label,
    required,
    autoFocus,
    disabled,
    defaultValue,
    value,
    labelProps,
    selectProps,
    children,
    onChange,
    className,
    ...props
}: LabeledSelectProps<T>) {
    return (
        <div
            className={twMerge(
                `
                    mb-5
                `,
                className
            )}
        >
            <Label
                {...labelProps}
                htmlFor={name}
            >
                {label}
            </Label>
            <Select<T>
                {...selectProps}
                id={name}
                name={name}
                required={required}
                autoFocus={autoFocus}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
            >
                {children}
            </Select>
        </div>
    );
};