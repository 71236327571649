import useTranslation from '../utilities/TranslationOutsideComponent';
import * as UserEndpoints from './UserEndpoints';
import * as Dialog from 'components/Dialog';
import { toast } from 'react-toastify';

export async function Delete(userID: number) {
    const t = await useTranslation();
    const yesNoResult = await Dialog.YesNo(t('user.message.yesNoDelete'));

    if (yesNoResult == 'no')
        return;

    const { result: deleteResult } = await UserEndpoints.Delete(userID);
    if (!deleteResult?.success) {
        deleteResult?.message && toast.error(t(deleteResult?.message));
        return false;
    }

    toast.success(t('user.deleted'));
    return true;
}