import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type ErrorProps = React.HTMLAttributes<HTMLDivElement> & {
};

const Error = forwardRef<HTMLDivElement, ErrorProps>(
    (
        {
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        if (!props.children)
            return <></>;

        return (
            <div
                ref={ref}
                {...props}
                className={twMerge(
                    `
                        text-red-500
                        text-right
                        mb-5
                    `,
                    className && className
                )}
            />
        );
    }
);

Error.displayName = 'Error';

export default Error;