import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import * as SuggestionCategoryEndpoints from './SuggestionCategoryEndpoints';
import * as SuggestionCategoryActions from './SuggestionCategoryActions';
import { FaComment, FaSave, FaTrash } from 'react-icons/fa';
import LabeledTextField from 'components/LabeledTextField';
import Box from 'components/Box';
import Title from 'components/Title';
import Button from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import { toast } from 'react-toastify';
import { History } from 'utilities/History';
import Error from 'components/Error';

interface SuggestionCategoryDetailViewRouteParams {
    suggestionCategoryID?: string
}

export default function SuggestionCategoryDetailView(props: RouteComponentProps<SuggestionCategoryDetailViewRouteParams>) {
    const { t } = useTranslation();
    const suggestionCategoryID = Number(props.match.params.suggestionCategoryID || '0');

    const [category, setCategory] = useState<any>();
    const [nameError, setNameError] = useState<string>();

    useEffect(() => {
        if (!suggestionCategoryID)
            return;

        SuggestionCategoryEndpoints.Get(suggestionCategoryID)
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                setCategory(result.data);
            });
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setNameError(undefined);

        const formData = new FormData(event.currentTarget);
        const name = formData.get('name') as string;

        if (!suggestionCategoryID) {
            // new category

            let { result } = await SuggestionCategoryEndpoints.Post(name);
            if (!result?.success) {
                setNameError(result?.message);
                return;
            }

            toast.success(t('suggestionCategory.added'));
        } else {
            // existing category

            let { result } = await SuggestionCategoryEndpoints.Put(suggestionCategoryID, name);
            if (!result?.success) {
                setNameError(result?.message);
                return;
            }

            toast.success(t('suggestionCategory.updated'));
        }

        History.push('/suggestionCategories');
    }

    const onClickDelete = async () => {
        const deleted = await SuggestionCategoryActions.Delete(suggestionCategoryID);
        if (!deleted)
            return;

        History.push('/suggestionCategories');
    };

    return (
        <div
            className="
                m-auto
                max-w-[800px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="/suggestionCategories">{t('suggestionCategory.categories')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{!suggestionCategoryID ? t('suggestionCategory.newCategory') : category?.name}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title
                    icon={FaComment}
                >
                    {t('suggestionCategory.category')} - {category?.name || t('suggestionCategory.newCategory')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <LabeledTextField
                        name="name"
                        label={t('suggestionCategory.name')}
                        defaultValue={category?.name}
                        required
                        autoFocus={!suggestionCategoryID}
                    />
                    <Error>
                        {t(nameError!)}
                    </Error>
                    <div
                        className="flex justify-end gap-2.5 2xl:gap-6"
                    >
                        <Button
                            icon={FaSave}
                            type="submit"
                        >
                            {t('save')}
                        </Button>
                        <Button
                            onClick={onClickDelete}
                            icon={FaTrash}
                            variant="outlined"
                            disabled={!suggestionCategoryID}
                        >
                            {t('delete')}
                        </Button>
                    </div>
                </form>
            </Box>
        </div>
    );
}