import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FaSave } from 'react-icons/fa';
import { BsGearFill } from 'react-icons/bs';
import { RouteComponentProps } from 'react-router';
import Breadcrumbs from '../components/Breadcrumbs';
import * as UserEndpoints from '../user/UserEndpoints';
import * as SuggestionApprovalSettingsEndpoints from '../suggestionApprovalSettings/SuggestionApprovalSettingsEndpoints';
import Title from 'components/Title';
import Box from 'components/Box';
import Select from 'components/Select';
import Label from 'components/Label';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from 'components/Button';
import { Permission } from 'permission/Permission';
import LabeledToggle from 'components/LabeledToggle';
import LabeledTextField from 'components/LabeledTextField';
import Separator from 'components/Separator';

interface SuggestionApprovalSettingsRouteParams {
}

export default function SuggestionApprovalSettingsView(props: RouteComponentProps<SuggestionApprovalSettingsRouteParams>) {
    const { t } = useTranslation();

    const [userSelectOptions, setUserSelectOptions] = useState(new Array());
    const [userSelectedOptions, setUserSelectedOptions] = useState(new Array());
    const [approvalSettings, setApprovalSettings] = useState<any>({});
    
    useEffect(() => {
        const promises = new Array<Promise<any>>();
        promises.push(UserEndpoints.All({
            permissions: [Permission.Suggestion_Review]
        }));
        promises.push(SuggestionApprovalSettingsEndpoints.Get());

        Promise.all(promises)
            .then(async responses => {
                const { result: usersResult }  = responses[0];
                if (!usersResult.success)
                    return;

                const usersData = (usersResult.data as Array<any>).map(user => ({
                    value: user.userID,
                    label: `${user.firstName} ${user.lastName} (${user.username})`
                }));
                setUserSelectOptions(usersData);

                const { result: settingsResult }  = responses[1];
                if (!settingsResult.success)
                    return;

                const { data } = settingsResult;

                setUserSelectedOptions(usersData.filter(userOption => data.sendEmailToUponSubmission.includes(userOption.value)));
                setApprovalSettings(data);
            });
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { result } = await SuggestionApprovalSettingsEndpoints.Put(
            userSelectedOptions.map(x => x.value),
            approvalSettings.activeVotesCapped,
            Number(approvalSettings.activeVotesCap),
            approvalSettings.displayUnreadFields
        );
        if (!result?.success) return;

        toast.success(t('settingsUpdated'));
        props.history.push('/suggestionsUnderReview');
    }

    const onChangeSelect = selectedOptions => setUserSelectedOptions(selectedOptions);

    const onToggleActiveVotesCapped = (event: ChangeEvent<HTMLInputElement>) => {
        setApprovalSettings({
            ...approvalSettings,
            activeVotesCapped: event.target.checked
        });
    };

    const onToggleDisplayUnreadFields = (event: ChangeEvent<HTMLInputElement>) => {
        setApprovalSettings({
            ...approvalSettings,
            displayUnreadFields: event.target.checked
        });
    };

    const onChangeActiveVotesCap = (event: ChangeEvent<HTMLInputElement>) => {
        setApprovalSettings({
            ...approvalSettings,
            activeVotesCap: event.target.value
        });
    };

    return (
        <div
            className="max-w-[1200px] m-auto"
        >
            <Breadcrumbs>
                <Breadcrumbs.Item
                    to="/"
                >
                    {t('home.home')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                    to="/suggestionsUnderReview"
                >
                    {t('suggestion.suggestionStatuses.0')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                    {t('suggestion.approvalSettings')}
                </Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title
                    icon={BsGearFill}
                >
                    {t('suggestion.approvalSettings')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <Title>
                        {t('votes')}
                    </Title>
                    <LabeledToggle
                        name="activeVotesCapped"
                        label={t('suggestionSettings.capActiveVotes')}
                        checked={approvalSettings.activeVotesCapped}
                        ToggleProps={{ onChange: onToggleActiveVotesCapped }}
                    />
                    <LabeledTextField
                        name="activeVotesCap"
                        type="number"
                        min={0}
                        label={t('suggestionSettings.activeVotesCap')}
                        textFieldProps={{
                            value: approvalSettings.activeVotesCap,
                            onChange: onChangeActiveVotesCap
                        }}
                        disabled={!approvalSettings.activeVotesCapped}
                    />
                    <Separator />
                    <Title>
                        {t('approval')}
                    </Title>
                    <Label>
                        {t('suggestion.sendEmailUponSubmission')}
                    </Label>
                    <Select
                        isMulti
                        options={userSelectOptions}
                        className="mb-5"
                        value={userSelectedOptions}
                        onChange={onChangeSelect}
                    />
                    <Separator />
                    <Title>
                        {t('suggestionSettings.displayParameters')}
                    </Title>
                    <LabeledToggle
                        name="displayUnreadFields"
                        label={t('suggestionSettings.displayUnreadFields')}
                        checked={approvalSettings.displayUnreadFields}
                        ToggleProps={{ onChange: onToggleDisplayUnreadFields }}
                    />
                    <div
                        className="flex justify-end"
                    >
                        <Button
                            type="submit"
                            icon={FaSave}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </form>
            </Box>
        </div>
    );
}