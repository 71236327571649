import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { History as WebHistory } from "history";

export class History {

    private static _instance: WebHistory;
    static set instance(instance: WebHistory) {
        this._instance = instance;
    };

    static push(...args: Parameters<WebHistory["push"]>)  {
        this._instance.push(...args);
    }
}

export function StaticHistory() {
    const history = useHistory();

    useEffect(() => {
        History.instance = history;
    }, []);

    return null;
}