import React, { forwardRef, ReactElement } from "react";
import { FaAngleRight } from "react-icons/fa";
import ChildSeparator from "components/ChildSeparator";
import { twMerge } from "tailwind-merge";
import BreadcrumbsItem, { BreadcrumbsItemProps } from "./BreadcrumbsItem";

export type BreadcrumbsProps = React.HTMLAttributes<HTMLUListElement> & {
    children: ReactElement<BreadcrumbsItemProps> | ReactElement<BreadcrumbsItemProps>[] | any;
};

const Breadcrumbs = forwardRef<HTMLUListElement, BreadcrumbsProps>(
    (
        {
            children,
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <ul
                role="navigation"
                ref={ref}
                {...props}
                className={twMerge(
                    `
                        inline-flex items-center
                        py-5 px-5 md:px-0
                    `,
                    className
                )}
            >
                <ChildSeparator
                    separator={
                        <FaAngleRight 
                            className="mx-2 w-4 h-4"
                        />
                    }
                >
                    {children}
                </ChildSeparator>
            </ul>
        );
    }
);

Breadcrumbs.displayName = 'Breadcrumbs';

export default Object.assign(
    Breadcrumbs,
    { 
        Item: BreadcrumbsItem
    }
);