import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import ClientListView from './ClientListView';
import ClientDetailView from './ClientDetailView';
import UserDetailView from 'user/UserDetailView';

export default function ClientLayout(props: RouteComponentProps) {

    return (
        <Switch>
            <Route path={`${props.match.url}/new`} component={ClientDetailView} />
            <Route path={`${props.match.url}/:clientID/users/:userID`} component={UserDetailView} />
            <Route path={`${props.match.url}/:clientID`} component={ClientDetailView} />
            <Route path={`${props.match.url}`} component={ClientListView} />
        </Switch>
    );
}