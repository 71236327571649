import React, { forwardRef } from "react";
import { ValidationResult } from "utilities/Api";
import Error from 'components/Error';
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

export type ValidationErrorProps = React.HTMLAttributes<HTMLDivElement> & {
    name?: string;
    result?: ValidationResult;
};

const ValidationError = forwardRef<HTMLDivElement, ValidationErrorProps>(
    (
        {
            name,
            result,
            className,
            ...props
        },
        ref
    ): JSX.Element => {

        const { t } = useTranslation();

        if (!result)
            return <></>;

        var keys = Object.getOwnPropertyNames(result);
        if (name)
            keys = keys.filter(x => x.toLowerCase() == name.toLowerCase());

        return (
            <div
                ref={ref}
                className={twMerge(
                    `mb-5`,
                    className
                )}
                {...props}
            >
                {keys.map((key, i) => (
                    <Error
                        key={`${key}-${i}`}
                        className="mb-0"
                    >
                        {t(result[key])}
                    </Error>
                ))}
            </div>
        );
    }
);

ValidationError.displayName = 'ValidationError';

export default ValidationError;