import { useContext } from "react";
import { Route, RouteProps } from "react-router-dom";
import { globalContext } from "../app/GlobalContext";
import { Permission } from "./Permission";

type AuthorizedRouteProps = RouteProps & {
    permissions: Array<Permission> | Permission;
};

export default function AuthorizedRoute(
    { 
        permissions,
        ...props
    } : AuthorizedRouteProps
) {
    const { globalState } = useContext(globalContext);
    const { user } = globalState;
    
    if (typeof permissions == 'string')
        permissions = [permissions];

    if (permissions.findIndex(p => user.permissions.includes(p)) == -1)
        return null;

    return (
        <Route {...props} />
    );
};