import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FaCalendarAlt, FaCheckSquare, FaComment, FaMinusSquare, FaPen, FaSave, FaTasks, FaTrash } from "react-icons/fa";
import Badge from "components/Badge";
import Button from "components/Button";
import TextArea, { TextAreaRef } from "components/TextArea";
import { twMerge } from "tailwind-merge";
import { SuggestionStatus } from "./SuggestionStatus";
import * as SuggestionActions from './SuggestionActions';
import * as SuggestionVoteEndpoints from '../suggestionVote/SuggestionVoteEndpoints';
import * as SuggestionCommentEndpoints from '../suggestionComment/SuggestionCommentEndpoints';
import * as SuggestionEndpoints from '../suggestion/SuggestionEndpoints';
import Link from "components/Link";
import { SuggestionCommentStatus } from "suggestionComment/SuggestionCommentStatus";
import { globalContext } from "app/GlobalContext";
import TextField from "components/TextField";
import { IconBaseProps } from "react-icons";
import ValidationError from "components/ValidationError";
import { ValidationResult } from "utilities/Api";
import { Permission } from "permission/Permission";
import { SuggestionLink } from "suggestionLink/SuggestionLink";

interface SuggestionProps {
    suggestion: any;
    onDeleted?(suggestion: any): void;
    showComments?: boolean;
    className?: string;
}

export default function Suggestion(props: SuggestionProps) {
    const {globalState, dispatch} = useContext(globalContext);
    const { t } = useTranslation();

    const [suggestion, setSuggestion] = useState(props.suggestion);
    const [editing, setEditing] = useState(false);
    const [validationResult, setValidationResult] = useState<ValidationResult>();

    const commentTextArea = useRef<TextAreaRef>(null);
    const editForm = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (props.showComments)
            loadComments();
    }, [props.showComments]);

    const vote = async () => {
        const { result } = await SuggestionVoteEndpoints.Vote(suggestion.suggestionID);

        if (!result?.success)
        {
            toast.error(t(result?.message!));
            return;
        }

        suggestion.hasVotedFor = true;
        suggestion.voteCount++;
        dispatch({ type: 'suggestion.addActiveVote' });
        setSuggestion({ ...suggestion });
    };

    const removeVote = async () => {
        const { result } = await SuggestionVoteEndpoints.Delete(suggestion.suggestionID);

        if (!result?.success)
        {
            toast.error(t(result?.message!));
            return;
        }

        suggestion.hasVotedFor = false;
        suggestion.voteCount--;
        dispatch({ type: 'suggestion.removeActiveVote' });
        setSuggestion({ ...suggestion });
    }

    const loadComments = async () => {
        const { result } = await SuggestionCommentEndpoints.All({ 
            suggestionID: suggestion.suggestionID,
            statuses: [SuggestionCommentStatus.Approved],
            includeUserUnderReview: true
        });
        if (!result?.success)
            return;
        
        suggestion.comments = result.data;
        suggestion.commentCount = suggestion.comments.length;
        setSuggestion({ ...suggestion });
    };

    const toggleComments = () => {
        if (!suggestion.comments) {
            loadComments();
            return;
        }

        suggestion.comments = null;
        setSuggestion({ ...suggestion });
    };

    const onSubmitComment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const response = await SuggestionCommentEndpoints.Post({
            text: formData.get('comment') as string,
            suggestionID: suggestion.suggestionID
        });

        if (response.status != 200)
            return;

        if (commentTextArea.current)
            commentTextArea.current.value = '';
        loadComments();
        toast.success(t('suggestion.commentSubmitted'));
    };

    const onClickEditSuggestion = () => setEditing(true);

    const onClickCancelEditSuggestion = () => {
        setValidationResult(undefined);
        setEditing(false);
    }

    const onClickSaveSuggestion = async () => {
        setValidationResult(undefined);

        if (!editForm.current?.reportValidity())
            return;

        const formData = new FormData(editForm.current!);
        const title = formData.get('title') as string;
        const text =  formData.get('text') as string;

        const { result } = await SuggestionEndpoints.Put(suggestion.suggestionID, title, text);
        if (!result?.success) {
            setValidationResult(result?.validationResult);
            return;
        }

        suggestion.title = title;
        suggestion.text =  text;
        setSuggestion({ ...suggestion });

        setEditing(false);
    };

    const onClickDeleteSuggestion = async () => {
        const deleted = await SuggestionActions.Delete(suggestion.suggestionID);
        if (!deleted)
            return;

        props.onDeleted && await props.onDeleted(suggestion);
    }

    // bg-suggestionStatus-0
    // bg-suggestionStatus-1
    // bg-suggestionStatus-2
    // bg-suggestionStatus-3
    // bg-suggestionStatus-4
    // bg-suggestionStatus-5
    // bg-suggestionStatus-6
    // bg-suggestionStatus-7

    const voteAllowedStatuses = [
        SuggestionStatus.Open,
        SuggestionStatus.UnderAnalysis,
    ];

    const commentAllowedStatuses = [
        SuggestionStatus.Open,
        SuggestionStatus.UnderAnalysis,
        SuggestionStatus.Planned,
        SuggestionStatus.UnderDevelopment,
    ];

    const roadmapShownStatuses = [
        SuggestionStatus.Open,
        SuggestionStatus.UnderAnalysis,
        SuggestionStatus.Planned,
        SuggestionStatus.UnderDevelopment,
        SuggestionStatus.Completed
    ];

    return <div
        key={suggestion.suggestionID}
        className={twMerge(
            `
                flex flex-col flex-wrap md:flex-row
                md:gap-5
                px-4 py-7 lg:px-7
                bg-[#eef5fc]
            `,
            props.className
        )}
    >
        <div
            className="
                flex md:flex-col gap-2 flex-wrap
                order-2 md:order-1
            "
        >
            {suggestion.status != SuggestionStatus.Open &&
                <div
                    className="
                        text-center
                        md:mb-4
                    "
                >
                    <Badge 
                        content={t(`suggestion.suggestionStatuses.${suggestion.status}`)}
                        className={`
                            text-xs
                            bg-suggestionStatus-${suggestion.status}
                        `}
                        title={suggestion.status == SuggestionStatus.UnderReview ? t('suggestion.suggestionUnderReviewTitle') : undefined}
                    />
                </div>
            }
            {voteAllowedStatuses.includes(suggestion.status) &&
                <Button
                    icon={!suggestion.hasVotedFor ? FaCheckSquare : FaMinusSquare}
                    className={twMerge(
                        `
                            bg-[#668823]
                            hover:bg-[#3d6a86]
                            md:px-7
                            md:min-w-[150px]
                        `,
                        suggestion.hasVotedFor && `
                            bg-[#3d6a86]
                            hover:bg-red-400
                        `
                    )}
                    iconClassName="fill-white"
                    disabled={!globalState.user.permissions.includes(Permission.Suggestion_Write) || suggestion.own || !suggestion.hasVotedFor && globalState.suggestionActiveVotesCapped && globalState.suggestionActiveVoteCount >= globalState.suggestionActiveVotesCap}
                    onClick={!suggestion.hasVotedFor ? vote : removeVote}
                >
                    {!suggestion.hasVotedFor ? t('suggestion.vote') : t('suggestion.removeVote')}
                </Button>
            }
            {voteAllowedStatuses.includes(suggestion.status) &&
                <div
                    className="
                        px-[0.9em] py-[0.5em] lg:py-[1.1em]
                        md:px-7
                        bg-white
                        text-center whitespace-nowrap
                    "
                >
                    {suggestion.voteCount} {suggestion.voteCount > 1 ? t('suggestion.votesCount') : t('suggestion.voteCount')}
                </div>
            }
            {(suggestion.status == SuggestionStatus.Open || suggestion.status == SuggestionStatus.UnderAnalysis) &&
                suggestion.popularityRank <= 3 &&
                <div
                    className="
                        px-[0.9em] py-[0.5em] lg:py-[1.1em]
                        md:px-7
                        bg-[#4dc7f5]
                        font-bold text-center whitespace-nowrap text-white
                    "
                >
                    {suggestion.popularityRank}<sup>{t(`suggestion.sup.${suggestion.popularityRank}`)}</sup> position
                </div>
            }
            {![
                SuggestionStatus.UnderReview,
                SuggestionStatus.Denied
            ].includes(suggestion.status) &&
                <Button
                    icon={FaComment}
                    variant="outlined"
                    className={twMerge(
                        `
                            md:mt-6
                            border-white
                            bg-transparent
                            hover:bg-white
                        `,
                        suggestion.comments && 'bg-white'
                    )}
                    iconClassName="fill-[#BCC3CA]"
                    onClick={toggleComments}
                >
                    {t('comments')} ({suggestion.commentCount})
                </Button>
            }
            {suggestion.status == SuggestionStatus.UnderReview
                && globalState.user.permissions.includes(Permission.Suggestion_Write)
                && suggestion.own &&
                <>
                    {!editing &&
                        <>
                            <ActionButton
                                icon={FaPen}
                                onClick={onClickEditSuggestion}
                                className="md:!p-1"
                            >
                                {t('edit')}
                            </ActionButton>
                            <ActionButton
                                icon={FaTrash}
                                onClick={onClickDeleteSuggestion}
                                className="md:!p-1"
                            >
                                {t('delete')}
                            </ActionButton>
                        </>
                    }
                    {editing &&
                        <>
                            <ActionButton
                                icon={FaSave}
                                onClick={onClickSaveSuggestion}
                                className="md:!p-1"
                            >
                                {t('save')}
                            </ActionButton>
                            <ActionButton
                                onClick={onClickCancelEditSuggestion}
                                className="md:!p-1"
                            >
                                {t('cancel')}
                            </ActionButton>
                        </>
                    }
                </>
            }
        </div>
        <div
            className="
                flex flex-1 flex-col justify-between
                order-1 md:order-2
                border-l-0 md:border-l-4 md:border-b-0
                border-white
                md:pl-5
            "
        >
            <form
                ref={editForm}
            >
                <div
                    className="
                        flex flex-col md:flex-row justify-between items-start gap-x-4 gap-y-5
                        mb-6
                    "
                >
                    {!editing &&
                        <div
                            className="flex flex-col items-start"
                        >
                            {suggestion.category &&
                                <Link
                                    to={`/suggestions/categories/${suggestion.category.suggestionCategoryID}`}
                                    className="
                                        text-xs text-[#84aec7]
                                        hover:underline hover:text-[auto]
                                    "
                                >
                                    {suggestion.category.name}
                                </Link>
                                }

                            <Link
                                to={`/suggestions/${suggestion.suggestionID}`}
                                className="font-bold text-lg"
                            >
                                <p>
                                    {globalState.user.permissions.includes(Permission.Suggestion_Review) &&
                                        <div
                                            className="inline font-bold"
                                        >
                                            #{`${suggestion.suggestionID}`},&nbsp;
                                        </div>
                                    }
                                    {suggestion.title}
                                    {suggestion.showOnRoadmap
                                        && roadmapShownStatuses.includes(suggestion.status) &&
                                        <FaTasks
                                            className="
                                                ml-2 mt-[0.45rem]
                                                inline-block align-top
                                                text-sm
                                                !text-[#4dc7f5]
                                            "
                                            title={t('suggestion.shownOnRoadmap')}
                                        />
                                    }
                                </p>
                            </Link>
                            {(globalState.user.permissions.includes(Permission.Suggestion_Review)
                                || globalState.user.permissions.includes(Permission.Suggestion_ProjectManager)) &&
                                <div
                                    className="text-xs mt-4"
                                >
                                    {t('suggestion.by')}&nbsp;
                                    <Link
                                        to={`/users/${suggestion.suggestedBy.userID}`}
                                        className="
                                            text-xs text-[#84aec7]
                                            hover:underline hover:text-[auto]
                                        "
                                    >
                                        {`${suggestion.suggestedBy.firstName} ${suggestion.suggestedBy.lastName}`}
                                    </Link>
                                    &nbsp;{t('suggestion.from')}&nbsp;
                                    <Link
                                        to={`/clients/${suggestion.suggestedBy.clientID}`}
                                        className="
                                            text-xs text-[#84aec7]
                                            hover:underline hover:text-[auto]
                                        "
                                    >
                                        {`${suggestion.suggestedBy.clientName}`}
                                    </Link>
                                </div>
                            }
                        </div>
                    }
                    {editing &&
                        <div
                            className="
                                flex flex-col
                                flex-1
                                w-full max-w-lg
                            "
                        >
                            <TextField
                                name="title"
                                defaultValue={suggestion.title}
                                className="bg-white"
                                required
                                placeholder={t('suggestion.title')}
                            />
                            <ValidationError
                                name="title"
                                result={validationResult}
                            />
                        </div>
                    }
                    <div
                        className="
                            flex items-center gap-x-2
                            whitespace-nowrap
                        "
                    >
                        <FaCalendarAlt 
                            className="text-[#4dc7f5]"
                        />
                        {new Date(suggestion.suggestedOn).toLocaleDateString()}
                    </div>
                </div>
                {!editing &&
                    <p
                        className="mb-8"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {suggestion.text}
                    </p>
                }
                {editing &&
                    <>
                        <TextArea
                            name="text"
                            defaultValue={suggestion.text}
                            className="bg-white"
                            rows={4}
                            required
                            placeholder={t('suggestion.description')}
                            autoFocus
                        />
                        <ValidationError
                            name="text"
                            result={validationResult}
                        />
                    </>
                }
                {!editing && suggestion.links?.length > 0 &&
                    <div
                        className="
                            w-full
                            flex gap-x-4 flex-wrap
                            -mt-2 mb-4
                        "
                    >
                        {suggestion.links?.map(link => (
                            <SuggestionLink
                                key={link.suggestionLinkID}
                                suggestionLink={link}
                            />
                        ))}
                    </div>
                }
            </form>
        </div>
        {suggestion.comments && (suggestion.comments.length > 0 || commentAllowedStatuses.includes(suggestion.status)) &&
            <div
                className="
                    w-full 
                    flex md:gap-5
                    order-3
                    mt-8 md:mt-0
                "
            >
                <div>
                </div>
                <div
                    className="
                        w-full
                        -ml-4 md:ml-[150px]
                        border-transparent md:border-l-4
                    "
                >
                    <div
                        className="
                            flex flex-col gap-1
                            ml-2
                        "
                    >
                        {commentAllowedStatuses.includes(suggestion.status)
                            && globalState.user.permissions.includes(Permission.Suggestion_Write) &&
                            <form
                                className="mb-5"
                                onSubmit={event => onSubmitComment(event)}
                            >
                                <TextArea
                                    ref={commentTextArea}
                                    className="bg-white mb-5"
                                    placeholder={t('suggestion.addComment')}
                                    rows={4}
                                    name="comment"
                                    required
                                />
                                <div
                                    className="text-right"
                                >
                                    <Button
                                        type="submit"
                                    >
                                        {t('suggestion.submitComment')}
                                    </Button>
                                </div>
                            </form>
                        }
                        {suggestion.comments &&
                            suggestion.comments.map(comment =>
                                <Comment
                                    key={comment.suggestionCommentID}
                                    comment={comment}
                                    onDelete={loadComments}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        }
    </div>;
};

const Comment = ({ 
    onDelete,
    ...props
}) => {
    const { t } = useTranslation();
    const {globalState} = useContext(globalContext);

    const [comment, setComment] = useState(props.comment);
    const [editing, setEditing] = useState(false);
    const [validationResult, setValidationResult] = useState<ValidationResult>();

    const editForm = useRef<HTMLFormElement>(null);

    const onClickEdit = () => setEditing(true);

    const onClickSave = async () => {
        setValidationResult(undefined);

        if (!editForm.current?.reportValidity())
            return;

        const formData = new FormData(editForm.current!);
        const text =  formData.get('text') as string;

        const { result } = await SuggestionCommentEndpoints.Put({
            suggestionCommentID: comment.suggestionCommentID, 
            text
        });
        if (!result?.success) {
            setValidationResult(result?.validationResult);
            return;
        }

        comment.text = text;
        setComment({ ...comment });

        setEditing(false);
    };

    const onClickCancel = () => {
        setEditing(false);
    };

    const onClickDelete = async comment => {
        const { result } = await SuggestionCommentEndpoints.Delete(comment.suggestionCommentID);
        if (!result?.success)
            return;

        await onDelete();
        toast.success(t('suggestion.commentDeleted'));
    };

    return (
        <div
            className={twMerge(
                `
                    w-fit min-w-[200px]
                    flex-col gap-2
                    w-full
                    bg-white
                    px-3 py-2
                `,
                comment.isOwner && 'border-l-4 border-[#4dc7f5]'
            )}
        >

            <div>
                <div
                    className="inline font-bold mr-2"
                >
                    {!comment.commentedBy.firstName && t('anonymous')}
                    {comment.commentedBy.firstName && `${comment.commentedBy.firstName} ${comment.commentedBy.lastName}`}
                </div>
                {!editing && comment.text}
                {editing &&
                    <form
                        ref={editForm}
                    >
                        <TextArea
                            name="text"
                            placeholder={t('suggestionComment.comment')}
                            rows={4}
                            className="bg-white my-2"
                            defaultValue={comment.text}
                            autoFocus
                            required
                        />
                        <ValidationError
                            name="text"
                            result={validationResult}
                        />
                    </form>
                }
            </div>
            <div
                className="inline-flex items-start gap-2 flex-wrap"
            >
                <ReactTimeAgo
                    date={comment.commentedOn}
                    timeStyle="round-minute"
                    className="
                        text-right text-xs
                        text-[#84aec7]
                    "
                />
                {comment.status == SuggestionCommentStatus.UnderReview &&
                    <>
                        <Badge 
                            content={t(`suggestion.commentStatuses.${comment.status}`)}
                            className="
                                bg-purple-300
                                text-xs
                            "
                            title={t('suggestion.commentUnderReviewTitle')}
                        />
                        {globalState.user.permissions.includes(Permission.Suggestion_Write) &&
                            <div
                                className="
                                    flex gap-2
                                "
                            >
                                {!editing &&
                                    <>
                                        <ActionButton
                                            icon={FaPen}
                                            onClick={onClickEdit}
                                        >
                                            {t('edit')}
                                        </ActionButton>
                                        <ActionButton
                                            icon={FaTrash}
                                            onClick={() => onClickDelete(comment)}
                                        >
                                            {t('delete')}
                                        </ActionButton>
                                    </>
                                }
                                {editing &&
                                    <>
                                        <ActionButton
                                            icon={FaSave}
                                            onClick={onClickSave}
                                        >
                                            {t('save')}
                                        </ActionButton>
                                        <ActionButton
                                            onClick={onClickCancel}
                                        >
                                            {t('cancel')}
                                        </ActionButton>
                                    </>
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};

const ActionButton = ({
    icon,
    onClick,
    children,
    className
} : {
    icon?: React.FC<IconBaseProps>;
    onClick?(event: React.MouseEvent): void;
    children?: ReactNode;
    className?: string;
}) => {
    return (
        <Button
            variant='link'
            icon={icon}
            className={twMerge(
                `
                    gap-x-1
                    !p-0
                    text-xs font-normal
                `,
                className
            )}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};