import Link from "components/Link";
import { IconBaseProps } from "react-icons";
import { FaBook, FaGlobe, FaYoutube } from "react-icons/fa";

interface SuggestionLinkProps {
    suggestionLink: {
        suggestionLinkID: number;
        title?: string;
        link: string;
    }
}

export function SuggestionLink({
    suggestionLink: {
        title,
        link
    }
}: SuggestionLinkProps) {
    var icon: React.FC<IconBaseProps> | undefined = FaGlobe;
    for (const iconKey of Array.from(iconMap.keys()))
        if (link.indexOf(iconKey) >= 0) {
            icon = iconMap.get(iconKey);
            break;
        }

    return (
        <Link
            to={link}
            icon={icon}
            className="text-[#84aec7] hover:underline hover:text-[auto]"
            iconClassName="fill-[#84aec7] group-hover:fill-[auto]"
            target="_blank"
        >
            {title || link}
        </Link>
    );
}

const iconMap = new Map<string, React.FC<IconBaseProps>>();
iconMap.set('youtube.com', FaYoutube);
iconMap.set('aide.cdid.lan', FaBook);
iconMap.set('aide.prextra.com', FaBook);