import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import LoginView from './LoginView';
import TwoFactorAuthenticationView from './TwoFactorAuthenticationView';
import * as PushNotification from '../PushNotification/PushNotification';
import QuickLoginView from './QuickLoginView';
import { EnvironmentDisplayName, environmentImageUrlFr } from 'utilities/Environment';

export default function LoginLayout(props: RouteComponentProps) {

    useEffect(() => {
        PushNotification.unregister();
    }, []);

    return (
        <main
            className="
                px-8
                bg-[#EEF5FC]

                md:bg-login md:bg-[length:auto_142%] md:bg-right-top md:bg-no-repeat
            "
        >
            <div
                className="
                    flex flex-col justify-center
                    h-auto w-full min-h-[100vh] max-w-[700px]
                    mx-auto
                "
            >
                <div
                    className="
                        flex items-end
                        gap-4 md:gap-6
                        -ml-6 md:-ml-8 -mb-1 md:-mb-3
                    "
                >
                    <img 
                        src={environmentImageUrlFr}
                        className="
                            max-w-[300px]
                            md:max-w-[414px]
                        "
                    />
                    <EnvironmentDisplayName 
                        className="
                            mb-10 md:mb-14
                            md:text-lg
                        "
                    />
                </div>
                <Switch>
                    <Route path={`${props.match.url}/quick`} component={QuickLoginView} />
                    <Route path={`${props.match.url}/2fa`} component={TwoFactorAuthenticationView} />
                    <Route path={`${props.match.url}`} component={LoginView} />
                </Switch>
            </div>
        </main>
    );
}