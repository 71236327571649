import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import { FaPlus, FaUser } from "react-icons/fa";
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from "components/Button";
import * as Path from '../utilities/Path';
import Title from "components/Title";
import UserList from "./UserList";

export default function UserListView(props: RouteComponentProps) {
    const { t } = useTranslation();
    
    return (
        <div
            className="
                m-auto
                max-w-[1200px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('user.users')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-10
                    "
                >
                    <Title
                        icon={FaUser}
                    >
                        {t('user.users')}
                    </Title>
                    <Button
                        icon={FaPlus}
                        onClick={() => props.history.push(Path.Resolve(props.match.url, 'new'))}
                    >
                        {t('user.newUser')}
                    </Button>
                </div>
                <UserList />
            </Box>
        </div>
    );
}