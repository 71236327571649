import { forwardRef, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBell, FaCheck } from 'react-icons/fa';
import TimeAgo from 'react-time-ago';
import { twMerge } from 'tailwind-merge';
import { globalContext } from 'app/GlobalContext';
import Box from 'components/Box';
import Title from 'components/Title';
import * as NotificationEndpoints from './NotificationEndpoints';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import './NotificationListView.css';

interface NotificationListViewProps {
    isPopup?: boolean;
}

const NotificationListView = forwardRef<HTMLDivElement, NotificationListViewProps>(
    (
        {
            ...props
        },
        ref
    ): JSX.Element => {
        const { globalState, dispatch } = useContext(globalContext);
        const [activeTab, setActiveTab] = useState('unread');
        const { t } = useTranslation();
        const history = useHistory();
        
        const onClickNotification  = async notificationReading => {
            if (!notificationReading.isRead) {
                const { result } = await NotificationEndpoints.MarkAsRead(notificationReading.notificationReadingID);

                if (result?.success)
                    dispatch({
                        type: 'notifications.markAsRead',
                        payload: notificationReading.notificationReadingID
                    });
            }
            
            const { data } = notificationReading.notification;
            
            if (data.url)
                history.push(data.url);
        };

        const notification = notification => (
            <div
                key={notification.notificationReadingID}
                onClick={() => onClickNotification(notification)}
                className={twMerge(
                    `
                        px-4
                        cursor-pointer
                        border-t-4 border-[#eef5fc]
                        hover:bg-[#eaf1f5]
                    `,
                    !notification.isRead && 'bg-[#cedee9]'
                )}
            >
                <div
                    className="py-[6px] text-left"
                >
                    {notification.notification.data.message}
                </div>
                <div
                    className="text-right"
                >
                    <TimeAgo
                        date={notification.notification.sentOn}
                        timeStyle="round-minute"
                        className="
                            text-right text-xs
                            text-[#84aec7]
                        "
                    />
                </div>
            </div>
        );

        const onClickMarkAllAsRead = async () => {
            const { result } = await NotificationEndpoints.MarkAllAsRead();

            if (result?.success)
                dispatch({
                    type: 'notifications.markAllAsRead'
                });
        };

        const Tab = ({ value, lbl, activeTab, onClick }) => {
            return (
                <button
                    className={`tab-button ${activeTab === value ? 'active' : ''}`}
                    onClick={() => onClick(value)}
                >
                    {lbl}
                </button>
            );
        };

        return (
            <div
                className={twMerge(
                    !props.isPopup && 'max-w-[800px] m-auto'
                )}
            >
                {!props.isPopup &&
                    <Breadcrumbs>
                        <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                        <Breadcrumbs.Item>{t('notification.notifications')}</Breadcrumbs.Item>
                    </Breadcrumbs>
                }
                <Box
                    className={twMerge(
                        props.isPopup && `
                            p-5
                            min-w-[400px] max-w-[550px]
                            max-h-[575px] overflow-y-auto
                            absolute top-[198px] right-0
                            text-[#3d6a86]
                            z-40
                            border border-[#3d6a86]

                            md:p-6 md:top-10
                        `
                    )}
                    ref={ref}
                >
                    <div
                        className="
                            flex justify-between items-center
                            mb-5
                        "
                    >
                        <Title
                            icon={FaBell}
                            className="mb-0"
                        >
                            {t('notification.notifications')}
                        </Title>
                        <Button
                            variant="link"
                            icon={FaCheck}
                            className="
                                !p-0
                                text-xs font-medium
                                text-[#3d6a86]
                            "
                            onClick={onClickMarkAllAsRead}
                            visible={activeTab != 'read'}
                        >
                            {t('notification.markAllAsRead')}
                        </Button>
                    </div>
                    <div className="tabs">
                        <Tab value="unread" lbl={t('notification.unread')} activeTab={activeTab} onClick={setActiveTab} />
                        <Tab value="read" lbl={t('notification.read')} activeTab={activeTab} onClick={setActiveTab} />
                    </div>
                    {[...globalState.notifications.filter(x => (activeTab === 'read' ? x.isRead : !x.isRead))]
                        .sort((a, b) => (a.sentOn > b.sentOn ? 0 : -1))
                        .map(notification)
                    }
                </Box>
            </div>
        );
    }
);

NotificationListView.displayName = 'NotificationListView';

export default NotificationListView;