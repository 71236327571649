import * as API from '../utilities/Api';
import { SuggestionCommentStatus } from './SuggestionCommentStatus';

export function All(
    args: {
        suggestionID?: number,
        statuses?: Array<SuggestionCommentStatus>,
        includeUserUnderReview?: boolean
    }
) {
    args = {
        statuses: new Array(),
        includeUserUnderReview: false,
        ...args
    }

    const { statuses, includeUserUnderReview } = args as Required<typeof args>;

    const params = new URLSearchParams();
    args.suggestionID !== undefined && params.append('suggestionID', args.suggestionID.toString());
    statuses.forEach(status => params.append('statuses', status.toString()));
    params.append('includeUserUnderReview', includeUserUnderReview ? 'true' : 'false');

    return API.GET(`suggestionComment/all?${params}`);
}

export function Post(
    args: {
        suggestionID: number,
        text: string
    }
) {
    return API.POST(
        'suggestionComment',
        {
            body: JSON.stringify(args)
        }
    )
}

export function Put(
    args: {
        suggestionCommentID: number,
        text: string
    }
) {
    return API.PUT(
        'suggestionComment',
        {
            body: JSON.stringify(args)
        }
    )
}

export function Delete(
    suggestionCommentID: number
) {
    return API.DELETE(`suggestionComment/${suggestionCommentID}`);
};

export function Approve(
    suggestionCommentID: number
) {
    return API.POST(`suggestionComment/${suggestionCommentID}/approve`);
}