import { getCookie } from './Cookies';
import * as Environment from './Environment';
import { toast } from 'react-toastify';
import useTranslation from './TranslationOutsideComponent';
import { History } from './History';

type RequestOptions = Omit<RequestInit, 'method' | 'headers'>;

export async function GET(
    endpoint: string,
    requestOptions?: RequestOptions,
    toastError = true
) {
    return await _fetch(endpoint, { ...requestOptions, method: 'get' }, toastError);
}

export async function POST(
    endpoint: string,
    requestOptions?: RequestOptions,
    toastError = true
) {
    return await _fetch(endpoint, { ...requestOptions, method: 'post' }, toastError);
}

export async function PUT(
    endpoint: string,
    requestOptions?: RequestOptions,
    toastError = true
) {
    return await _fetch(endpoint, { ...requestOptions, method: 'put' }, toastError);
}

export async function DELETE(
    endpoint: string,
    requestOptions?: RequestOptions,
    toastError = true
) {
    return await _fetch(endpoint, { ...requestOptions, method: 'delete' }, toastError);
}

export interface ValidationResult {
    [key: string]: Array<string>;
};

type APIResponse = Response & {
    result?: {
        message?: string;
        data?: any;
        validationResult?: ValidationResult;

        success?: boolean;
        error?: boolean;
    }
};

async function _fetch(
    endpoint: string,
    requestOptions: Omit<RequestInit, 'headers'> = { method: 'get' },
    toastError = true
) {
    const t = await useTranslation();
    const token = getCookie('token');

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    token && headers.append('Authorization', `Bearer ${token}`);

    var response: APIResponse;
    try {
        response = await fetch(
            `${Environment.apiUrl}/${endpoint}`,
            {
                credentials: 'include',
                headers,
                ...requestOptions
            }
        );
    }
    catch (error) {
        toastError && toast.error(t('api.fetchFailed'), { autoClose: 1000 * 8 });
        throw error;
    }

    if (response.status == 401) {
        toastError && toast.error(t('api.unauthorized'));
        History.push('/login');
    }

    if (response.status == 403)
        toastError && toast.error(t('api.forbidden'))

    try {
        response.result = await response.json();
        
        // @ts-ignore
        response.json = function() { return this.result; };
    }
    catch(error)
    {}

    return response;
}