import React, { forwardRef, useEffect } from "react";
import Box from "components/Box";
import OutsideClickListener from "components/OutsideClickListener";
import Portal from "components/Portal";
import { twMerge } from "tailwind-merge";
export type ModalProps = React.HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean;
    boxClassName?: string;
    containerClassName?: string;
    onClickOutside?(event: React.MouseEvent): void;
};

const Modal = forwardRef<HTMLDivElement, ModalProps>(
    (
        {
            className,
            boxClassName,
            containerClassName,
            isOpen,
            children,
            onClickOutside = () => {},
            ...props
        },
        ref
    ): JSX.Element => {
        useEffect(() => {
            const removeOverflow = () => document.body.classList.remove('!overflow-hidden');

            if (isOpen)
                document.body.classList.add('!overflow-hidden');
            else
                removeOverflow();

            return removeOverflow;
        }, [isOpen])

        if (!isOpen)
            return <></>;
            
        return (
            <Portal
                className="root-modal"
            >
                <div
                    ref={ref}
                    {...props}
                    className={twMerge(
                        `
                            bg-[#3d6a86d9]
                            fixed left-0 top-0
                            w-full h-full
                            z-[60]
                            flex items-center justify-center
                            py-3 px-6
                        `,
                        className
                    )}
                >
                    <OutsideClickListener
                        onClickOutside={onClickOutside}
                        className={containerClassName}
                    >
                        <Box
                            className={boxClassName}
                        >
                            {children}
                        </Box>
                    </OutsideClickListener>
                </div>
            </Portal>
        );
    }
);

Modal.displayName = 'Modal';

export default Modal;