import * as API from '../utilities/Api';

export function login(
    username: string,
    password: string
) {
    return API.POST(
        'login',
        {
            body: JSON.stringify({
                Username: username,
                Password: password
            })
        }   
    );
}

export function quickLogin(
    token: string
) {
    return API.POST(
        'login/quickLogin',
        {
            body: JSON.stringify({
                Token: token
            })
        }
    );
};

export function twoFactor(
    requestGuid: string,
    code: string
) {
    return API.POST(
        'login/twoFactor',
        {
            body: JSON.stringify({
                RequestGuid: requestGuid,
                Code: code.trim()
            })
        }
    );
}