import { globalContext } from "app/GlobalContext";
import { useContext, useEffect } from "react";
import * as SuggestionVoteEndpoints from '../suggestionVote/SuggestionVoteEndpoints';

export default function SuggestionVoteCount() {
    const { globalState, dispatch } = useContext(globalContext);

    useEffect(() => {
        SuggestionVoteEndpoints.GetActiveVoteCount()
            .then(async response => {
                if (response.status != 200)
                    return;

                const { data: { activeVoteCount, activeVotesCap, activeVotesCapped } } = await response.json();
                dispatch({
                    type: 'suggestion.setActiveVoteCount',
                    payload: activeVoteCount
                });
                dispatch({
                    type: 'suggestion.setActiveVoteSettings',
                    payload: {
                        activeVotesCap,
                        activeVotesCapped
                    }
                });
            })
    }, []);

    if (!globalState.suggestionActiveVotesCapped)
        return null;

    return (
        <div
            className="
                fixed right-0 bottom-0 
                inline-flex items-end
                mb-1 sm:mb-3 lg:mb-4 xl:mb-5
                mr-1 sm:mr-4 lg:mr-6 xl:mr-8
                p-2
                z-40
                bg-white
                border-4 
                border-[#eef5fc]
            "
        >
            <div
                className="
                    text-4xl
                    font-bold
                "
            >
                {globalState.suggestionActiveVoteCount}
            </div>
            <div
                className="
                    text-lg
                    font-semibold
                    ml-1
                "
            >
                / {globalState.suggestionActiveVotesCap}
            </div>
            <div
                className="text-lg ml-2"
            >
                votes
            </div>
        </div>
    );
};