import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import * as RoleEndpoints from './RoleEndpoints';
import * as RoleActions from './RoleActions';
import * as PermissionEndpoints from '../permission/PermissionEndpoints';
import * as RolePermissionEndpoints from '../rolePermission/RolePermissionEndpoints';
import DataTable, { TableColumn } from "components/DataTable";
import Title from "components/Title";
import LabeledTextField from "components/LabeledTextField";
import Button from "components/Button";
import { FaSave, FaTrash } from "react-icons/fa";
import Box from "components/Box";
import Breadcrumbs from 'components/Breadcrumbs';
import Error from "components/Error";
import { History } from "utilities/History";
import { toast } from "react-toastify";

interface RoleViewRouteParams {
    roleID?: string
}

export default function RoleDetailView(props: RouteComponentProps<RoleViewRouteParams>) {
    const { t } = useTranslation();
    const roleID = Number(props.match.params.roleID || '0');

    const [role, setRole] = useState<any>();
    const [nameError, setNameError] = useState<string>();
    const [permissionRows, setPermissionRows] = useState(new Array<TableColumn<any>>());
    const [selectedRows, setSelectedRows] = useState(new Array<any>());

    useEffect(() => {
        if (!roleID)
            return;

        RoleEndpoints.Get(roleID)
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                setRole(result.data);
            });
    }, []);

    useEffect(() => {
        const promises = new Array<Promise<any>>();

        promises.push(PermissionEndpoints.All());
        roleID && promises.push(RolePermissionEndpoints.All(roleID));

        Promise.all(promises)
            .then(async responses => {
                const { result: permissionResult }  = responses[0];
                if (!permissionResult.success)
                    return;

                const permissionRows = permissionResult.data.map((permission: any) => ({
                    id: permission.permissionID,
                    name: permission.name
                }));
                setPermissionRows(permissionRows);

                if (roleID) {
                    const { result: rolePermissionResult } = responses[1];
                    if (!rolePermissionResult.success)
                        return;

                    const permissionSelection = permissionRows
                        .filter(x => rolePermissionResult.data.some(y => y.permissionID == x.id));
                    setSelectedRows(permissionSelection);
                }
            });
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: t('role.rolePermissions'),
            selector: row => row.name,
            sortable: true
        }
    ];

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setNameError(undefined);

        const formData = new FormData(event.currentTarget);
        const name = formData.get('name') as string;
        
        let _roleID = roleID;

        if (!_roleID) {
            // new role

            let { result } = await RoleEndpoints.Post(name);
            if (!result?.success) {
                setNameError(result?.message);
                return;
            }

            _roleID = result.data;
        } else {
            // existing role

            let { result } = await RoleEndpoints.Put(roleID, name);
            if (!result?.success) {
                setNameError(result?.message);
                return;
            }
        }

        await RolePermissionEndpoints.Put(_roleID, selectedRows.map(row => row.id));
        toast.success(t('role.updated'));
        History.push('/roles');
    }

    const onClickDelete = async () => {
        const deleted = await RoleActions.Delete(roleID);
        if (!deleted)
            return;

        History.push('/roles');
    };

    return (
        <div
            className="
                m-auto
                max-w-[800px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="/roles">{t('role.roles')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{!roleID ? t('role.newRole') : role?.name}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title>
                    {t('role.role')} - {role?.name || t('role.newRole')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <LabeledTextField 
                        name="name"
                        required
                        label={t('role.name')}
                        autoFocus={!roleID}
                        defaultValue={role?.name}
                    />
                    <Error>
                        {t(nameError!)}
                    </Error>
                    <DataTable
                        columns={columns}
                        data={permissionRows}
                        selectableRows
                        selectableRowSelected={row => selectedRows.includes(row)}
                        onSelectedRowsChange={state => setSelectedRows(state.selectedRows)}
                    />
                    <div
                        className="flex justify-end gap-2.5 2xl:gap-6"
                    >
                        <Button
                            icon={FaSave}
                            type="submit"
                        >
                            {t('save')}
                        </Button>
                        <Button
                            variant="outlined"
                            icon={FaTrash}
                            onClick={onClickDelete}
                            disabled={!roleID}
                        >
                            {t('delete')}
                        </Button>
                    </div>
                </form>
            </Box>
        </div>
    );
}