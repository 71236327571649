import ReactSelect, { Props } from 'react-select';
import { twMerge } from 'tailwind-merge';

export type SelectProps = Props & {};

const Select = (
    {
        classNames,
        placeholder,
        ...props
    }: SelectProps,
    ref: any // TODO: find ref type
  ): JSX.Element => {

    return (
        <ReactSelect
            {...props}
            placeholder={placeholder || ''}
            classNames={{
                ...classNames,
                control: state => twMerge(
                    `
                        !bg-[#eef5fc]
                        !border-2 !border-[#eef5fc]
                        !shadow-none
                    `,
                    state.isFocused && '!border-2 !border-black'
                ),
                valueContainer: state => '!px-2',
                multiValue: state => `
                    !bg-[#3d6a86]
                    text-white
                    !rounded-full
                `,
                multiValueLabel: state => `
                    !pl-2.5 !py-1
                    !text-white
                `,
                multiValueRemove: state => `
                    transition-all duration-300
                    !rounded-full
                    hover:!bg-white hover:!text-[#3d6a86]
                `,
                dropdownIndicator: state => '!text-[#565C63]'
            }}
        />
    );
};

Select.displayName = 'Select';

export default Select;