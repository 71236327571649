import IconButton from "components/IconButton";
import Modal, { ModalProps } from "components/Modal";
import Title from "components/Title/Title";
import React, { forwardRef } from "react";
import { IconBaseProps } from "react-icons";
import { FaTimesCircle } from "react-icons/fa";

export type TitledModalProps = ModalProps & {
    title: string;
    titleIcon?: React.FC<IconBaseProps>;
    showCloseButton?: boolean;
    onClickClose?(): void;
};

const TitledModal = forwardRef<HTMLDivElement, TitledModalProps>(
    (
        {
            showCloseButton,
            children,
            title,
            titleIcon,
            onClickClose,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <Modal
                {...props}
                ref={ref}
            >
                <div
                    className="flex items-start justify-between"
                >
                    <Title
                        icon={titleIcon}
                    >
                        {title}
                    </Title>
                    {showCloseButton && 
                        <IconButton 
                            icon={FaTimesCircle}
                            onClick={onClickClose}
                            className="ml-4"
                        />
                    }
                </div>
                {children}
            </Modal>
        );
    }
);

TitledModal.displayName = 'TitledModal';

export default TitledModal;