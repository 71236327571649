import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export type PortalProps = React.PropsWithChildren<{
    className?: string;
    element?: string;
}>;

const Portal = (
    {
        children,
        className = 'root-portal',
        element = 'div'
    }: PortalProps
) => {
    const [container] = useState(() => document.createElement(element));

    useEffect(() => {
        container.classList.add(className);
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container); 
        };
    }, []);

    return ReactDOM.createPortal(children, container);
};

Portal.displayName = 'Portal';

export default Portal;