export enum SuggestionStatus {
    UnderReview,
    Open,
    UnderAnalysis,
    Planned,
    UnderDevelopment,
    Completed,
    Denied,
    Expired
}

export const allSuggestionStatuses = [
    SuggestionStatus.Open,
    SuggestionStatus.UnderAnalysis,
    SuggestionStatus.Planned,
    SuggestionStatus.UnderDevelopment,
    SuggestionStatus.Completed,
    SuggestionStatus.UnderReview,
    SuggestionStatus.Expired,
    SuggestionStatus.Denied
];