import * as API from '../utilities/Api';

export function All() {
    return API.GET(`role/all`);
}

export function Get(roleID: number) {
    return API.GET(`role/${roleID}`);
}

export function Post(name: string) {
    return API.POST(
        `role`,
        {
            body: JSON.stringify({
                name
            })
        }
    );
}

export function Put(
    roleID: number,
    name: string
) {
    return API.PUT(
        `role/${roleID}`,
        {
            body: JSON.stringify({
                name
            })
        }
    );
}

export function Delete (roleID: number) {
    return API.DELETE(`role/${roleID}`);
}