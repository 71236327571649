import React, { forwardRef } from "react";
import { IconBaseProps } from "react-icons";
import { Link as RouterLink } from 'react-router-dom';
import { twMerge } from "tailwind-merge";

type PropsOf<
  C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<C, React.ComponentProps<C>>

export type LinkProps = Omit<PropsOf<RouterLink>, 'to'> & {
    to?: string;
    icon?: React.FC<IconBaseProps>;
    iconClassName?: string;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            children,
            className,
            icon,
            iconClassName,
            ...props
        },
        ref
    ): JSX.Element => {
        const Icon = icon;

        const Link = props.to?.startsWith('http') ? ({ to, ...props}) => <a href={to} {...props} /> : RouterLink;

        return (
            <Link
                {...props}
                ref={ref}
                className={twMerge(
                    `
                        inline-flex items-center justify-center gap-x-2
                        transition-colors duration-300
                        hover:text-[#4dc7f5]
                        cursor-pointer
                        group
                    `,
                    className && className
                )}
            >
                {Icon && 
                    <Icon 
                        className={twMerge(
                            `
                                w-4 h-4 min-w-4
                                fill-[#3d6a86] text-[#3d6a86]
                                transitions-all duration-300
                                group-hover:fill-[#4dc7f5]
                                group-hover:text-[#4dc7f5]
                            `,
                            iconClassName
                        )}
                    />
                }
                {children}
            </Link>
        );
    }
);

Link.displayName = 'Link';

export default Link;