import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import LabeledTextField from 'components/LabeledTextField';
import Box from 'components/Box';
import * as PasswordEndpoints from './PasswordEndpoints';
import Title from 'components/Title';
import { FaKey } from 'react-icons/fa';
import Link from 'components/Link';
import Error from 'components/Error';

interface ResetPasswordViewParams {
    token: string;
}

export default function ResetPasswordView(props: RouteComponentProps<ResetPasswordViewParams>) {
    const [error, setError] = useState<string>();
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);
    const params = new URLSearchParams(useLocation().search);

    const [passwordReset, setPasswordReset] = useState(false);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(undefined);
        setDisabled(true);

        const formData = new FormData(event.currentTarget);
        const { result } = await PasswordEndpoints.reset(
            params.get('token')!,
            formData.get('password') as string,
            formData.get('passwordConfirmation') as string
        );
        setDisabled(false);

        if (!result?.success) {
            setError(result?.message);
            return;
        }

        setPasswordReset(true);
    };

    const returnLink = (
        <Link
            to="/login"
        >
            {t('password.return')}
        </Link>
    );

    return (
        <>
            <Box
                className="md:bg-white/80"
            >
                <Title
                    icon={FaKey}
                >
                    {t('password.resetPassword')}
                </Title>
                {!passwordReset &&
                    <form
                        onSubmit={onSubmit}
                    >
                        <LabeledTextField
                            required
                            name="password"
                            label={t('password.newPassword')}
                            type="password"
                        />
                        <LabeledTextField
                            required
                            name="passwordConfirmation"
                            label={t('password.newPasswordConfirmation')}
                            type="password"
                        />
                        <Error>
                            {t(error!)}
                        </Error>
                        <div
                            className="flex justify-end items-center gap-6"
                        >
                            {returnLink}
                            <Button
                                type="submit"
                                disabled={disabled}
                            >
                                {t('password.reset')}
                            </Button>
                        </div>
                    </form>
                }
                {passwordReset &&
                    <>
                        <p
                            className="mb-5"
                        >
                            { t('password.passwordReset')}
                        </p>
                        <div
                            className="flex justify-end items-center gap-6"
                        >
                            {returnLink}
                        </div>
                    </>
                }
            </Box>
        </>
    );
}