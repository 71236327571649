import DatePicker from "components/DatePicker";
import { DatePickerProps } from "components/DatePicker/DatePicker";
import Label, { LabelProps } from "components/Label";
import { twMerge } from "tailwind-merge";

export type LabeledDatePickerProps = {
    name: string;
    label: string;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    min?: DatePickerProps['min'];

    defaultValue?: Date;
    labelProps?: Partial<Omit<LabelProps, 'children' | 'htmlFor'>>;
    datePickerProps?: Partial<Omit<DatePickerProps, 'type' | 'id' | 'name' | 'required' | 'autoFocus' | 'defaultValue' | 'disabled' | 'pattern'>>;
    className?: string;
    pattern?: string;
    showTime?: boolean;
};

export default function LabeledTextField({
    name,
    label,
    required,
    autoFocus,
    disabled,
    defaultValue,
    labelProps,
    datePickerProps,
    className,
    pattern,
    showTime,
    min,
    ...props
}: LabeledDatePickerProps) {
    return (
        <div
            className={twMerge(
                `
                    mb-5
                `,
                className
            )}
        >
            <Label
                {...labelProps}
                htmlFor={name}
            >
                {label}
            </Label>
            <DatePicker
                {...datePickerProps}
                id={name}
                name={name}
                required={required}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
                pattern={pattern}
                min={min}
                showTime={showTime}
            />
        </div>
    );
};