import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import RoleListView from './RoleListView';
import RoleDetailView from './RoleDetailView';

export default function RoleLayout(props: RouteComponentProps) {

    return (
        <Switch>
            <Route path={`${props.match.url}/new`} component={RoleDetailView} />
            <Route path={`${props.match.url}/:roleID`} component={RoleDetailView} />
            <Route path={`${props.match.url}`} component={RoleListView} />
        </Switch>
    );
}