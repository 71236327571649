import { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import Breadcrumbs from '../components/Breadcrumbs';
import * as SuggestionEndpoints from './SuggestionEndpoints';
import * as SuggestionCategoryEndpoints from '../suggestionCategory/SuggestionCategoryEndpoints';
import Title from 'components/Title';
import Box from 'components/Box';
import { FaCheck, FaComment, FaFilter, FaSearch } from 'react-icons/fa';
import Button from 'components/Button';
import Suggestion from './Suggestion';
import { globalContext } from 'app/GlobalContext';
import SuggestionVoteCount from './SuggestionVoteCount';
import { Permission } from 'permission/Permission';
import SubmitSuggestionModal from './SubmitSuggestionModal';
import SuggestionStatusFilter from './StatusFilter';
import { SuggestionStatus } from './SuggestionStatus';
import LabeledToggle from 'components/LabeledToggle';
import { useLocation } from 'react-router-dom';
import TextField from "components/TextField";

interface SuggestionListViewRouteParams {
    suggestionCategoryID?: string
}

export default function SuggestionListView(props: RouteComponentProps<SuggestionListViewRouteParams>) {
    const { globalState, dispatch } = useContext(globalContext);
    const { t } = useTranslation();
    const categoryID = Number(props.match.params.suggestionCategoryID || '0');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const statuses = [
        SuggestionStatus.Open,
        SuggestionStatus.UnderAnalysis,
        SuggestionStatus.Planned,
        SuggestionStatus.UnderDevelopment,
        SuggestionStatus.Completed
    ];
    if (!categoryID)
        statuses.push(...[
            SuggestionStatus.UnderReview,
            SuggestionStatus.Expired,
            SuggestionStatus.Denied
        ]);

    const initialSelectedStatuses = !categoryID ? [
        SuggestionStatus.Open,
        SuggestionStatus.UnderAnalysis,
        SuggestionStatus.Planned,
        SuggestionStatus.UnderDevelopment,
        SuggestionStatus.Completed,
    ] : undefined;
    
    const [category, setCategory] = useState<any>();
    const [suggestions, setSuggestions] = useState(new Array());
    const [orderBy, setOrderBy] = useState<Parameters<typeof SuggestionEndpoints.All>[0]['orderBy']>('date');
    const [statusFilter, setStatusFilter] = useState(initialSelectedStatuses || statuses);
    const [onlyShowUnread, setOnlyShowUnread] = useState(searchParams.get('onlyShowUnread') == 'true');
    const [unreadCount, setUnreadCount] = useState(0);
    const [suggestionCount, setSuggestionCount] = useState(0);
    const [filterText, setFilterText] = useState<string>('');

    const filteredSuggestions = useMemo(() => suggestions.filter(row => {
        const text = filterText.toLowerCase();
        const isReviewer = globalState.user.permissions.includes(Permission.Suggestion_Review);
        return (row.text as string).toLowerCase().includes(text)
            || (row.title as string).toLowerCase().includes(text)
            || (isReviewer && (row.suggestionID as string).toString().toLowerCase().includes(text))
            || (row.category && (row.category.name as string).toString().toLowerCase().includes(text));
    }), [filterText, suggestions]);

    useEffect(() => {
        if (categoryID)
            SuggestionCategoryEndpoints.Get(categoryID)
                .then(async response => {
                    const { result } = response
                    if (!result?.success)
                        return;

                    setCategory(result.data);
                });
    }, []);

    const loadSuggestions = async () => {
        
        if (statusFilter == undefined) return;

        const { result } = await SuggestionEndpoints.All({
            statuses: statusFilter.length ? statusFilter : statuses,
            suggestionCategoryID: categoryID || undefined,
            orderBy,
            onlyShowUnread
        });

        if (!result?.success)
            return;

        setSuggestions(result.data);
        setUnreadCount((result.data.filter((x) => x.isRead == false && x.status != SuggestionStatus.Denied && x.status != SuggestionStatus.Expired && x.status != SuggestionStatus.UnderReview)).length);
        setSuggestionCount((result.data.filter((x) => x.status != SuggestionStatus.Denied && x.status != SuggestionStatus.Expired && x.status != SuggestionStatus.UnderReview)).length);
    };

    const toggleOnlyShowUnread = async () => {
        setOnlyShowUnread(!onlyShowUnread);
    };

    const onClickMarkAllAsRead = async () => {
        const { result } = await SuggestionEndpoints.MarkAllAsRead();

        if (result?.success)
            loadSuggestions();
    };

    useEffect(() => { 
        loadSuggestions();
    }, [orderBy, statusFilter, onlyShowUnread]);

    const onChangeFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFilterText(value);
    };

    return (
        <div
            className="max-w-[1100px] m-auto"
        >
            <Breadcrumbs>
                <Breadcrumbs.Item
                    to="/"
                >
                    {t('home.home')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                    to="/suggestions"
                >
                    {t('suggestion.suggestions')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                    {category?.name || t('suggestionCategory.viewAllSuggestions')}
                </Breadcrumbs.Item>
            </Breadcrumbs>
            {globalState.user.permissions.includes(Permission.Suggestion_Write) && <SuggestionVoteCount />}
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-5
                    "
                >
                    <Title
                        icon={FaComment}
                    >
                        {category?.name || t('suggestion.suggestions')}
                    </Title>
                    <div
                        className="
                            mb-5
                            flex items-center justify-end gap-x-2.5 2xl:gap-x-6 gap-y-2 flex-wrap
                        "
                    >
                        <SubmitSuggestionModal
                            onSubmit={loadSuggestions}
                        />
                    </div>
                </div>
                <div
                    className="
                        mb-5
                        flex items-center justify-start gap-x-2.5 2xl:gap-x-6 gap-y-4 flex-wrap
                    "
                >
                    <Button
                        icon={FaFilter}
                        variant="outlined"
                        onClick={() => setOrderBy('popularity')}
                    >
                        {t('suggestion.mostPopular')}
                    </Button>
                    <Button
                        icon={FaFilter}
                        variant="outlined"
                        onClick={() => setOrderBy('date')}
                    >
                        {t('suggestion.newest')}
                    </Button>
                    <TextField
                        icon={FaSearch}
                        placeholder={t('search')}
                        className="
                                text-sm 
                                font-bold 
                                bg-white
                                border-4 
                                h-12
                                w-80
                            "
                        onChange={onChangeFilterText}
                    />
                    <SuggestionStatusFilter
                        statuses={statuses}
                        InitialSelectedStatuses={initialSelectedStatuses}
                        onFilterChange={statuses => setStatusFilter(statuses)}
                    />
                </div>
                {globalState.suggestionDisplayUnreadFields &&
                    <div
                        className="flex justify-between mb-10"
                    >
                        <LabeledToggle
                            label={t('suggestion.unreadOnly')}
                            name="unreadOnly"
                            className="gap-x-2 w-auto mt-auto mb-0"
                            checked={onlyShowUnread}
                            ToggleProps={{ onChange: toggleOnlyShowUnread }}
                        />
                        <div className="flex flex-col gap-y-1">
                            <div
                                className="inline-flex items-end justify-end"
                            >
                                <div
                                    className="text-xl font-bold"
                                >
                                    {unreadCount}
                                </div>
                                <div
                                    className="text-xl font-semibold ml-1"
                                >
                                    / {suggestionCount}
                                </div>
                                <div
                                    className="text-lg ml-2"
                                >
                                    {t('suggestion.read')}
                                </div>
                            </div>
                            <Button
                                variant="link"
                                icon={FaCheck}
                                className="!p-0 text-xs font-medium text-[#3d6a86]"
                                onClick={onClickMarkAllAsRead}
                            >
                                {t('notification.markAllAsRead')}
                            </Button>
                        </div>
                    </div>
                }
                <div>
                    {filteredSuggestions.map(suggestion => (
                        <Suggestion
                            key={suggestion.suggestionID}
                            suggestion={suggestion}
                            onDeleted={loadSuggestions}
                            className="mb-8"
                        />
                    ))}
                </div>
            </Box>
        </div>
    );
}