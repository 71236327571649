import { useCombinedRefs } from "hooks/UseCombinedRefs";
import React, { forwardRef, useMemo, useRef } from "react";
import { twMerge } from 'tailwind-merge';

export type DatePickerProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'defaultValue'
> & {
    showTime?: boolean,
    defaultValue?: Date,
};

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
    (
        {
            className,
            showTime,
            defaultValue,
            ...props
        },
        ref
    ): JSX.Element => {
        const innerRef = useRef<HTMLInputElement>(null);
        const combinedRefs = useCombinedRefs<HTMLInputElement>(ref, innerRef);
        const value = useMemo(() => {
            if (!defaultValue) {
                return '';
            }

            defaultValue.setMinutes(defaultValue.getMinutes() - defaultValue.getTimezoneOffset());

            return defaultValue.toISOString().slice(0, 16);
        }, [defaultValue])

        return (
            <div
                className="relative flex"
            >
                <input
                    {...props}
                    type={showTime ? 'datetime-local' : 'date'}
                    ref={combinedRefs}
                    defaultValue={value}
                    className={twMerge(
                        `
                            w-full h-10
                            px-2.5
                            border border-solid border-[#eef5fc]
                            bg-[#eef5fc]
                            outline-none

                            transition-colors duration-300
                            focus:border-[#3d6a86]
                        `,
                        className && className
                    )}
                />
            </div>
        );
    }
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;