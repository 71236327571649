export enum PublicationCategory {
    News,
    Maintnance,
    Update,
    Info
}

export const allPublicationCategories = [
    PublicationCategory.News,
    PublicationCategory.Maintnance,
    PublicationCategory.Update,
    PublicationCategory.Info
];

export enum Language {
    En = 1,
    Fr = 2
}