import Label, { LabelProps } from "components/Label";
import TextArea, { TextAreaProps, TextAreaRef } from "components/TextArea";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type LabeledTextAreaProps = {
    name: string;
    label: string;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    defaultValue?: string;
    labelProps?: Partial<Omit<LabelProps, 'children' | 'htmlFor'>>;
    textAreaProps?: Partial<Omit<TextAreaProps, 'id' | 'name' | 'required' | 'autoFocus' | 'defaultValue' | 'disabled'>>;
    className?: string;
};

export type LabeledTextAreaRef = TextAreaRef;

const LabeledTextArea = forwardRef<LabeledTextAreaRef, LabeledTextAreaProps>(
    (
        {

            name,
            label,
            required,
            autoFocus,
            disabled,
            defaultValue,
            labelProps,
            textAreaProps,
            className,
            ...props
        },
        ref
    ) => {
    return (
        <div
            className={twMerge(
                `
                    mb-5
                `,
                className
            )}
        >
            <Label
                {...labelProps}
                htmlFor={name}
            >
                {label}
            </Label>
            <TextArea
                {...textAreaProps}
                id={name}
                name={name}
                required={required}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                disabled={disabled}
                ref={ref}
            />
        </div>
    );
});

LabeledTextArea.displayName = 'LabeledTextArea';

export default LabeledTextArea;