import * as API from '../utilities/Api';

export function NotifyMe() {
    return API.POST(`__dev/notifyMe`);
}

export function NotifyMePush() {
    return API.POST(`__dev/notifyMePush`);
}

export function ClearMyNotifications() {
    return API.POST(`__dev/clearMyNotifications`)
}