import { useTranslation } from "react-i18next";
import { FaPen, FaSearch, FaTrash} from "react-icons/fa";
import { useState, useEffect, useMemo } from 'react';
import * as UserEndpoints from './UserEndpoints';
import DataTable, { Media, TableColumn } from "components/DataTable";
import RoleBadge from "role/RoleBadge";
import TextField from "components/TextField";
import Button from "components/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Path from '../utilities/Path';
import * as UserActions from './UserActions';

interface UserListProps {
    clientID?: number;
}

export default function UserList(props: UserListProps) {
    const { t } = useTranslation();
    const routeMatch = useRouteMatch();
    const history = useHistory();

    const [rows, setRows] = useState(new Array<any>());
    const [filterText, setFilterText] = useState<string>('');

    const filteredRows = useMemo(() => rows.filter(row => {
        const text = filterText.toLowerCase();

        return (row.username as string).toLowerCase().includes(text)
            || (row.firstName as string).toLowerCase().includes(text)
            || (row.lastName as string).toLowerCase().includes(text)
            || (row.clientName as string).toLowerCase().includes(text)
            || (row.roles as Array<any>).findIndex(role => role.name.toLowerCase().includes(text)) >= 0;
    }), [filterText, rows]);

    const columns: TableColumn<any>[] = [
        {
            name: t('user.username'),
            selector: row => row.username,
            sortable: true
        },
        {
            name: t('user.firstName'),
            selector: row => row.firstName,
            sortable: true,
            hide: 1400
        },
        {
            name: t('user.lastName'),
            selector: row => row.lastName,
            sortable: true,
            hide: 1400
        },
        {
            name: t('client.client'),
            selector: row => row.clientName,
            sortable: true,
            hide: Media.SM,
            omit: !!props.clientID
        },
        {
            name: t('role.roles'),
            cell: row => <>
                {row.roles.map(role => (
                    <RoleBadge
                        key={role.roleID}
                        role={role}
                    />
                ))}
            </>,
            style: 'flex-wrap: wrap; gap: 5px',
            hide: 1200
        },
        {
            cell: row => <>
                <Button
                    onClick={() => {
                        var url = routeMatch.url;
                        if (routeMatch.path == '/clients/:clientID')
                            url = Path.Resolve(url, 'users');

                        history.push(Path.Resolve(url, row.id.toString()))
                    }}
                    variant="link"
                    icon={FaPen}
                    className="!py-0"
                >
                    {t('edit')}
                </Button>
                <Button
                    variant="link"
                    icon={FaTrash}
                    className="!py-0"
                    onClick={() => onClickDelete(row)}
                >
                    {t('delete')}
                </Button>
            </>,
            right: true,
            style:"flex-wrap: wrap; margin-top: 10px; margin-bottom: 10px"
        }
    ];

    const loadUsers = async () => {
        const response = await UserEndpoints.All({ clientID: props.clientID });
        if (response.status != 200)
            return;

        const { result } = response;
        if (!result?.success) return;

        const { data } = result;
        const rows = data.map(user => ({
            id: user.userID,
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            clientName: user.client.name,
            roles: user.roles
        }));
        setRows(rows);
    }

    useEffect(() => {
        const { clientID } = props;
        if (clientID != undefined && clientID == 0) return;

        UserEndpoints.All({ clientID: props.clientID })
            .then(async response => {
                if (response.status != 200)
                    return;

                const { result } = response;
                if (!result?.success) return;

                const { data } = result;
                const rows = data.map(user => ({
                    id: user.userID,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    clientName: user.client.name,
                    roles: user.roles
                }));
                setRows(rows);
            });
    }, []);

    const onChangeFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFilterText(value);
    };

    const onClickDelete = async (row) => {
        const deleted = await UserActions.Delete(row.id);
        if (!deleted)
            return;
        
        await loadUsers();
    };

    return (
        <DataTable
            columns={columns}
            data={filteredRows}
            subHeader
            subHeaderComponent={
                <TextField
                    icon={FaSearch}
                    placeholder={t('search')}
                    className="
                        w-72
                        pl-4
                        -ml-4
                        bg-white
                    "
                    onChange={onChangeFilterText}
                />
            }
        />
    );
}