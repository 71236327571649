import { TFunction, useTranslation as reactUseTranslation } from "react-i18next";
import { WaitUntil } from "./WaitUntil";

var t: TFunction<'translation'> | null = null;

export function TranslationOutsideComponent(props: any) {
    const { t: translation } = reactUseTranslation();
    t = translation;
    return null;
}

export default async function useTranslation() {
    await WaitUntil(() => t != null);
    return t!;
}