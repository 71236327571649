import Badge from "components/Badge";
import Button from "components/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import { allSuggestionStatuses, SuggestionStatus } from "./SuggestionStatus";

interface Props {
    statuses?: Array<SuggestionStatus>;
    InitialSelectedStatuses?: Array<SuggestionStatus>;
    onFilterChange?(statuses: Array<SuggestionStatus>): void;
};

const SuggestionStatusFilter = ({
    statuses,
    InitialSelectedStatuses,
    onFilterChange
}: Props) => {
    statuses = statuses || allSuggestionStatuses;

    const { t } = useTranslation();
    const [selectedStatuses, setSelectedStatuses] = useState(InitialSelectedStatuses || new Array<SuggestionStatus>());

    const onClickStatus = (status: SuggestionStatus) => {
        var statuses = [...selectedStatuses];

        if (selectedStatuses.includes(status))
            statuses = selectedStatuses.filter(s => s != status);
        else
            statuses.unshift(status);

        setSelectedStatuses(statuses);

        onFilterChange && onFilterChange(statuses);
    };

    const onClickClear = () => {
        setSelectedStatuses([]);
        onFilterChange && onFilterChange([]);
    }

    return (
        <div
            className="inline-flex gap-2 flex-wrap"
        >
            {statuses.map(status => (
                <Badge
                    key={status}
                    content={t(`suggestion.suggestionStatuses.${status}`)}
                    className={twMerge(
                        `
                            text-xs
                            bg-suggestionStatus-${status}
                            cursor-pointer select-none
                        `,
                        !selectedStatuses.includes(status) && 'opacity-40'
                    )}
                    onClick={() => onClickStatus(status)}
                />
            ))}
            <Button
                variant="link"
                icon={FaTimes}
                className="
                    h-auto
                    !p-0 ml-2
                "
                onClick={onClickClear}
            >
                {t('clear')}
            </Button>
        </div>
    );
}

export default SuggestionStatusFilter;