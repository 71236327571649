import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type BreadcrumbsItemProps = React.LiHTMLAttributes<HTMLLIElement> & {
    to?: string
};

const BreadcrumbsItem = forwardRef<HTMLLIElement, BreadcrumbsItemProps>(
    ({ 
        children,
        to,
        className,
        ...props
    },
    ref
): JSX.Element => {
    return (
        <li 
            role="link"
            ref={ref}
            {...props}
        >
            {to &&
                <Link 
                    to={to}
                    className={twMerge(
                        'cursor-pointer hover:underline',
                        className
                    )}
                >
                    {children}
                </Link>
            }
            {!to &&
                <a
                    className={className}
                >
                    {children}
                </a>
            }
        </li>
    )
});

BreadcrumbsItem.displayName = 'BreadcrumbsItem';

export default BreadcrumbsItem;