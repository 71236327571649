import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import * as UserEndpoints from './UserEndpoints';
import * as RoleEndpoints from '../role//RoleEndpoints';
import * as UserRoleEndpoints from '../userRole/UserRoleEndpoints';
import Breadcrumbs from 'components/Breadcrumbs';
import Box from 'components/Box';
import Title from 'components/Title';
import { FaSave, FaTrash, FaUser } from 'react-icons/fa';
import LabeledTextField from 'components/LabeledTextField';
import DataTable, { TableColumn } from "components/DataTable";
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { History } from 'utilities/History';
import * as ClientEndpoints from '../client/ClientEndpoints';
import LabeledToggle from 'components/LabeledToggle';
import { ValidationResult } from 'utilities/Api';
import ValidationError from 'components/ValidationError';
import { convertToValidationResult } from 'utilities/Yup';
import Select from 'components/Select';
import Label from 'components/Label';
import * as UserActions from './UserActions';

interface UserViewRouteParams {
    clientID?: string;
    userID?: string;
}

export default function UserDetailView(props: RouteComponentProps<UserViewRouteParams>) {
    const { t } = useTranslation();
    const { match } = props;

    const clientID = Number(props.match.params.clientID || '0');
    const userID = Number(props.match.params.userID || '0');

    const [user, setUser] = useState<any>();
    const [validationResult, setValidationResult] = useState<ValidationResult>();
    const [clientSelectOptions, setClientSelectOptions] = useState(new Array());
    const [clientSelectedOption, setClientSelectedOption] = useState<any>();
    const [roleRows, setRoleRows] = useState(new Array<TableColumn<any>>());
    const [selectedRows, setSelectedRows] = useState(new Array<any>());

    useEffect(() => {
        const promises = new Array<Promise<any>>();
        promises.push(UserEndpoints.Get(userID));
        promises.push(ClientEndpoints.All());

        Promise.all(promises)
            .then(async responses => {
                var userData;
                const { result: userResult }  = responses[0];
                if (userResult.success) {
                    userData = userResult.data;
                    setUser(userData);
                }

                const { result: clientsResult }  = responses[1];
                if (clientsResult.success) {
                    const clientsData = (clientsResult.data as Array<any>).map(client => ({
                        value: client.clientID,
                        label: client.name
                    }));
                    setClientSelectOptions(clientsData);
                    setClientSelectedOption(clientsData.find(clientOption => clientOption.value == (userData?.client.clientID || clientID)));
                }
            });
    }, []);

    useEffect(() => {
        const promises = new Array<Promise<any>>();

        promises.push(RoleEndpoints.All());
        userID && promises.push(UserRoleEndpoints.All(userID));

        Promise.all(promises)
            .then(async responses => {
                const { result: roleResult } = responses[0];
                if (!roleResult.success)
                    return;

                const roleRows = roleResult.data.map((role: any) => ({
                    id: role.roleID,
                    name: role.name
                }));
                setRoleRows(roleRows);

                if (userID) {
                    const { result: userRoleResult } = responses[1];
                    if (!userRoleResult.success)
                        return;

                    const roleSelection = roleRows
                        .filter(x => userRoleResult.data.some(y => y.roleID == x.id));
                    setSelectedRows(roleSelection);
                }
            });
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: t('role.name'),
            selector: row => row.name,
            sortable: true
        }
    ];

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValidationResult(undefined);

        const schema = yup.object().shape({
            userID: yup.number(),
            username: yup.string().trim().required().label(t('user.username')),
            firstName: yup.string().trim().required().label(t('user.firstName')),
            lastName: yup.string().trim().required().label(t('user.lastName')),
            email: yup.string().trim().required().email().label(t('user.email')),
            clientID: yup.number().required().label(t('user.client')),
            password: yup.string().nullable().when(
                'userID',
                {
                    is: (userID: number) => !userID,
                    then: yup.string().trim().required()
                }
            ).label(t('user.newPassword')),
            active: yup.boolean()
        });

        var data;
        try {
            const formData = new FormData(event.currentTarget);
            data = await schema.validate({
                userID: userID || undefined,
                username: formData.get('username'),
                firstName: formData.get('firstName'),
                lastName: formData.get('lastName'),
                email: formData.get('email'),
                password: formData.get('password') || null,
                clientID: clientSelectedOption?.value,
                active: !Boolean(formData.get('disabled'))
            }, { 
                abortEarly: false
            });
        } catch (error) {
            const validationResult = convertToValidationResult(error as yup.ValidationError);
            setValidationResult(validationResult);
            return;
        }

        let _userID = userID;

        if (!_userID) {
            // new user

            let { result } = await UserEndpoints.Post(data);
            if (!result?.success) {
                setValidationResult(result?.validationResult);
                return;
            }

            _userID = result.data;
        } else {
            // existing user
            
            let { result } = await UserEndpoints.Put(data);
            if (!result?.success) {
                setValidationResult(result?.validationResult);
                return;
            }
        }

        await UserRoleEndpoints.Put(_userID, selectedRows.map(row => row.id));
        toast.success(t('user.updated'));

        if (match.path == '/clients/:clientID/users/:userID') {
            History.push(`/clients/${match.params.clientID}`);
            return;
        }

        History.push('/users');
    }
    
    const onChangeSelect = selectedOptions => setClientSelectedOption(selectedOptions);

    const onClickDelete = async () => {
        const deleted = await UserActions.Delete(userID);
        if (!deleted)
            return;

        if (match.path == '/clients/:clientID/users/:userID') {
            History.push(`/clients/${match.params.clientID}`);
            return;
        }

        History.push('/users');
    };
    
    return (
        <div
            className="
                m-auto
                max-w-[1200px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                {clientID &&
                    <Breadcrumbs.Item
                        to={`/clients`}
                    >
                            {t('client.clients')}
                    </Breadcrumbs.Item>
                }
                {clientID &&
                    <Breadcrumbs.Item
                        to={`/clients/${clientID}`}
                    >
                        {clientSelectOptions.find(x => x.value == clientID)?.label}
                    </Breadcrumbs.Item>
                }
                {!clientID && (
                    <Breadcrumbs.Item to="/users">{t('user.users')}</Breadcrumbs.Item>
                )}
                <Breadcrumbs.Item>{!userID ? t('user.newUser') : user?.username}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title
                    icon={FaUser}
                >
                    {t('user.user')} - {user?.username || t('user.newUser')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <Title>
                        {t('user.info')}
                    </Title>
                    <LabeledTextField
                        name="username"
                        label={t('user.username')}
                        required
                        autoFocus={!!userID}
                        defaultValue={user?.username}
                        autoComplete="off"
                    />
                    <ValidationError
                        name="username"
                        result={validationResult}
                    />
                    <LabeledTextField
                        name="firstName"
                        label={t('user.firstName')}
                        required
                        defaultValue={user?.firstName}
                    />
                    <ValidationError
                        name="firstName"
                        result={validationResult}
                    />
                    <LabeledTextField
                        name="lastName"
                        label={t('user.lastName')}
                        required
                        defaultValue={user?.lastName}
                    />
                    <ValidationError
                        name="lastName"
                        result={validationResult}
                    />
                    <LabeledTextField
                        name="email"
                        label={t('user.email')}
                        type="email"
                        required
                        defaultValue={user?.email}
                    />
                    <ValidationError
                        name="email"
                        result={validationResult}
                    />
                    <Label>
                        {t('client.client')}
                    </Label>
                    <Select
                        options={clientSelectOptions}
                        className="mb-5"
                        value={clientSelectedOption}
                        onChange={onChangeSelect}
                        name="clientID"
                    />
                    <ValidationError
                        name="clientID"
                        result={validationResult}
                    />
                    <Title>
                        {t('user.password')}
                    </Title>
                    <LabeledTextField
                        name="password"
                        label={t('user.newPassword')}
                        type="password"
                        required={!userID}
                    />
                    <ValidationError
                        name="password"
                        result={validationResult}
                    />
                    <Title>
                        {t('role.roles')}
                    </Title>
                    <DataTable
                        columns={columns}
                        data={roleRows}
                        selectableRows
                        selectableRowSelected={row => selectedRows.includes(row)}
                        onSelectedRowsChange={state => setSelectedRows(state.selectedRows)}
                    />
                    {user?.userID && (
                        <>
                            <Title>
                                {t('user.quickConnect')}
                            </Title>
                            <LabeledTextField
                                name="quiConnectToken"
                                label={t('user.token')}
                                defaultValue={user.quickConnectToken}
                                disabled
                                showCopyButton
                            />
                            <Title>
                                {t('user.security')}
                            </Title>
                            <LabeledToggle
                                name="disabled"
                                label={t('user.accountDisabled')}
                                defaultChecked={!user.active}
                            />
                        </>
                    )}
                    <div
                        className="flex justify-end gap-2.5 2xl:gap-6 mt-10"
                    >
                        <Button
                            type="submit"
                            icon={FaSave}
                        >
                            {t('save')}
                        </Button>
                        <Button
                            icon={FaTrash}
                            variant="outlined"
                            onClick={onClickDelete}
                            disabled={!userID}
                        >
                            {t('delete')}
                        </Button>
                    </div>
                </form>
            </Box>
        </div>
    );
}