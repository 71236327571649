import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
};

export type TextAreaRef = HTMLTextAreaElement;

const TextArea = forwardRef<TextAreaRef, TextAreaProps>(
    (
        {
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <textarea 
                {...props}
                ref={ref}
                className={twMerge(
                    `
                        w-full
                        p-2.5
                        border border-solid border-[#eef5fc]
                        bg-[#eef5fc]
                        outline-none

                        transition-colors duration-300
                        focus:border-[#3d6a86]
                    `,
                    className
                )}
            />
        );
    }
);

TextArea.displayName = 'TextArea';

export default TextArea;