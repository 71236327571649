import Label, { LabelProps } from "components/Label";
import Toggle, { ToggleProps } from "components/Toggle";
import { twMerge } from "tailwind-merge";

export type LabeledToggleProps = {
    name: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    defaultChecked?: boolean;
    checked?: boolean;
    labelProps?: Partial<Omit<LabelProps, 'children' | 'htmlFor'>>;
    ToggleProps?: Partial<Omit<ToggleProps, 'id' | 'name' | 'required' | 'disabled'>>;
    className?: string;
};

export default function LabeledToggle({
    name,
    label,
    required,
    disabled,
    defaultChecked,
    checked,
    labelProps,
    ToggleProps,
    className,
    ...props
}: LabeledToggleProps) {
    return (
        <div
            className={twMerge(
                `
                    w-full
                    flex justify-between items-center
                    mb-5
                `,
                className
            )}
        >
            <Label
                {...labelProps}
                htmlFor={name}
            >
                {label}
            </Label>
            <Toggle
                {...ToggleProps}
                id={name}
                name={name}
                required={required}
                disabled={disabled}
                defaultChecked={defaultChecked}
                checked={checked}
            />
        </div>
    );
};