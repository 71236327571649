import { createRoot } from 'react-dom/client';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import i18next from "i18next";
import TimeAgo from 'javascript-time-ago';
import timeAgoEn from 'javascript-time-ago/locale/en';
import timeAgoFr from 'javascript-time-ago/locale/fr';
import { I18nextProvider, useTranslation } from "react-i18next";
import AppLayout from './app/AppLayout';
import LoginLayout from './login/LoginLayout';
import PasswordLayout from './password/PasswordLayout';
import CreateAccountLayout from './createAccount/createAccountLayout';
import translationEn from "./translations/en.json";
import translationFr from "./translations/fr.json";
import { TranslationOutsideComponent } from './utilities/TranslationOutsideComponent';
import { ToastContainer } from 'react-toastify';
import { GlobalContext } from './app/GlobalContext';
import { StaticHistory } from 'utilities/History';
import { fr as yupFr } from 'yup-locales';
import yupEn from 'yup/lib/locale';
import * as yup from 'yup';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { customTranslationEn, customTranslationFr } from './utilities/Environment';

function deepObjectAssign(target, ...sources) {
    for (const source of sources) {
        for (const key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                if (typeof source[key] === 'object' && source[key] !== null) {
                    if (!target[key] || typeof target[key] !== 'object') {
                        target[key] = Array.isArray(source[key]) ? [] : {};
                    }
                    deepObjectAssign(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }
    }
    return target;
}

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'fr',
    defaultNS: 'common',
    resources: {
        en: {
            common: deepObjectAssign(translationEn, customTranslationEn)
        },
        fr: {
            common: deepObjectAssign(translationFr, customTranslationFr)
        },
    },
});
i18next.on('languageChanged', language => {
    TimeAgo.setDefaultLocale(language == 'en' ? 'en-us' : 'fr');
    yup.setLocale(language == 'en' ? yupEn : yupFr);
});

TimeAgo.addDefaultLocale(timeAgoEn);
TimeAgo.addLocale(timeAgoFr);
TimeAgo.setDefaultLocale(i18next.language == 'en' ? 'en-us' : 'fr');

yup.setLocale(i18next.language == 'en' ? yupEn : yupFr);

document.body.className = `
    text-[#3d6a86]
    bg-[#eef5fc]
    text-sm
`;

const container = document.getElementById('root')!;
const root = createRoot(container);

const Title = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('portal');
    });
    return null;
};

const OpenPrextraHelpWindow = () => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'F1') {
                event.preventDefault();
                var helpUrl = i18next.language == 'en' ?
                    'https://aide.prextra.com/en/' :
                    'https://aide.prextra.com/fr/Aide%20G%C3%A9n%C3%A9rale/Portail%20Partenaire/'
                window.open(helpUrl, 'PrextraHelp',);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
    }, []);
    return null;
};

root.render(
    <GlobalContext>
        <I18nextProvider i18n={i18next}>
            <Title />
            <OpenPrextraHelpWindow />
            <BrowserRouter>
                <StaticHistory />
                <Switch>-
                    <Route path="/login" component={LoginLayout} />
                    <Route path="/createAccount" component={CreateAccountLayout} />
                    <Route path="/password" component={PasswordLayout} />
                    <Route path="*" >
                        <Route path="/" component={AppLayout} />
                    </Route>
                </Switch>
            </BrowserRouter>
            <TranslationOutsideComponent />
            <ToastContainer
                position="bottom-right"
                autoClose={1000 * 5}
                limit={3}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName="
                    !text-[#3d6a86]
                    !rounded-none
                "
            />
        </I18nextProvider>
    </GlobalContext>
);