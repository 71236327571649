import useTranslation from '../utilities/TranslationOutsideComponent';
import * as SuggestionEndpoints from './SuggestionEndpoints';
import * as Dialog from 'components/Dialog';
import { toast } from 'react-toastify';

export async function Delete(roleID: number) {
    const t = await useTranslation();
    const yesNoResult = await Dialog.YesNo(t('suggestion.yesNoDelete'));

    if (yesNoResult == 'no')
        return false;

    const { result: deleteResult } = await SuggestionEndpoints.Delete(roleID);
    if (!deleteResult?.success) {
        deleteResult?.message && toast.error(t(deleteResult?.message));
        return false;
    }

    toast.success(t('suggestion.suggestionDeleted'));
    return true;
}