import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import * as PublicationEndpoints from './PublicationEndpoints';
import Breadcrumbs from 'components/Breadcrumbs';
import Box from 'components/Box';
import { FaArrowLeft } from 'react-icons/fa';
import './Publication.css';
import Button from 'components/Button';
import { History } from 'utilities/History';
import { Permission } from 'permission/Permission';
import { twMerge } from 'tailwind-merge';
import { globalContext } from 'app/GlobalContext';

interface PublicationViewRouteParams {
    publicationID?: string
}

export default function PublicationView(props: RouteComponentProps<PublicationViewRouteParams>) {
    const { t } = useTranslation();
    const { globalState } = useContext(globalContext);

    const [publication, setPublication] = useState<any>();
    const publicationID = Number(props.match.params.publicationID || '0');
    const canManagePublications = globalState.user.permissions.includes(Permission.Publication_Manage);

    useEffect(() => {
        PublicationEndpoints.Get(publicationID)
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;
                
                setPublication(result.data)
            });
    }, []);

    const publicationTranslation = publication?.publicationTranslations.find(t => t.langId == globalState.user.language);
    
    return (
        <div
            className="
                m-auto
                max-w-[1200px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to={canManagePublications && globalState.user.language == 2 ? '/publication' : 'publication/showall'}>
                    {canManagePublications && globalState.user.language == 2 ? t('publication.publications'): t('publication.communications')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>{!publicationID ? t('publication.newPublication') : publicationTranslation?.title}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box className="publication-content flex flex-col">
                <div className="publication_inline w-[950px]" dangerouslySetInnerHTML={{ __html: publicationTranslation?.htmlData}}></div>
                <div className={twMerge('ml-auto', !canManagePublications && 'hidden')}>
                    <Button
                        type="submit"
                        icon={FaArrowLeft}
                        onClick={() => History.push('/publication')}
                    >
                        {t('return')}
                    </Button>
                </div>
            </Box>
        </div>
    );
}