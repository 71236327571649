import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import React, { useState, useEffect, useMemo } from 'react';
import * as Path from '../utilities/Path';
import * as ClientEndpoints from './ClientEndpoints';
import Breadcrumbs from 'components/Breadcrumbs';
import Title from "components/Title";
import Button from "components/Button";
import DataTable, { TableColumn } from "components/DataTable";
import Box from "components/Box";
import { FaPen, FaPlus, FaSearch, FaUsers } from "react-icons/fa";
import TextField from "components/TextField";

export default function ClientListView(props: RouteComponentProps) {
    const { t } = useTranslation();

    const [rows, setRows] = useState(new Array<any>());
    const [filterText, setFilterText] = useState<string>('');

    const filteredRows = useMemo(() => rows.filter(row => {
        return (row.name as string)
            .toLowerCase()
            .includes(filterText.toLowerCase());
    }), [filterText, rows]);

    useEffect(() => {
        ClientEndpoints.All()
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                const rows = result.data.map(client => ({
                    id: client.clientID,
                    name: client.name
                }));
                setRows(rows);
            });
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: t('client.name'),
            selector: row => row.name,
            sortable: true
        },
        {
            cell: row => <>
                <Button
                    variant="link"
                    icon={FaPen}
                    onClick={() => props.history.push(Path.Resolve(props.match.url, row.id.toString()))}
                >
                    {t('edit')}
                </Button>
            </>,
            right: true
        }
    ];

    const onChangeFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFilterText(value);
    };

    return (
        <div
            className="
                m-auto
                max-w-[1200px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('client.clients')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-10
                    "
                >
                    <Title
                        icon={FaUsers}
                    >
                        {t('client.clients')}
                    </Title>
                    <Button
                        icon={FaPlus}
                        onClick={() => props.history.push(Path.Resolve(props.match.url, 'new'))}
                    >
                        {t('client.newClient')}
                    </Button>
                </div>
                <DataTable
                    columns={columns}
                    data={filteredRows}
                    subHeader
                    subHeaderComponent={
                        <TextField
                            icon={FaSearch}
                            placeholder={t('search')}
                            className="
                                w-72
                                pl-4
                                -ml-4
                                bg-white
                            "
                            onChange={onChangeFilterText}
                        />
                    }
                />
            </Box>
        </div>
    );
}