import { globalContext } from "app/GlobalContext";
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import Box from "components/Box";
import Title from "components/Title";
import { FaPaperPlane, FaTasks, FaComment } from 'react-icons/fa';
import Link from 'components/Link';
import * as SuggestionEndpoints from '../suggestion/SuggestionEndpoints';
import { SuggestionStatus } from '../suggestion/SuggestionStatus';
import Breadcrumbs from 'components/Breadcrumbs';
import SubmitSuggestionModal from 'suggestion/SubmitSuggestionModal';

export default function RoadmapView(props: RouteComponentProps) {
    const { globalState } = useContext(globalContext);
    const { t } = useTranslation();

    const [statusGroups, setStatusGroups] = useState({});
    const [unreadCount, setUnreadCount] = useState(0);
    const [suggestionCount, setSuggestionCount] = useState(0);
    const [labelReadUnread, setLabelReadUnread] = useState('');
    const [onlyShowUnread, setOnlyShowUnread] = useState('');

    useEffect(() => {
        SuggestionEndpoints.All({
            statuses: [
                SuggestionStatus.Open,
                SuggestionStatus.UnderAnalysis,
                SuggestionStatus.Planned,
                SuggestionStatus.UnderDevelopment,
                SuggestionStatus.Completed
            ],
            onlyShownOnRoadmap: true
        })
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;
    
                // group by status
                const statusGroups = result.data.reduce((resultObj, suggestion) => {
                    (resultObj[suggestion.status] = resultObj[suggestion.status] || new Array()).push(suggestion);
                    return resultObj;
                }, {});
                
                // order by title asc
                Object.getOwnPropertyNames(statusGroups).forEach(status =>
                    (statusGroups[status] as Array<any>).sort((a, b) => a.title.trim().toLowerCase().localeCompare(b.title.trim().toLowerCase()))
                );

                setStatusGroups(statusGroups);
            });

        SuggestionEndpoints.GetSuggestionReadUnread()
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                setUnreadCount((result.data.filter((x) => x.isRead == false && x.status != SuggestionStatus.Denied && x.status != SuggestionStatus.Expired && x.status != SuggestionStatus.UnderReview)).length);
                setSuggestionCount(result.data.length);
                setLabelReadUnread((result.data.filter((x) => x.isRead == false && x.status != SuggestionStatus.Denied && x.status != SuggestionStatus.Expired && x.status != SuggestionStatus.UnderReview)).length > 0 ?
                    t('roadmap.youHave') + (result.data.filter((x) => x.isRead == false && x.status != SuggestionStatus.Denied && x.status != SuggestionStatus.Expired && x.status != SuggestionStatus.UnderReview)).length + t('roadmap.seeUnreadSuggestions') : t('roadmap.seeAllSuggestions'));
                setOnlyShowUnread((result.data.filter((x) => x.isRead == false)).length > 0 ? '?onlyShowUnread=true' : '');
            });
    }, []);

    const whiteHr = (
        <hr 
            className="h-1 bg-white border-none mb-4"
        />
    );

    // before:bg-suggestionStatus-0
    // before:bg-suggestionStatus-1
    // before:bg-suggestionStatus-2
    // before:bg-suggestionStatus-3
    // before:bg-suggestionStatus-4
    // before:bg-suggestionStatus-5

    // hover:text-suggestionStatus-0
    // hover:text-suggestionStatus-1
    // hover:text-suggestionStatus-2
    // hover:text-suggestionStatus-3
    // hover:text-suggestionStatus-4
    // hover:text-suggestionStatus-5

    // fill-suggestionStatus-0
    // fill-suggestionStatus-1
    // fill-suggestionStatus-2
    // fill-suggestionStatus-3
    // fill-suggestionStatus-4
    // fill-suggestionStatus-5

    return (
        <>
            <Breadcrumbs>
                <Breadcrumbs.Item>{t('home.home')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title>
                    {t('roadmap.welcome')}
                </Title>
                <p
                    dangerouslySetInnerHTML={{  __html:  t('roadmap.mission') }}
                />
                {globalState.suggestionDisplayUnreadFields &&
                    <div>
                        <Link
                            to={`/suggestions/all${onlyShowUnread}`}
                            icon={FaComment}
                            className="mt-5"
                        >
                            {labelReadUnread}
                        </Link>
                    </div>
                }
            </Box>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-5
                    "
                >
                    <Title
                        icon={FaTasks}
                    >
                        {t('suggestion.roadmap')}
                    </Title>
                    <div
                        className="
                            mb-5
                            flex items-center justify-end gap-x-2.5 2xl:gap-x-6 gap-y-2 flex-wrap
                        "
                    >
                        <SubmitSuggestionModal />
                    </div>
                </div>
                <p
                    dangerouslySetInnerHTML={{  __html:  t('roadmap.details') }}
                    className="mb-8"
                />
                <hr
                    className="h-1 border-none bg-[#eef5fc] mb-8"
                />
                <div
                    className="
                        grid
                        grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5
                        items-stretch
                        mb-10
                        gap-4
                    "
                >
                    {Object.getOwnPropertyNames(statusGroups).map(status => (
                        <div
                            key={status}
                            className={`
                                relative
                                bg-[#eef5fc]
                                mb-8
                                px-4 py-5
                                border-t-[5px]
                                border-white

                                before:absolute
                                before:block
                                before:w-[80px]
                                before:h-[8px]
                                before:left-[calc(50%_-_40px)]
                                before:-top-1
                                before:content-[""]
                                before:bg-suggestionStatus-${status}
                            `}
                        >
                            <div
                                className="
                                    text-base font-bold text-center
                                    mb-4
                                "
                            >
                                {t(`suggestion.suggestionStatuses.${status}`)}
                            </div>
                            {whiteHr}
                            <div
                                className="flex flex-col items-start"
                            >
                                {(statusGroups[status] as Array<any>).map(suggestion => (
                                    <Link
                                        key={suggestion.suggestionID}
                                        to={`/suggestions/${suggestion.suggestionID}`}
                                        icon={FaPaperPlane}
                                        className={`
                                            font-bold
                                            mb-4
                                            hover:text-suggestionStatus-${status}
                                        `}
                                        iconClassName={`
                                            fill-suggestionStatus-${status}
                                            group-hover:fill-suggestionStatus-${status}
                                            min-w-4
                                        `}
                                    >
                                        {suggestion.title}
                                    </Link>
                                ))}
                            </div>
                    </div>
                    ))}
                </div>
            </Box>
        </>
    );
}