import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import PublicationListView from './PublicationListView';
import PublicationDetailView from './PublicationDetailView';
import PublicationView from './PublicationView';
import PublicationViewAll from './PublicationViewAll';

export default function PublicationLayout(props: RouteComponentProps) {

    return (
        <Switch>
            <Route path={`${props.match.url}/new`} component={PublicationDetailView} />
            <Route path={`${props.match.url}/edit/:publicationID`} component={PublicationDetailView} />
            <Route path={`${props.match.url}/show/:publicationID`} component={PublicationView} />
            <Route path={`${props.match.url}/showall`} component={PublicationViewAll} />
            <Route path={`${props.match.url}`} component={PublicationListView} />
        </Switch>
    );
}