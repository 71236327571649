import * as yup from 'yup';
import { ValidationResult } from './Api';

export function convertToValidationResult(error: yup.ValidationError) {
    const validationResult: ValidationResult = {};

    if (!error.inner.length)
        // @ts-ignore
        validationError.inner = [{
            path: error.path,
            message: error.message
        }];

        error.inner.map(error => {
        if (!error.path) return;
        validationResult[error.path] = new Array(...[error.message]);
    });

    return validationResult;
}