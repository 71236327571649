import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import * as PublicationEndpoints from '../publication/PublicationEndpoints';
import Breadcrumbs from 'components/Breadcrumbs';
import Box from 'components/Box';
import Title from 'components/Title';
import { FaPaperPlane, FaSave } from 'react-icons/fa';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { History } from 'utilities/History';
import { ValidationResult } from 'utilities/Api';
import { convertToValidationResult } from 'utilities/Yup';
import Editor, { EditorRef } from 'components/Editor';
import LabeledDatePicker from 'components/LabeledDatePicker';
import ValidationError from 'components/ValidationError';
import LabeledSelect from 'components/LabeledSelect';
import SelectOption from 'components/OldSelect/SelectOption';
import { Language, PublicationCategory } from './PublicationCategory';
import Label from '../components/Label';
import LabeledTextArea from '../components/LabeledTextArea';
import { globalContext } from 'app/GlobalContext';
import { Permission } from 'permission/Permission';

interface PublicationViewRouteParams {
    publicationID?: string
}

export default function PublicationDetailView(props: RouteComponentProps<PublicationViewRouteParams>) {
    const { t } = useTranslation();
    const { globalState } = useContext(globalContext);

    const [publication, setPublication] = useState<any>();
    const publicationID = Number(props.match.params.publicationID || '0');

    const [validationResult, setValidationResult] = useState<ValidationResult>();

    const languages = Object.values(Language).filter((value) => typeof value !== "string");

    var editors = [] as any;

    for (var lang of languages) {
        editors.push({ editor: useRef<EditorRef>(null), langId: lang });
    }

    useEffect(() => {
        PublicationEndpoints.Get(publicationID)
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;
                
                setPublication(result.data);
            });
    }, []);

    const publicationTranslation = publication?.publicationTranslations.find(t => t.langId == globalState.user.language);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValidationResult(undefined);

        const schema = yup.object().shape({
            publicationDate: yup.date().required().label(t('publication.publicationDate')),
            expirationDate: yup.date()
                .test((x, y) => x != undefined && x > y.parent.publicationDate)
                .required().label(t('publication.expirationDate')),
            category: yup.number().required().label(t('publication.category')),
            translations: yup.array().of(
                yup.object().shape({
                    summary: yup.string().trim().required().label(t('publication.summary')),
                    title: yup.string().trim().required().label(t('publication.title'))
                    //TODO: Revoir si on peut faire une validation par editor
                    //htmlData: yup.string().trim()
                    //    .test(() => editorRef.current?.body() != '<p>&nbsp;</p>')
                    //    .required().label(t('publication.publicationContent')),
                    //markdownData: yup.string().trim()
                })
            )
        });

        const formData = new FormData(event.currentTarget);

        var formObject = {
            publicationDate: formData.get('publicationDate'),
            expirationDate: formData.get('expirationDate'),
            category: formData.get('category'),
            translations: [] as any
        }

        for (const lang of languages) {
            const editor = editors.find(x => x.langId == lang).editor;   
            formObject.translations.push({
                langId: lang,
                summary: formData.get('summary' + lang),
                title: editor.current?.title(),
                htmlData: editor.current?.html(),
                markdownData: editor.current?.markdown()
            } as any)
        }

        var data;
        try {
            data = await schema.validate(formObject, { 
                abortEarly: false
            });
        } catch (error) {
            const validationResult = convertToValidationResult(error as yup.ValidationError);
            setValidationResult(validationResult);
            return;
        }

        let _publicationID = publicationID;

        if (!_publicationID) {
            // new publication

            let { result } = await PublicationEndpoints.Post(data);
            if (!result?.success) {
                setValidationResult(result?.validationResult);
                return;
            }

            _publicationID = result.data;
        } else {
            // existing publication
            let { result } = await PublicationEndpoints.Put(publicationID, data);
            if (!result?.success) {
                setValidationResult(result?.validationResult);
                return;
            }
        }

        toast.success(t('publication.updated'));
        History.push('/publication');
    }

    if (!globalState.user.permissions.includes(Permission.Publication_Manage))
        return null;
    
    return (
        <div
            className="
                m-auto
                max-w-[1200px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="/publication">{t('publication.publications')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{!publicationID ? t('publication.newPublication') : publicationTranslation?.title}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title
                    icon={FaPaperPlane}
                >
                    {t('publication.publications')} - {publicationTranslation?.title || t('publication.newPublication')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <LabeledDatePicker 
                        name="publicationDate"
                        label={t('publication.publicationDate')}
                        showTime
                        required
                        defaultValue={publication?.publicationDate && new Date(publication.publicationDate)}
                    />
                    <ValidationError
                        name="publicationDate"
                        result={validationResult}
                    />
                    <LabeledDatePicker 
                        name="expirationDate"
                        label={t('publication.expirationDate')}
                        showTime
                        required
                        defaultValue={publication?.expirationDate && new Date(publication.expirationDate)}
                    />
                    <ValidationError
                        name="expirationDate"
                        result={validationResult}
                    />
                    <LabeledSelect<PublicationCategory>
                        name="category"
                        label={t('publication.category')}
                        required
                    >
                        {Object.keys(PublicationCategory).filter(k => !isNaN(Number(k))).map(category => {
                            return <SelectOption<PublicationCategory>
                                key={category}
                                value={Number(category)}
                                selectedValue={publication?.category}
                            >
                                {t(`publication.publicationCategories.${category}`)}
                            </SelectOption>;
                        })}
                    </LabeledSelect>
                    <ul>
                        {languages.map((lang, index) => {
                            const publicationTranslation = publication?.publicationTranslations.find(t => t.langId == lang);
                            const editor = editors.find(x => x.langId == lang).editor;
                            const langLbl = Object.keys(Language).find(key => Language[key] === lang);

                            return <>
                                <LabeledTextArea
                                    name={"summary" + lang}
                                    label={t('publication.summary') + ` ${langLbl}`}
                                    defaultValue={publicationTranslation?.summary}
                                    required
                                    textAreaProps={{
                                        rows: 2
                                    }}
                                />
                                <ValidationError
                                    name={"translations[" + index + "].summary"}
                                    result={validationResult}
                                />
                                <div>
                                    <Label>
                                        {t('publication.content') + ` ${langLbl}`}
                                    </Label>
                                    <div className="mb-24">
                                        <div className="h-[700px]">
                                            <Editor
                                                ref={editor}
                                                data={publicationTranslation?.htmlData}
                                                placeholder={t('publication.publicationContent')}
                                                showTitle
                                                titlePlaceholder={t('publication.publicationTitle')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ValidationError
                                    name={"translations[" + index + "].title"}
                                    result={validationResult}
                                />
                            </>
                        })}
                    </ul>
                    <div
                        className="flex justify-end gap-2.5 2xl:gap-6 mt-10"
                    >
                        <Button
                            type="submit"
                            icon={FaSave}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </form>
            </Box>
        </div>
    );
}