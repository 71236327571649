import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type BadgeProps = Omit<
    React.HTMLAttributes<HTMLSpanElement>,
    'children'
> & {
    content?: number | string;
    max?: number;
};

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
    (
        {
            className,
            content,
            max,
            ...props
        },
        ref
    ): JSX.Element => {
        var text: string | null = null;
        if (typeof content === 'string')
            text = content;
        else if (content !== undefined) {
            if (max !== undefined && content > max)
                text = `${max}+`;
            else
                text = content.toString();
        }

        return (
            <span
                ref={ref}
                {...props}
                className={twMerge(
                    `
                        inline-flex items-center justify-center
                        px-2.5 py-1
                        rounded-full
                        bg-red-600 text-white
                        font-bold
                    `,
                    !text && 'px-1.5 py-1.5',
                    className
                )}
            >
                {text}
            </span>
        );
    }
);

Badge.displayName = 'Badge';

export default Badge;