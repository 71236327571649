import { FaDocker } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import translationEnCdid from "../translations/cdid/en.json";
import translationFrCdid from "../translations/cdid/fr.json";

export const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const apiUrl = isDevelopment ? 'https://localhost:5001' : !process.env.REACT_APP_API_URL ? 'https://portaildev-api.cdidnet.com' : process.env.REACT_APP_API_URL;
export const environmentImageUrlFr = process.env.REACT_APP_LOGO_NAME?.toLowerCase() === 'cdid' ? '/images/logo-cdid.svg' : '/images/logo-fr.svg';
export const environmentImageUrlEn = process.env.REACT_APP_LOGO_NAME?.toLowerCase() === 'cdid' ? '/images/logo-cdid.svg' : '/images/logo-en.svg';
export const customTranslationEn = process.env.REACT_APP_LOGO_NAME?.toLowerCase() === 'cdid' ? translationEnCdid : {};
export const customTranslationFr = process.env.REACT_APP_LOGO_NAME?.toLowerCase() === 'cdid' ? translationFrCdid : {};

export function EnvironmentDisplayName({
    className
}: {
    className?: string
}) {
    if (process.env.REACT_APP_ENV_DISPLAY_NAME)
        return (
            <span
                className={twMerge(
                    `
                        flex items-center
                        gap-2
                        text-sm capitalize
                    `,
                    className
                )}
            >
                <FaDocker />
                {process.env.REACT_APP_ENV_DISPLAY_NAME}
            </span>
        );

    return null;
}