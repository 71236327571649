import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import * as PublicationEndpoints from './PublicationEndpoints';
import Box from 'components/Box';
import './Publication.css';
import { globalContext } from 'app/GlobalContext';

export default function PublicationViewAll(props: RouteComponentProps) {
    const { globalState } = useContext(globalContext);

    const [publications, setPublications] = useState(new Array<any>());

    useEffect(() => {
        PublicationEndpoints.AllNotExpired()
        .then(async response => {
            const { result } = response;
            if (!result?.success) return;
    
            setPublications(result.data);
        });
    }, []);
    
    return (
        <>
            <div>&nbsp;</div>
            <div className="m-auto max-w-[1200px]">
                {publications.map(publication => {
                    const publicationTranslation = publication.publicationTranslations.find(t => t.langId === globalState.user.language);
                    return (
                        <Box key={publication.publicationID} className="publication-content flex flex-col">
                            <div className="publication_inline w-[950px]" dangerouslySetInnerHTML={{ __html: publicationTranslation?.htmlData}}></div>
                        </Box>
                    );
                })}
            </div>
        </>
    );
}