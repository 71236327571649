import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import { useState, useEffect } from 'react';
import { FaIdCardAlt, FaPen, FaPlus, FaTrash } from "react-icons/fa";
import * as Path from '../utilities/Path';
import * as RoleEndpoints from './RoleEndpoints';
import * as RoleActions from './RoleActions';
import Breadcrumbs from 'components/Breadcrumbs';
import Title from "components/Title";
import Button from "components/Button";
import DataTable, { Media, TableColumn } from "components/DataTable";
import Box from "components/Box";
import RoleBadge from "./RoleBadge";

export default function RoleListView(props: RouteComponentProps) {
    const { t } = useTranslation();

    const [rows, setRows] = useState(new Array<TableColumn<any>>());

    const loadRoles = async () => {
        const { result } = await RoleEndpoints.All();
        if (!result?.success)
            return;

        const rows = result.data.map((role: any) => ({
            id: role.roleID,
            name: role.name
        }));
        setRows(rows);
    };

    useEffect(() => {
        loadRoles();
    }, []);

    const onClickDelete = async row => {
        const deleted = await RoleActions.Delete(row.id);
        if (!deleted)
            return;
        
        await loadRoles();
    };

    const columns: TableColumn<any>[] = [
        {
            name: t('role.name'),
            selector: row => row.name,
            sortable: true,
            cell: row => (
                <RoleBadge
                    role={row}
                />
            )
        },
        {
            cell: row => <>
                <Button
                    variant="link"
                    icon={FaPen}
                    onClick={() => props.history.push(Path.Resolve(props.match.url, row.id.toString()))}
                >
                    {t('edit')}
                </Button>
                <Button
                    variant="link"
                    icon={FaTrash}
                    onClick={() => onClickDelete(row)}
                >
                    {t('delete')}
                </Button>
            </>,
            right: true
        }
    ];

    return (
        <div
            className="
                m-auto
                max-w-[800px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('role.roles')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-10
                    "
                >
                    <Title
                        icon={FaIdCardAlt}
                    >
                        {t('role.roles')}
                    </Title>
                    <Button
                        icon={FaPlus}
                        onClick={() => props.history.push(Path.Resolve(props.match.url, 'new'))}
                    >
                        {t('role.newRole')}
                    </Button>
                </div>
                <DataTable
                    columns={columns}
                    data={rows}
                />
            </Box>
        </div>
    );
}