import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    HtmlDataProcessor,
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    Autosave,
    Base64UploadAdapter,
    BlockQuote,
    Bold,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Heading,
    Highlight,
    HorizontalLine,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Markdown,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    SelectAll,
    SpecialCharacters,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Title,
    TodoList,
    Underline,
    Undo,
    EditorConfig,
    StylesProcessor,
    ViewDocument,
    DataProcessor,
} from 'ckeditor5';

// @ts-expect-error
import en from 'ckeditor5/translations/en.js';
// @ts-expect-error
import fr from 'ckeditor5/translations/fr.js';

import 'ckeditor5/ckeditor5.css';
import i18next from "i18next";

export type EditorProps = {
    data?: string,
    placeholder?: string,
    showTitle?: boolean,
    titlePlaceholder?: string,
};

export type EditorRef = {
    setData(value: string);
    html(): string;
    markdown(): string;
    title(): string;
    body(): string;
};

const Editor = forwardRef<EditorRef, EditorProps>(
    (
        {
            data,
            placeholder,
            showTitle,
            titlePlaceholder,
            ...props
        },
        ref
    ): JSX.Element => {
        const { t } = useTranslation();
        const editorRef = useRef<CKEditor<ClassicEditor>>(null);
        const [htmlProcessor] = useState<DataProcessor>(new HtmlDataProcessor(new ViewDocument(new StylesProcessor())));
        const [markdownProcessor, setMarkdownProcessor] = useState<DataProcessor>();

        useEffect(() =>{
            setMarkdownProcessor(editorRef.current?.editor?.data.processor);
        }, [editorRef.current?.editor]);

        useImperativeHandle(ref, () => ({
            setData: (value) => {
                if (editorRef.current && editorRef.current.editor) {
                    editorRef.current.editor.setData(value);
                }
            },
            html: () => {
                if (!editorRef.current?.editor)
                    return '';

                editorRef.current.editor.data.processor = htmlProcessor;

                return editorRef.current.editor.getData();
            },
            markdown: () => {
                if (!editorRef.current?.editor || !markdownProcessor)
                    return '';

                editorRef.current.editor.data.processor = markdownProcessor;

                return editorRef.current.editor.getData();
            },
            title: () => {
                if (!editorRef.current?.editor)
                    return '';

                editorRef.current.editor.data.processor = htmlProcessor;

                const editorData = editorRef.current?.editor?.getData() || '';
                const htmlEl = document.createElement('div');
                htmlEl.innerHTML = editorData;
                return htmlEl.getElementsByTagName('h1')[0]?.innerHTML || '';
            },
            body: () => {
                if (!editorRef.current?.editor)
                    return '';

                editorRef.current.editor.data.processor = htmlProcessor;

                const editorData = editorRef.current?.editor?.getData() || '';
                const htmlEl = document.createElement('div');
                htmlEl.innerHTML = editorData;
                htmlEl.getElementsByTagName('h1')[0]?.remove();

                return htmlEl.innerHTML;
            }
        }));

        const editorConfig = {
            toolbar: {
                items: [
                    'undo',
                    'redo',
                    '|',
                    'heading',
                    '|',
                    'fontSize',
                    'fontFamily',
                    'fontColor',
                    'fontBackgroundColor',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    '|',
                    'link',
                    'insertImage',
                    'insertTable',
                    'highlight',
                    'blockQuote',
                    '|',
                    'alignment',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'todoList',
                    'outdent',
                    'indent'
                ],
                shouldNotGroupWhenFull: false
            },
            plugins: [
                AccessibilityHelp,
                Alignment,
                Autoformat,
                AutoImage,
                Autosave,
                Base64UploadAdapter,
                BlockQuote,
                Bold,
                Code,
                Essentials,
                FontBackgroundColor,
                FontColor,
                FontFamily,
                FontSize,
                Heading,
                Highlight,
                HorizontalLine,
                ImageBlock,
                ImageCaption,
                ImageInline,
                ImageInsert,
                ImageInsertViaUrl,
                ImageResize,
                ImageStyle,
                ImageTextAlternative,
                ImageToolbar,
                ImageUpload,
                Indent,
                IndentBlock,
                Italic,
                Link,
                LinkImage,
                List,
                ListProperties,
                Markdown,
                MediaEmbed,
                Paragraph,
                PasteFromOffice,
                SelectAll,
                SpecialCharacters,
                Strikethrough,
                Subscript,
                Superscript,
                Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
                TextTransformation,
                TodoList,
                Underline,
                Undo,
                ...(showTitle ? [Title] : [])
            ],
            fontFamily: {
                supportAllValues: true
            },
            fontSize: {
                options: [10, 12, 14, 'default', 18, 20, 22],
                supportAllValues: true
            },
            heading: {
                options: [
                    {
                        model: 'paragraph',
                        title: 'Paragraph',
                        class: 'ck-heading_paragraph'
                    },
                    {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                        class: 'ck-heading_heading1'
                    },
                    {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                        class: 'ck-heading_heading2'
                    },
                    {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                        class: 'ck-heading_heading3'
                    },
                    {
                        model: 'heading4',
                        view: 'h4',
                        title: 'Heading 4',
                        class: 'ck-heading_heading4'
                    },
                    {
                        model: 'heading5',
                        view: 'h5',
                        title: 'Heading 5',
                        class: 'ck-heading_heading5'
                    },
                    {
                        model: 'heading6',
                        view: 'h6',
                        title: 'Heading 6',
                        class: 'ck-heading_heading6'
                    }
                ]
            },
            image: {
                toolbar: [
                    'toggleImageCaption',
                    'imageTextAlternative',
                    '|',
                    'imageStyle:inline',
                    'imageStyle:wrapText',
                    'imageStyle:breakText',
                    '|',
                    'resizeImage'
                ]
            },
            initialData: '',
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
                decorators: {
                    toggleDownloadable: {
                        mode: 'manual',
                        label: 'Downloadable',
                        attributes: {
                            download: 'file'
                        }
                    }
                }
            },
            list: {
                properties: {
                    styles: true,
                    startIndex: true,
                    reversed: true
                }
            },
            menuBar: {
                isVisible: true
            },
            placeholder: placeholder,
            title: {
                placeholder: titlePlaceholder,
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
            },
            translations: [fr, en],
            language: {
                ui: i18next.language,
                content: i18next.languages[0]
            }
        } as EditorConfig;

        return (
            <CKEditor
                editor={ClassicEditor}
                config={editorConfig}
                ref={editorRef}
                data={data}
            />
        )
    }
);

Editor.displayName = 'Editor';

export default Editor;