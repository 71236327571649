import * as PushNotificationEndpoints from '../PushNotification/PushNotificationEndpoints';

export async function register() {
    if (!('serviceWorker' in navigator && 'PushManager' in window)) {
        console.warn('Push notification are not supported');
        return;
    }

    await registerSW();
    await subscribe();
}

declare global {
    interface Window {
        clients: any
    }
}

export async function unregister() {
    return navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
            for (let registration of registrations)
                registration.unregister();
        });
}

var pushNotificationSW = null as null | ServiceWorkerRegistration;

function registerSW() {
    return navigator.serviceWorker.register('/PushNotificationServiceWorker.js')
        .then(registration => {
            pushNotificationSW = registration;
        })
        .catch(error => console.error('Push notification service worker registration error', error));
}

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

async function subscribe() {
    var subscription = await pushNotificationSW!.pushManager.getSubscription();

    if (subscription)
        // already subscribed
        // no need to register again
        return;

    const { result } = await PushNotificationEndpoints.GetKey();
    if (!result?.success)
        return;

    const { data: key } = result;

    subscription =
        await pushNotificationSW!.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(key)
        })
        .then(subscription => {
            return subscription;
        })
            .catch(error => console.log('Push notification user subscription error', error)) as PushSubscription;

    if (!subscription)
        return;

    await saveSubscription(key, subscription);
}

async function saveSubscription(publicKey, subscription) {
    const rawP256dh = subscription.getKey('p256dh');
    const p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(rawP256dh) as unknown as Array<number>));
    const rawSecret = subscription.getKey('auth');
    const secret = btoa(String.fromCharCode.apply(null, new Uint8Array(rawSecret) as unknown as Array<number>));

    await PushNotificationEndpoints.CreateSubscription(
        publicKey,
        subscription.endpoint,
        p256dh,
        secret
    )
    .then(response => {
        if (response.ok)
            console.log('Push notification registration created');
        else
            throw `Response status code ${response.status}`;
    })
    .catch(error => console.error('Push notification registration error', error));
}