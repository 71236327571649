import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import UserListView from './UserListView';
import UserDetailView from './UserDetailView';

export default function UserLayout(props: RouteComponentProps) {

    return (
        <Switch>
            <Route path={`${props.match.url}/new`} component={UserDetailView} />
            <Route path={`${props.match.url}/:userID`} component={UserDetailView} />
            <Route path={`${props.match.url}`} component={UserListView} />
        </Switch>
    );
}