import React, { forwardRef } from "react";
import { IconBaseProps } from "react-icons";
import { twMerge } from 'tailwind-merge';

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: React.FC<IconBaseProps>;
    iconClassName?: string;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    (
        {
            icon,
            className,
            iconClassName,
            children,
            ...props
        },
        ref
    ): JSX.Element => {
        const Icon = icon;
        return (
            <button
                {...props}
                ref={ref}
                className={twMerge(
                    `
                        w-[30px] h-[30px]
                        flex items-center justify-center
                        relative
                        group
                    `,
                    className && className
                )}
            >
                <Icon
                    className={twMerge(
                        `
                            w-6 h-6
                            ${!props.disabled ? 'fill-[#3d6a86]' : 'fill-[#8A9198]'}
                            ${!props.disabled ? 'text-[#3d6a86]' : 'text-[#8A9198]'}
            
                            transition-colors duration-300
                        `,
                        !props.disabled && 'group-hover:fill-[#4dc7f5]',
                        !props.disabled && 'group-hover:text-[#4dc7f5]',
                        iconClassName
                    )}
                />
                {children}
            </button>
        );
    }
);

IconButton.displayName = 'IconButton';

export default IconButton;