import Button from "components/Button";
import { useCombinedRefs } from "hooks/UseCombinedRefs";
import React, { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IconBaseProps } from "react-icons";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import { twMerge } from 'tailwind-merge';

export type TextFieldProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type'
> & {
    type?: 'text' | 'password' | 'email' | 'tel' | 'number';
    showCopyButton?: boolean,
    icon?: React.FC<IconBaseProps>;
    iconClassName?: string;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            className,
            showCopyButton,
            icon,
            iconClassName: iconClass,
            ...props
        },
        ref
    ): JSX.Element => {
        const { t } = useTranslation();
        const innerRef = useRef<HTMLInputElement>(null);
        const combinedRefs = useCombinedRefs<HTMLInputElement>(ref, innerRef);

        const Icon = icon;
        const iconClassName = twMerge(
            `
                absolute
                right-5 translate-x-1/2
                top-1/2 -translate-y-1/2
                w-4 h-4
                transition-colors duration-300
            `,
            iconClass
        );

        const onClickCopy = () => {
            if (!innerRef.current?.value)
                return;
                
            innerRef.current?.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(innerRef.current?.value!);
            toast.success(t('textCopied'));
        }

        return (
            <div
                className="relative flex"
            >
                <input
                    {...props}
                    ref={combinedRefs}
                    className={twMerge(
                        `
                            w-full h-10
                            px-2.5
                            border border-solid border-[#eef5fc]
                            bg-[#eef5fc]
                            outline-none

                            transition-colors duration-300
                            focus:border-[#3d6a86]
                        `,
                        Icon && 'pr-10',
                        className && className
                    )}
                />
                {
                    Icon &&
                    <Icon
                        className={iconClassName}
                    />
                }
                {showCopyButton && (
                    <Button
                        className="h-10"
                        iconClassName="fill-[#b6bcc3] group-hover:fill-[#8d9197]"
                        variant="outlined"
                        icon={FaCopy}
                        onClick={onClickCopy}
                    >
                    </Button>
                )}
            </div>
        );
    }
);

TextField.displayName = 'TextField';

export default TextField;