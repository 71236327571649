import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import Box from "components/Box";
import Link from "components/Link";
import Title from "components/Title";
import Error from "components/Error";
import * as LoginEndpoints from './LoginEndpoints';
import { History } from "utilities/History";

export default function QuickLoginView() {
    const { t } = useTranslation();
    const [error, setError] = useState<string>();
    const params = new URLSearchParams(useLocation().search);

    useEffect(() => {
        const token = params.get('token');
        const publicationId = params.get('publicationId');
        if (!token)
            return;

        new Promise(r => setTimeout(r, 1000 * 2.5))
            .then(async () => {
                const  { result } = await LoginEndpoints.quickLogin(token);
                if (!result?.success) {
                    setError(result?.message);
                    return;
                }

                const { data } = result;

                if (data.alreadyLoggedIn) {
                    History.push('/' + (publicationId ? `publication/show/${publicationId}` : ''));
                }
                else
                    History.push(`/login/2fa?requestGuid=${data.tfaRequestGuid}&deliveryMethod=${data.tfaDeliveryMethod}&to=${data.tfaTo}`
                        + (publicationId ? `&publicationId=${publicationId}` : ''));
            });
    }, []);

    return (
        <>
            <Box
                className="md:bg-white/80"
            >
                <Title
                    icon={FaLock}
                >
                    {t('login.connection')}
                </Title>
                {!error && 
                    <p>
                        {t('login.connecting')}
                    </p>
                }
                {error && 
                    <>
                        <Error
                            className="text-left"
                        >
                            {t(error)}
                        </Error>
                        <div
                            className="flex justify-end items-center gap-6"
                        >
                            <Link
                                to="/login"
                            >
                                {t('login.return')}
                            </Link>
                        </div>
                    </>
                }
            </Box>
        </>
    );
};