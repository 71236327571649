import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type SeparatorProps = React.HTMLAttributes<HTMLHRElement> & {
};

const Separator = forwardRef<HTMLHRElement, SeparatorProps>(
    (
        {
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <hr
                ref={ref}
                {...props}
                className={twMerge(
                    `
                        my-5
                    `,
                    className && className
                )}
            />
        );
    }
);

Separator.displayName = 'Separator';

export default Separator;