import React, { useEffect, useRef } from "react";

export type OutsideClickListenerProps = {
    children?: React.ReactNode;
    className?: string;
    onClickOutside?(event: React.MouseEvent): void
}

export default function OutsideClickListener(props: OutsideClickListenerProps) {
    const ref = useRef<HTMLDivElement>(null);
    UseOutsideClickListener(ref, props.onClickOutside);

    return (
        <div
            ref={ref}
            className={props.className}
        >
            {props.children}
        </div>
    );
}

function UseOutsideClickListener(
    ref: React.RefObject<HTMLDivElement | undefined>,
    onClickOutside
) {
    useEffect(() => {
        function onClick(event: MouseEvent) {

            // @ts-ignore
            if (!ref || !ref.current || ref.current.contains(event.target))
                return;

            onClickOutside(event);
        }

        document.addEventListener('mousedown', onClick);

        return () => document.removeEventListener('mousedown', onClick);
    });

    return [ref];
}