import * as API from '../utilities/Api';

export function GetKey() {
    return API.GET(`pushNotification/key`);
}

export function CreateSubscription(
    publicKey: string,
    endpoint: string,
    p256dh: string,
    secret: string
) {
    return API.POST(
        `pushNotification/subscription`,
        {
            body: JSON.stringify({
                publicKey,
                endpoint,
                p256dh,
                secret
            })
        }
    );
}