import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import * as Path from '../utilities/Path';
import * as SuggestionCategoryEndpoints from './SuggestionCategoryEndpoints';
import * as SuggestionCategoryActions from './SuggestionCategoryActions';
import Breadcrumbs from 'components/Breadcrumbs';
import Title from "components/Title";
import Button from "components/Button";
import DataTable, { TableColumn } from "components/DataTable";
import Box from "components/Box";
import { FaComment, FaPen, FaPlus, FaSearch, FaTrash } from "react-icons/fa";
import TextField from 'components/TextField';

export default function SuggestionCategoryListView(props: RouteComponentProps) {
    const { t } = useTranslation();

    const [rows, setRows] = useState(new Array<TableColumn<any>>());
    const [filterText, setFilterText] = useState<string>('');

    const filteredRows = useMemo(() => rows.filter(row => {
        return (row.name as string)
            .toLowerCase()
            .includes(filterText.toLowerCase());
    }), [filterText, rows]);

    const loadCategories = async () => {
        setRows(new Array());

        const { result } = await SuggestionCategoryEndpoints.All();
        if (!result?.success)
            return;

        const { data } = result;
        const rows = data.map((category: any) => ({
            id: category.suggestionCategoryID,
            name: category.name
        }));
        setRows(rows);
    };

    useEffect(() => {
        loadCategories();
    }, []);

    const onClickDelete = async (suggestionCategoryID: number) => {
        const deleted = await SuggestionCategoryActions.Delete(suggestionCategoryID);
        if (!deleted)
            return;

        await loadCategories();
    };

    const columns: TableColumn<any>[] = [
        {
            name: t('suggestionCategory.name'),
            selector: row => row.name,
            sortable: true
        },
        {
            cell: row => <>
                <Button
                    variant="link"
                    icon={FaPen}
                    onClick={() => props.history.push(Path.Resolve(props.match.url, row.id.toString()))}
                >
                    {t('edit')}
                </Button>
                <Button
                    variant="link"
                    icon={FaTrash}
                    onClick={() => onClickDelete(row.id)}
                >
                    {t('delete')}
                </Button>
            </>,
            right: true
        }
    ];

    const onChangeFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFilterText(value);
    };

    return (
        <div
            className="
                m-auto
                max-w-[800px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('suggestionCategory.categories')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-10
                    "
                >
                    <Title
                        icon={FaComment}
                    >
                        {t('suggestionCategory.categories')}
                    </Title>
                    <Button
                        icon={FaPlus}
                        onClick={() => props.history.push(Path.Resolve(props.match.url, 'new'))}
                    >
                        {t('suggestionCategory.newCategory')}
                    </Button>
                </div>
                <DataTable
                    columns={columns}
                    data={filteredRows}
                    subHeader
                    subHeaderComponent={
                        <TextField
                            icon={FaSearch}
                            placeholder={t('search')}
                            className="
                                w-72
                                pl-4
                                -ml-4
                                bg-white
                            "
                            onChange={onChangeFilterText}
                        />
                    }
                />
            </Box>
        </div>
    );
}