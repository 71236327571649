import * as API from '../utilities/Api';

export function All(
    userID: number
) {
    return API.GET(`userRole/${userID}/all`);
}

export function Put(
    userID: number,
    roleIDs: Array<number>

) {
    return API.PUT(
        `userRole/${userID}`,
        {
            body: JSON.stringify({
                roleIDs
            })
        }
    );
}