import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type BoxProps = React.HTMLAttributes<HTMLElement> & {
};

const Box = forwardRef<HTMLDivElement, BoxProps>(
    (
        {
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <div
                ref={ref}
                {...props}
                className={twMerge(
                    `
                        p-5 md:p-10
                        mb-5
                        bg-white
                    `,
                    className && className
                )}
            />
        );
    }
);

Box.displayName = 'Box';

export default Box;