import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import Suggestion from './Suggestion';
import * as SuggestionEndpoints from './SuggestionEndpoints';
import { useHistory } from 'react-router-dom';
import Title from 'components/Title';
import Separator from 'components/Separator';
import LabeledSelect from 'components/LabeledSelect';
import SelectOption from 'components/OldSelect/SelectOption';
import Button from 'components/Button';
import { FaMinus, FaPlus, FaSave } from 'react-icons/fa';
import { SuggestionStatus } from '../suggestion/SuggestionStatus';
import * as SuggestionCategoryEndpoints from '../suggestionCategory/SuggestionCategoryEndpoints';
import { globalContext } from 'app/GlobalContext';
import { Permission } from 'permission/Permission';
import { toast } from 'react-toastify';
import SuggestionVoteCount from './SuggestionVoteCount';
import LabeledToggle from 'components/LabeledToggle';
import Label from 'components/Label';
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';
import { v4 as uuidv4 } from 'uuid';
import LabeledTextField from 'components/LabeledTextField';
import LabeledTextArea from 'components/LabeledTextArea';

interface SuggestionDetailViewRouteParams {
    suggestionID?: string
}

export default function SuggestionDetailView(props: RouteComponentProps<SuggestionDetailViewRouteParams>) {
    const { globalState, dispatch } = useContext(globalContext);
    const history = useHistory();
    const { t } = useTranslation();
    const suggestionID = Number(props.match.params.suggestionID || '0');

    const [showDenyReason, setShowDenyReason] = useState<boolean>();
    const [suggestion, setSuggestion] = useState<any>();
    const [categories, setCategories] = useState(new Array());
    const [links, setLinks] = useState(new Array<any>());

    useEffect(() => {
        if (!suggestionID)
            return;

        SuggestionEndpoints.Get(suggestionID)
            .then(async response => {
                if (response.status != 200)
                    return;

                const suggestion = await response.json();
                setLinks(suggestion.links.map(link => ({ uuid: uuidv4(), ...link })));
                setSuggestion(suggestion);
            });

        if (!globalState.user.isAnonymous)
            SuggestionEndpoints.MarkAsRead(suggestionID)
                .then(async response => {
                    const { result } = response
                    if (!result?.success)
                        return;
                });

        SuggestionCategoryEndpoints.All()
            .then(async response => {
                const { result } = response
                if (!result?.success)
                    return;

                setCategories(result.data);
            });
    }, [suggestionID]);

    const onDeleted = () => history.push(`/suggestions/categories/${suggestion.category.suggestionCategoryID}`);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const status = Number(formData.get('status'));
        const suggestionCategoryID = Number(formData.get('category'));
        const showOnRoadmap = Boolean(formData.get('showOnRoadmap'));
        const title = formData.get('title') as string;
        const text = formData.get('text') as string;
        const denyReason = formData.get('denyReason') as string;
        const workOrder = formData.get('workOrder') as string;
        const newsUrl = formData.get('newsUrl') as string;
        const adminNote = formData.get('adminNote') as string;
        const _links = links.filter(link => link.link && link.link.trim()).map(link => ({ ...link, title: link.title?.trim() }));

        // TODO: move updating status to the suggestion itself
        const { result } = await SuggestionEndpoints.UpdateStatus({
            suggestionID,
            status,
            suggestionCategoryID,
            showOnRoadmap,
            links: _links,
            title,
            text,
            denyReason,
            workOrder,
            newsUrl,
            adminNote
        });
        
        if (!result?.success) {
            result?.message && toast.error(t(result.message));
            return;
        }

        setLinks([..._links]);
        setSuggestion({ ...suggestion, links: [] });

        toast.success(t('suggestion.updated'));
    };

    const onClickAddLink = () => {
        links.push({
            uuid: uuidv4()
        });
        setLinks([...links]);
    };

    const onClickRemoveLink = (uuid: string) => {
        setLinks([...links.filter(link => link.uuid != uuid)]);
    }

    const onChangeLinkTitle = (uuid: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const link = links.find(link => link.uuid == uuid);
        link.title = event.target.value;
        setLinks([...links]);
    };

    const onChangeLinkLink = (uuid: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const link = links.find(link => link.uuid == uuid);
        link.link = event.target.value;
        setLinks([...links]);
    }

    const onChangeStatus = (status: SuggestionStatus) => {
        var isDenied = status == SuggestionStatus.Denied;
        setShowDenyReason(isDenied);
    };
    
    return (
        <div
            className="max-w-[1100px] m-auto"
        >
            <Breadcrumbs>
                <Breadcrumbs.Item
                    to="/"
                >
                    {t('home.home')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                    to="/suggestions"
                >
                    {t('suggestion.suggestions')}
                </Breadcrumbs.Item>
                {suggestion?.category &&
                    <Breadcrumbs.Item
                        to={`/suggestions/categories/${suggestion?.category?.suggestionCategoryID}`}
                    >
                        {suggestion?.category?.name}
                    </Breadcrumbs.Item>
                }
                <Breadcrumbs.Item>
                    {suggestion?.title}
                </Breadcrumbs.Item>
            </Breadcrumbs>
            {globalState.user.permissions.includes(Permission.Suggestion_Write) && <SuggestionVoteCount />}
            <Box>
                {suggestion && (
                    <>
                        {globalState.user.permissions.includes(Permission.Suggestion_Review) && (
                            <>
                                <Title>
                                    {suggestion.title}
                                </Title>
                                {globalState.user.permissions.includes(Permission.Suggestion_Review) &&
                                    <div
                                        className="mb-5"
                                    >
                                        <div
                                            className="inline font-bold"
                                        >
                                            #{`${suggestion.suggestionID}`},&nbsp;
                                        </div>
                                        {t('suggestion.submittedBy')}&nbsp;
                                        <div
                                            className="inline font-bold"
                                        >
                                            {`${suggestion.suggestedBy.firstName} ${suggestion.suggestedBy.lastName}`}
                                        </div>
                                        &nbsp;{t('suggestion.from')}&nbsp;
                                        <div
                                            className="inline font-bold"
                                        >
                                            {`${suggestion.suggestedBy.clientName}`}
                                        </div>
                                    </div>
                                }
                                <form
                                    onSubmit={onSubmit}
                                >
                                    <LabeledSelect<SuggestionStatus>
                                        name="status"
                                        label={t('suggestion.status')}
                                        onChange={onChangeStatus}
                                    >
                                        {Object.keys(SuggestionStatus).filter(k => !isNaN(Number(k))).map(status => { 
                                            return <SelectOption<SuggestionStatus>
                                                key={status}
                                                value={Number(status)}
                                                selectedValue={suggestion.status}
                                            >
                                                {t(`suggestion.suggestionStatuses.${status}`)}
                                            </SelectOption>;
                                        })}
                                    </LabeledSelect>
                                    <LabeledSelect
                                        label={t('suggestionCategory.category')}
                                        name="category"
                                        required
                                    >
                                        {categories.map(category => (
                                            <SelectOption<number>
                                                key={category.suggestionCategoryID}
                                                value={category.suggestionCategoryID}
                                                selectedValue={suggestion.category?.suggestionCategoryID}
                                            >
                                                {category.name}
                                            </SelectOption>
                                        ))}
                                    </LabeledSelect>
                                    <LabeledTextField
                                        name="title"
                                        label={t('suggestion.title')}
                                        defaultValue={suggestion.title}
                                        required
                                    />
                                    <LabeledTextArea
                                        name="text"
                                        label={t('suggestion.description')}
                                        defaultValue={suggestion.text}
                                        required
                                        textAreaProps={{
                                            rows: 4
                                        }}
                                    />
                                    {globalState.user.permissions.includes(Permission.Suggestion_Review) &&
                                        <LabeledTextArea
                                            name="adminNote"
                                            label={t('suggestion.adminNote')}
                                            defaultValue={suggestion.adminNote}
                                            textAreaProps={{
                                                rows: 2
                                            }}
                                        />
                                    }
                                    <LabeledToggle
                                        label={t('suggestion.showOnRoadmap')}
                                        name="showOnRoadmap"
                                        defaultChecked={suggestion.showOnRoadmap}
                                    />
                                    {showDenyReason &&
                                        <LabeledTextArea
                                            label={t('suggestion.denyReason')}
                                            name="denyReason"
                                            textAreaProps={{
                                                className: 'h-[128px] resize-none',
                                            }}
                                            required
                                        />}
                                    <LabeledTextField
                                        name="workOrder"
                                        label={t('suggestion.workOrder')}
                                        defaultValue={suggestion.workOrder}
                                    />
                                    <LabeledTextField
                                        name="newsUrl"
                                        label={t('suggestion.newsUrl')}
                                        defaultValue={suggestion.newsUrl}
                                    />
                                    <div
                                        className="mb-5"
                                    >
                                        <Label>
                                            {t('suggestionLink.links')}
                                        </Label>
                                        <div>
                                            {links.map(link => (
                                                <div
                                                    key={link.uuid}
                                                    className="
                                                        flex gap-2 items-center
                                                        mb-4
                                                    "
                                                >
                                                    <div
                                                        className="flex flex-1 flex-col md:flex-row gap-x-2 gap-y-1"
                                                    >
                                                        <div
                                                            className="flex-[1]"
                                                        >
                                                            <TextField
                                                                value={link.title}
                                                                onChange={event => onChangeLinkTitle(link.uuid, event)}
                                                                placeholder={t('suggestionLink.title')}
                                                            />
                                                        </div>
                                                        <div
                                                            className="flex-[2]"
                                                        >
                                                            <TextField
                                                                value={link.link}
                                                                onChange={event => onChangeLinkLink(link.uuid, event)}
                                                                placeholder={t('suggestionLink.link')}
                                                                pattern="^https?://.+"
                                                            />
                                                        </div>
                                                    </div>
                                                    <IconButton
                                                        type="button"
                                                        icon={FaMinus}
                                                        onClick={() => onClickRemoveLink(link.uuid)}
                                                    />   
                                                </div>
                                            ))}
                                            <div
                                                className="
                                                    flex items-center justify-center
                                                    mt-2
                                                "
                                            >
                                                <IconButton
                                                    type="button"
                                                    icon={FaPlus}
                                                    onClick={onClickAddLink}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-end gap-2.5 2xl:gap-6"
                                    >
                                        <Button
                                            icon={FaSave}
                                            type="submit"
                                        >
                                            {t('save')}
                                        </Button>
                                    </div>
                                </form>
                                <Separator />
                            </>
                        )}
                        <Suggestion
                            key={suggestion.suggestionID}
                            suggestion={suggestion}
                            showComments
                            onDeleted={onDeleted}
                        />
                    </>
                )}
            </Box>
        </div>
    );
}