import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import SuggestionCategoryListView from './SuggestionCategoryListView';
import SuggestionCategoryDetailView from './SuggestionCategoryDetailView';

export default function SuggestionCategoryLayout(props: RouteComponentProps) {

    return (
        <Switch>
            <Route path={`${props.match.url}/new`} component={SuggestionCategoryDetailView} />
            <Route path={`${props.match.url}/:suggestionCategoryID`} component={SuggestionCategoryDetailView} />
            <Route path={`${props.match.url}`} component={SuggestionCategoryListView} />
        </Switch>
    );
}