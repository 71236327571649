import * as API from '../utilities/Api';

export function All() {
    return API.GET(`client/all`);
}

export function Get(clientID: number) {
    return API.GET(`client/${clientID}`);
}

export function Post(
    args: {
        name: string,
        tenantId: string
    }
) {
    return API.POST(
        `client`,
        {
            body: JSON.stringify(args)
        }
    );
}

export function Put(
    clientID: number,
    args: {
        name: string,
        tenantId: string
    }
) {
    return API.PUT(
        `client/${clientID}`,
        {
            body: JSON.stringify(args)
        }
    );
}