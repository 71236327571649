import { useContext, useEffect, useState } from 'react';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { toast } from 'react-toastify';
import { getCookie } from '../utilities/Cookies';
import { useInterval } from '../hooks/UseInterval';
import { globalContext } from 'app/GlobalContext';
import * as NotificationEndpoints from 'notification/NotificationEndpoints';
import { History } from '../utilities/History';
import { apiUrl } from 'utilities/Environment';
import { useTranslation } from 'react-i18next';

export default function NotificationHub() {
    const { globalState, dispatch } = useContext(globalContext);
    const { t } = useTranslation();
    
    const [unseenCount, setUnseenCount] = useState(0);
    const [hasFocus, setHasFocus] = useState(true);

    const onNotification = (data: any) => {
        dispatch({
            type: 'notifications.add',
            payload: data
        });
        setUnseenCount(unseenCount => !document.hasFocus() ? unseenCount + 1 : 0);

        toast.info(
            data.notification.data.message,
            { 
                autoClose: 1000 * 7,
                onClick: () => {
                    dispatch({
                        type: 'notifications.markAsRead',
                        payload: data.notificationReadingID
                    });
                    
                    const url = data?.notification?.data?.url;
                    if (url)
                        History.push(url);
                }
            }
        );

        const sound = new Audio('sounds/notification.mp3');
        sound.play();
    };

    useEffect(() => {
        dispatch({ type: 'notifications.clear' });

        NotificationEndpoints.All()
            .then(async ({ result }) => {
                if (!result?.success)
                    return;

                const { data } = result;
                if (!data.length)
                    return;

                dispatch({
                    type: 'notifications.add',
                    payload: data
                });
        });

        const token = getCookie('token');
        const options = {};
        token && Object.assign(options, { accessTokenFactory: () => token });

        const connection = new HubConnectionBuilder()
            .withUrl(`${apiUrl}/hub/notification`, options)
            .withAutomaticReconnect()
            .build();

        connection.on('notification', onNotification);

        connection.start()
            .catch(error => console.error(error));

        return () => { connection.stop(); };
    }, []);

    useEffect(() => {
        if (hasFocus)
            setUnseenCount(0);
    }, [hasFocus]);

    useInterval(() => {
            if (document.title == t('portal') && unseenCount > 0)
                document.title = `${unseenCount} ${t('notification.newNotifications')}`;
            else 
                document.title = t('portal');
        }, 
        !hasFocus ? 1000 * 1.5 : null,
        () => document.title = t('portal')
    );

    useInterval(() => setHasFocus(document.hasFocus()), 1000 * 0.1);

    //useInterval(() => onNotification('Un vote a ete soumis sur votre suggestion.'), 1000 * 4)
    
    return null;
};