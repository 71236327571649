import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number | null, onClear = function() {}) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current!();
        }

        if (delay !== null) {
            const interval = setInterval(tick, delay);
            return () => {
                clearInterval(interval);
                onClear();
            };
        }
    }, [delay]);
}