import * as API from '../utilities/Api';

export function Put(
    sendEmailToUponSubmission: Array<number>,
    activeVotesCapped: boolean,
    activeVotesCap: number,
    displayUnreadFields: boolean
) {
    return API.PUT(
        `suggestionApprovalSettings`,
        {
            body: JSON.stringify({
                sendEmailToUponSubmission,
                activeVotesCapped,
                activeVotesCap,
                displayUnreadFields
            })
        }
    );
}

export function Get() {
    return API.GET('suggestionApprovalSettings/get');
}

export function GetDisplaySetting() {
    return API.GET('suggestionApprovalSettings/getDisplaySetting');
}