import * as API from '../utilities/Api';
import { PublicationCategory } from './PublicationCategory';

export function All() {
    return API.GET(`publication/all`);
}

export function AllNotExpired() {
    return API.GET(`publication/allnotexpired`);
}

export function Get(publicationID: number) {
    return API.GET(`publication/${publicationID}`);
}

interface PublicationTranslation {
    title: string,
    htmlData: string,
    markdownData: string,
}

export function Post(
    args: {
        publicationDate: Date,
        expirationDate: Date,
        category: PublicationCategory,
        publicationTransations: PublicationTranslation[]
    }
) {
    return API.POST(
        `publication`,
        {
            body: JSON.stringify(args)
        }
    );
}

export function Put(
    publicationID: number,
    args: {
        publicationDate: Date,
        expirationDate: Date,
        publicationTransations: PublicationTranslation[]
    }
) {
    return API.PUT(
        `publication/${publicationID}`,
        {
            body: JSON.stringify(args)
        }
    );
}

export function Delete (publicationID: number) {
    return API.DELETE(`publication/${publicationID}`);
}