import React, { forwardRef } from "react";
import { twMerge } from 'tailwind-merge';
import { IconBaseProps } from "react-icons";

export type TitleProps = React.HTMLAttributes<HTMLHeadingElement> & {
    icon?: React.FC<IconBaseProps>;
};

const Title = forwardRef<HTMLHeadingElement, TitleProps>(
    (
        {
            icon,
            children,
            className,
            ...props
        },
        ref
    ): JSX.Element => {

        const Icon = icon;

        return (
            <h1
                ref={ref}
                className={twMerge(
                    `
                        flex items-center gap-x-2
                        font-bold text-lg
                        mb-5
                    `,
                    className && className
                )}
            >
                {
                    Icon &&
                    <Icon 
                        className="fill-[#4dc7f5]"
                    />
                }
                {children}
            </h1>
        );
    }
);

Title.displayName = 'Title';

export default Title;