import * as API from '../utilities/Api';

export function requestAccount(
    email: string
) {
    return API.POST(
        'requestAccount/request',
        {
            body: JSON.stringify({
                email
            })
        }
    );
}