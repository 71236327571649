import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import Button from 'components/Button';
import * as LoginEndpoints from './LoginEndpoints';
import { FaLock } from 'react-icons/fa';
import Title from 'components/Title';
import LabeledTextField from 'components/LabeledTextField';
import Box from 'components/Box';
import Link from 'components/Link';
import Error from 'components/Error';
import { History } from 'utilities/History';
import * as Path from '../utilities/Path';
import * as AuhtenticationEndpoints from '../authentication/AuthenticationEndpoints';

export default function LoginView(props: RouteComponentProps) {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => {
        AuhtenticationEndpoints.Get()
            .then(async response => {
                const { result } = response;

                const data = response.status != 401 ? result?.data : {
                    isAuthenticated: false,
                    isAccessToken: false
                };

                if (data.isAuthenticated && data.isAccessToken)
                    History.push('/');
            });
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(undefined);
        setDisabled(true);

        const formData = new FormData(event.currentTarget);
        const { result } = await LoginEndpoints.login(formData.get('username') as string, formData.get('password') as string);
        setDisabled(false);
        
        if (!result?.success) {
            setError(result?.message);
            return;
        }

        const { data } = result!;

        if (data.tfaRequestGuid) {
            History.push(Path.Resolve(props.match.url, `2fa?requestGuid=${data.tfaRequestGuid}&deliveryMethod=${data.tfaDeliveryMethod}&to=${data.tfaTo}`));
            return;
        }

        var date = new Date();
        date.setDate(date.getDate() + 6);
        document.cookie = `token=${data.token}; expires=${date.toUTCString()}; path=/`;
        
        History.push('/');
    };

    return (
        <>
            <Box
                className="md:bg-white/80"
            >
                <Title
                    icon={FaLock}
                >
                    {t('login.connection')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <LabeledTextField
                        name="username"
                        label={t('login.username')}
                        required
                        autoFocus
                    />
                    <LabeledTextField
                        name="password"
                        label={t('login.password')}
                        type="password"
                        required
                    />
                    <Error>
                        {t(error!)}
                    </Error>
                    <div 
                        className="flex justify-end"
                    >
                        <Button
                            icon={FaLock}
                            type="submit"
                            disabled={disabled}
                        >
                            {t('login.login')}
                        </Button>
                    </div>
                </form>
            </Box>
            <div
                className="text-center"
            >
                {t('login.noAccount')}&nbsp;&nbsp;
                <Link
                    to="/createAccount"
                >
                    <div className="inline font-bold underline">{t('login.register')}</div>
                </Link>
                <br />
                <Link
                    to="/password/forgot"
                >
                    {t('login.forgottenPassword')}
                </Link>
            </div>
        </>
    );
}