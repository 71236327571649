import useTranslation from '../utilities/TranslationOutsideComponent';
import * as SuggestionCategoryEndpoints from './SuggestionCategoryEndpoints';
import * as Dialog from 'components/Dialog';
import { toast } from 'react-toastify';

export async function Delete(roleID: number) {
    const t = await useTranslation();
    const yesNoResult = await Dialog.YesNo(t('suggestionCategory.message.yesNoDelete'));

    if (yesNoResult == 'no')
        return false;

    const { result: deleteResult } = await SuggestionCategoryEndpoints.Delete(roleID);
    if (!deleteResult?.success) {
        deleteResult?.message && toast.error(t(deleteResult?.message));
        return false;
    }

    toast.success(t('suggestionCategory.deleted'));
    return true;
}