import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import { FaPlus, FaSave, FaUser, FaUsers } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as ClientEndpoints from './ClientEndpoints';
import Breadcrumbs from 'components/Breadcrumbs';
import Title from 'components/Title';
import Box from 'components/Box';
import LabeledTextField from 'components/LabeledTextField';
import Button from 'components/Button';
import Error from 'components/Error';
import UserList from 'user/UserList';
import * as Path from '../utilities/Path';
import { History } from 'utilities/History';
import * as yup from 'yup';
import { ValidationResult } from 'utilities/Api';
import { convertToValidationResult } from 'utilities/Yup';

interface ClientDetailViewRouteParams {
    clientID?: string
}

export default function ClientDetailView(props: RouteComponentProps<ClientDetailViewRouteParams>) {
    const { t } = useTranslation();
    const clientID = Number(props.match.params.clientID || '0');

    const [client, setClient] = useState<any>();
    const [nameError, setNameError] = useState<string>();
    const [validationResult, setValidationResult] = useState<ValidationResult>();

    useEffect(() => {
        if (!clientID)
            return;

        ClientEndpoints.Get(clientID)
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;
                    
                setClient(result.data);
            });
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValidationResult(undefined);

        const schema = yup.object().shape({
            name: yup.string().trim().required().label(t('client.name')),
            tenantId: yup.string().trim().required().label(t('client.tenant'))
        });

        var data;
        try {
            const formData = new FormData(event.currentTarget);
            data = await schema.validate({
                name: formData.get('name'),
                tenantId: formData.get('tenantId')
            }, {
                abortEarly: false
            });
        } catch (error) {
            const validationResult = convertToValidationResult(error as yup.ValidationError);
            setValidationResult(validationResult);
            return;
        }

        if (!clientID) {
            // new client

            let { result } = await ClientEndpoints.Post(data);

            if (!result?.success) {
                setValidationResult(result?.validationResult);
                return;
            }

            toast.success(t('client.added'));
        } else {
            // existing client

            let { result } = await ClientEndpoints.Put(clientID, data);

            if (!result?.success) {
                setValidationResult(result?.validationResult);
                return;
            }

            toast.success(t('client.updated'));
        }

        History.push('/clients');
    }
    
    return (
        <div
            className="
                m-auto
                max-w-[1200px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="/clients">{t('client.clients')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{!clientID ? t('client.newClient') : client?.name}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title
                    icon={FaUsers}
                >
                    {t('client.client')} - {client?.name || t('client.newClient')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <LabeledTextField 
                        label={t('client.name')}
                        name="name"
                        autoFocus={!clientID}
                        required
                        defaultValue={client?.name}
                    />
                    <LabeledTextField
                        label={t('client.tenant')}
                        name="tenantId"
                        required
                        defaultValue={client?.tenantId}
                    />
                    <Error>
                        {t(nameError!)}
                    </Error>
                    <div
                        className="flex justify-end"
                    >
                        <Button
                            type="submit"
                            icon={FaSave}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </form>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mt-10 mb-10
                    "
                >
                    <Title
                        icon={FaUser}
                    >
                        {t('user.users')}
                    </Title>
                    <Button
                        icon={FaPlus}
                        onClick={() => History.push(Path.Resolve(props.match.url, 'users', 'new'))}
                        disabled={!clientID}
                    >
                        {t('user.newUser')}
                    </Button>
                </div>
                <UserList
                    clientID={clientID}
                />
            </Box>
        </div>
    );
}