import useTranslation from '../utilities/TranslationOutsideComponent';
import * as PublicationEndpoints from './PublicationEndpoints';
import * as Dialog from 'components/Dialog';
import { toast } from 'react-toastify';

export async function Delete(publicationID: number) {
    const t = await useTranslation();
    const yesNoResult = await Dialog.YesNo(t('publication.message.yesNoDelete'));

    if (yesNoResult == 'no')
        return;

    const { result: deleteResult } = await PublicationEndpoints.Delete(publicationID);
    if (!deleteResult?.success) {
        deleteResult?.message && toast.error(t(deleteResult?.message));
        return false;
    }

    toast.success(t('publication.deleted'));
    return true;
}