import * as API from '../utilities/Api';

export function Get() {
    return API.GET('account');
}

export function Put(args: {
    email: string,
    phoneNumber?: string,
    language: number,
    showIdentity: boolean,
    tfaDeliveryMethod: number,
    emailNotificationsEnabled: boolean,
    pushNotificationsEnabled: boolean,
    currentPassword?: string,
    newPassword?: string,
    newPasswordConfirmation?: string
}) {
    return API.PUT(
        `account`,
        {
            body: JSON.stringify(args)
        }
    );
}