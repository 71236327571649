export function Resolve(...args: (string | number)[]) {
    return args.reduce(
        (previous, current) => {
            if (typeof current === 'number')
                current = current.toString();

            if (current.endsWith('/'))
                current = current.substr(0, current.length);

            if (previous == '')
                return current;

            return `${previous}/${current}`;
        },
        ''
    ) as string;
}