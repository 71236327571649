import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { IconBaseProps } from 'react-icons';
import { FaIdCardAlt, FaUsers, FaFolderOpen, FaBars, FaLockOpen, FaBell, FaUser, FaComment, FaHome, FaMegaport, FaBullhorn, FaPaperPlane } from 'react-icons/fa';
import { useTranslation, withTranslation } from "react-i18next";
import { twMerge } from 'tailwind-merge';
import { deleteCookie, getCookie } from '../utilities/Cookies';
import Link from 'components/Link';
import SuggestionCategoryLayout from '../suggestionCategory/SuggestionCategoryLayout';
import UserLayout from '../user/UserLayout';
import SuggestionLayout from '../suggestion/SuggestionLayout';
import * as PushNotification from '../PushNotification/PushNotification';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import RoleLayout from '../role/RoleLayout';
import ClientLayout from '../client/ClientLayout';
import Badge from 'components/Badge';
import { globalContext } from './GlobalContext';
import DevView from '__dev/DevView';
import NotificationListView from 'notification/NotificationListView';
import OutsideClickListener from 'components/OutsideClickListener';
import AccountView from 'account/AccountView';
import * as AccountEndpoints from '../account/AccountEndpoints';
import SuggestionUnderReviewListView from 'suggestion/SuggestionUnderReviewListView';
import AuthorizedRoute from '../permission/AuthorizedRoute';
import { Permission } from 'permission/Permission';
import RoadmapView from 'roadMap/RoadmapView';
import NotificationHub from 'notification/NotificationHub';
import { EnvironmentDisplayName, environmentImageUrlFr, environmentImageUrlEn } from 'utilities/Environment';
import SuggestionApprovalSettingsView from 'suggestion/SuggestionApprovalSettingsView';
import * as SuggestionApprovalSettingsEndpoints from '../suggestionApprovalSettings/SuggestionApprovalSettingsEndpoints';
import PublicationLayout from 'publication/PublicationLayout';
import * as AuhtenticationEndpoints from '../authentication/AuthenticationEndpoints';

function AppLayout(props: RouteComponentProps) {
    const { globalState, dispatch } = useContext(globalContext);
    const history = useHistory();
    const { t } = useTranslation();

    const [showDrawer, _setShowDrawer] = useState(false);
    const showDrawerRef = useRef(showDrawer); // a ref is needed to access in an event listener
    const setShowDrawer = showDrawer => {
        showDrawerRef.current = showDrawer;
        _setShowDrawer(showDrawer);
    };
    const [showNotificationPopup, setShowNotificationPopup] = useState(false);

    const applyBodyOverflow = () => {
        if (!document.getElementById('drawer'))
            return;

        const isMobile = window.innerWidth < 992;
        const hideScrollbar = isMobile && showDrawerRef.current;

        document.body.style.overflow = hideScrollbar ? 'hidden': 'auto';
        hideScrollbar && window.scrollTo({ top: 0 })

        const drawerStyle = document.getElementById('drawer')!.style;
        if (!isMobile)
            drawerStyle.right = 'auto';
        else
            drawerStyle.right = hideScrollbar ? 'auto' : '100%';
    }

    const switchLanguage = async () => {
        if (globalState.user.language == 2) {
            history.push('/publication/showall');
        }
        dispatch({
            type: 'user.setLanguage',
            payload: globalState.user.language == 1 ? 2 : 1
        });
    }

    useEffect(() => {
        AuhtenticationEndpoints.Get()
            .then(async response => {
                const { result } = response;

                const data = response.status != 401 ? result?.data : {
                    isAuthenticated: false,
                    isAccessToken: false
                };

                dispatch({
                    type: 'isAuthenticated.set',
                    payload: {
                        isAuthenticated: data.isAuthenticated,
                        isAccessToken: data.isAccessToken
                    }
                });
            });
    }, []);

    useEffect(() => {
        if (!globalState.isAuthenticated)
            return;

        PushNotification.register();

        AccountEndpoints.Get()
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                const { data } = result;
                dispatch({
                    type: 'user.set',
                    payload: {
                        firstName: data.firstName,
                        language: data.language,
                        permissions: data.permissions,
                        isAnonymous: data.isAnonymous
                    }
                });
            });

        SuggestionApprovalSettingsEndpoints.GetDisplaySetting()
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                const { data } = result;
                dispatch({
                    type: 'suggestion.setDisplayUnreadFields',
                    payload: {
                        displayUnreadFields: data.displayUnreadFields
                    }
                });
            });

        window.addEventListener('resize', applyBodyOverflow);
        return () => window.removeEventListener('resize', applyBodyOverflow);
    }, [globalState.isAuthenticated]);

    useEffect(applyBodyOverflow, [showDrawer]);

    const toggleDrawer = () => setShowDrawer(!showDrawer);

    const DrawerItem = (p: {
        to: string;
        permissions?: Array<Permission> | Permission
        icon: React.FC<IconBaseProps>,
        children?: React.ReactNode
    }) => {
        const history = useHistory();

        const permissions = typeof p.permissions == 'string' ? [p.permissions] : p.permissions;

        if (permissions && permissions.findIndex(p => globalState.user.permissions.includes(p)) == -1)
            return null;

        const Icon = p.icon;

        const onClick = (event) => {
            event.preventDefault();
            history.push(p.to);
            setShowDrawer(false);
        };

        return (
            <a
                className="
                    flex items-center gap-4
                    py-0.5
                    leading-[3rem]
                    font-bold

                    border-b-4 border-[#eef5fc]
                    first:border-t-4
                    last:border-b-0

                    transition-colors duration-300
                    hover:text-[#4dc7f5]
                "
                href={p.to}
                onClick={onClick}
            >
                <Icon
                    className="
                        w-5 h-5
                        ml-2
                        fill-[#4dc7f5] text-[#4dc7f5]
                    "
                />
                {p.children}
            </a>
        );
    };

    const onClickLogout = () => {
        deleteCookie('token');
        history.push('/login');
    };

    const onClickNotification = () => {
        setShowNotificationPopup(true);
    };

    if (!globalState.isAuthenticated)
        return null;

    return (
        <>
            <NotificationHub />
            <header
                className="
                        bg-white

                        transition-[width] duration-500

                        md:fixed
                        md:w-[270px] md:h-[100vh]
                    "
            >
                <div
                    className="
                            flex items-center justify-between md:justify-center
                            pr-8 md:pr-0
                        "
                >
                    <img
                        src={globalState.user.language == 2 ? environmentImageUrlFr : environmentImageUrlEn}
                        className="max-h-[121px]"
                    />
                    <Button
                        icon={FaBars}
                        className="!px-2 md:hidden"
                        iconClassName="w-8 h-8"
                        onClick={toggleDrawer}
                    />
                </div>
                <div
                    className={twMerge(
                        `
                                w-full h-[calc(100vh_-_120.98px)]
                                absolute
                                z-50
                                bg-black/20
                            `,
                        !showDrawer && 'bg-black/0',
                        'transition-colors duration-400'
                    )}
                    id="drawer"
                >
                    <div
                        className={twMerge(
                            `
                                    relative
                                    flex justify-between flex-col
                                    bg-white
                                    w-[90%] h-full
                                    right-[0%]
                                `,
                            !showDrawer && 'right-[100%]',
                            `
                                    transition-[right] duration-300

                                    md:w-full md:right-[0%]
                                `
                        )}
                    >
                        <nav>
                            <div
                                className="px-8 py-5"
                            >
                                {globalState.user.language == 2 ?
                                    <>
                                        <DrawerItem
                                            to={props.match.url}
                                            icon={FaHome}
                                        >
                                            {t('home.home')}
                                        </DrawerItem>
                                    </>
                                    : null
                                }
                                <DrawerItem
                                    to={`${props.match.url}roles`}
                                    permissions={Permission.Role_Manage}
                                    icon={FaIdCardAlt}
                                >
                                    {t('role.roles')}
                                </DrawerItem>
                                <DrawerItem
                                    to={`${props.match.url}clients`}
                                    permissions={Permission.Client_Manage}
                                    icon={FaUsers}
                                >
                                    {t('client.clients')}
                                </DrawerItem>
                                <DrawerItem
                                    to={`${props.match.url}users`}
                                    permissions={Permission.User_Manage}
                                    icon={FaUser}
                                >
                                    {t('user.users')}
                                </DrawerItem>
                                {/* On cache temporairement les suggestions pour les utilisateurs en anglais */}
                                {globalState.user.language == 2 ?
                                    <>
                                        <DrawerItem
                                            to={`${props.match.url}suggestionCategories`}
                                            permissions={Permission.SuggestionCategory_Manage}
                                            icon={FaFolderOpen}
                                        >
                                            {t('suggestionCategory.categories')}
                                        </DrawerItem>
                                        <DrawerItem
                                            to={`${props.match.url}suggestions`}
                                            permissions={[
                                                Permission.Suggestion_Write,
                                                Permission.Suggestion_Read,
                                                Permission.Suggestion_ProjectManager,
                                            ]}
                                            icon={FaComment}
                                        >
                                            {t('suggestion.suggestions')}
                                        </DrawerItem>
                                        <DrawerItem
                                            to={`${props.match.url}suggestionsUnderReview`}
                                            permissions={Permission.Suggestion_Review}
                                            icon={FaComment}
                                        >
                                            {t('suggestion.suggestionStatuses.0')}
                                        </DrawerItem>
                                    </>
                                    : null}
                                <DrawerItem
                                    to={`${props.match.url}publication`}
                                    permissions={Permission.Publication_Manage}
                                    icon={FaPaperPlane}
                                >
                                    {t('publication.publications')}
                                </DrawerItem>
                                <DrawerItem
                                    to={`${props.match.url}publication/showall`}
                                    icon={FaPaperPlane}
                                >
                                    {t('publication.communications')}
                                </DrawerItem>
                                {!globalState.user.isAnonymous ?
                                    <>
                                        <DrawerItem
                                            icon={FaBell}
                                            to={`${props.match.url}notifications`}
                                        >
                                            {t('notification.notifications')}
                                        </DrawerItem>
                                    </>
                                    : null
                                }
                            </div>
                        </nav>
                        <div
                            className="px-8 pb-5"
                        >
                            {globalState.isAccessToken ? null : <Button
                                icon={FaLockOpen}
                                className="w-full"
                                onClick={onClickLogout}
                            >
                                {t('login.logout')}
                            </Button>}
                        </div>
                    </div>
                </div>
            </header>
            <div
                className="
                        min-h-screen
                        flex flex-col
                        md:ml-[270px]

                        transition-[margin] duration-500
                    "
            >
                <section
                    className="
                            pt-3.5 px-7 pb-3
                            bg-[#3d6a86] text-white
                            flex justify-between items-center flex-wrap

                            lg:px-12
                        "
                >
                    <p
                        className="
                                flex gap-4
                                font-bold text-lg
                                mb-4

                                lg:mb-0
                            "
                    >
                        {t('portal')}
                        <EnvironmentDisplayName />
                    </p>
                    <div
                        className="
                                w-full
                                ml-auto mr-8
                                grid gap-x-2.5 grid-cols-[1fr_44px]
                                items-center text-left

                                lg:h-14 lg:w-auto
                                lg:grid-cols-[1fr_150px_60px_60px]
                                lg:text-center
                            "
                    >
                        <p
                            className="order-1"
                        >
                            {t('hi')} {globalState.user?.firstName}!
                        </p>
                        {globalState.isAccessToken ? null : (
                            <>
                                <div
                                    className="order-3 lg:order-2"
                                >
                                    <Link
                                        to="/account"
                                        className="font-bold"
                                    >
                                        {t('account.myAccount')}
                                    </Link>
                                </div>
                            </>
                        )}
                        <div
                            className="
                                    order-2 lg:order-3
                                    md:relative
                                "
                        >
                            <IconButton
                                icon={FaBell}
                                iconClassName="
                                        fill-white text-white
                                        w-6 h-6
                                        z-10
                                    "
                                onClick={onClickNotification}
                            >
                                {!!globalState.notifications.filter(x => !x.isRead).length &&
                                    <Badge
                                        className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2"
                                        content={globalState.notifications.filter(x => !x.isRead).length}
                                        max={9}
                                    />
                                }
                            </IconButton>
                            {showNotificationPopup &&
                                <OutsideClickListener
                                    onClickOutside={() => setShowNotificationPopup(false)}
                                >
                                    <NotificationListView
                                        isPopup
                                    />
                                </OutsideClickListener>
                            }
                        </div>
                    </div>
                    <Link
                        to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            switchLanguage();
                        }}
                        className="font-bold"
                    >
                        {t('switchLanguage')}
                    </Link>
                </section>
                <main
                    className="
                            flex-1
                            lg:bg-login lg:bg-[length:55%_auto] lg:bg-right-top lg:bg-no-repeat
                        "
                >
                    <div
                        className="
                                px-0 md:px-7 lg:px-12
                            "
                    >
                        <Switch>
                            <Route path={`${props.match.url}__dev`} component={DevView} />

                            <AuthorizedRoute
                                path={`${props.match.url}roles`}
                                permissions={Permission.Role_Manage}
                                component={RoleLayout}
                            />
                            <AuthorizedRoute
                                path={`${props.match.url}clients`}
                                permissions={Permission.Client_Manage}
                                component={ClientLayout}
                            />
                            <AuthorizedRoute
                                path={`${props.match.url}users`}
                                permissions={Permission.User_Manage}
                                component={UserLayout}
                            />
                            <AuthorizedRoute
                                path={`${props.match.url}suggestionCategories`}
                                permissions={Permission.SuggestionCategory_Manage}
                                component={SuggestionCategoryLayout}
                            />
                            <AuthorizedRoute
                                path={`${props.match.url}suggestions`}
                                permissions={[
                                    Permission.Suggestion_Write,
                                    Permission.Suggestion_Read,
                                    Permission.Suggestion_ProjectManager,
                                ]}
                                component={SuggestionLayout}
                            />
                            <AuthorizedRoute
                                path={`${props.match.url}suggestionsUnderReview`}
                                permissions={Permission.Suggestion_Review}
                                component={SuggestionUnderReviewListView}
                            />
                            <AuthorizedRoute
                                path={`${props.match.url}suggestiontApprovalSettings`}
                                permissions={Permission.Suggestion_Review}
                                component={SuggestionApprovalSettingsView}
                            />
                            <Route
                                path={`${props.match.url}publication`}
                                component={PublicationLayout}
                            />
                            <Route
                                path={`${props.match.url}notifications`}
                                component={NotificationListView}
                            />
                            <Route
                                path={`${props.match.url}account`}
                                component={AccountView}
                            />
                            <Route
                                path={props.match.url}
                                component={RoadmapView}
                            />
                        </Switch>
                    </div>
                </main>
            </div>
        </>
    );
}
export default withTranslation()(AppLayout);