import React from "react";
import ReactDOM from "react-dom";
import Button from "components/Button";
import Modal from "components/Modal";
import useTranslation from "utilities/TranslationOutsideComponent";

export type YesNoDialogResult = 'yes' | 'no';

export default async function YesNoDialog(
    content: JSX.Element,
    onClickYes: (event: React.MouseEvent) => void = () => {},
    onClickNo: (event: React.MouseEvent) => void = () => {}
) {
    const t = await useTranslation();

    var resolve;
    const promise = new Promise<YesNoDialogResult>(r => resolve = r);

    const clickYes = async (event: React.MouseEvent) => {
        await onClickYes(event);
        resolve('yes');
    };

    const clickNo = async (event: React.MouseEvent) => {
        await onClickNo(event);
        resolve('no');
    }

    const container = document.getElementById('root-dialog')!;
    ReactDOM.render(
        <Modal
            isOpen
            boxClassName="flex flex-col max-w-[550px]"
        >
            <div>
                {content}
            </div>
            <div
                className="
                    flex flex-row 
                    items-center justify-end
                    gap-8
                    mt-8
                "
            >
                <Button
                    onClick={clickYes}
                    className="min-w-[70px]"
                >
                    {t('yes')}
                </Button>
                <Button
                    onClick={clickNo}
                    className="min-w-[70px]"
                >
                    {t('no')}
                </Button>
            </div>
        </Modal>,
        container
    );

    const response = await promise;
    ReactDOM.unmountComponentAtNode(container);
    return response;
}