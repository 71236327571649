import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type ToggleProps = React.InputHTMLAttributes<HTMLInputElement> & {
};

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    (
        {
            className,
            ...props
        },
        ref
    ): JSX.Element => {
        return (
            <label 
                className={twMerge(
                    `
                        inline-flex items-center
                        relative
                        group
                    `,
                    !props.disabled && 'cursor-pointer'
                )}
            >
                <input
                    type="checkbox"
                    className="sr-only peer"
                    ref={ref}
                    {...props}
                />
                <div 
                    className="
                        w-[46px] h-[26px] 
                        bg-[#eef5fc] group-hover:bg-[#d6dde3]
                        border border-[#eef5fc] rounded-full

                        transition-colors duration-300
                        
                        peer

                        peer-focus:outline-none peer-focus:border-[#3d6a86]

                        peer-checked:bg-[#3d6a86] group-hover:peer-checked:bg-[#4dc7f5]
                        peer-checked:after:translate-x-full peer-checked:after:border-white

                        peer-disabled:bg-[#BCC3CA] group-hover:peer-disabled:bg-[#BCC3CA]

                        after:h-5 after:w-5
                        after:content-[''] 
                        after:absolute after:top-[3px] after:left-[3px] 
                        after:bg-white 
                        after:border-gray-300 after:border after:rounded-full 
                        after:transition-all
                    "
                >
                </div>
            </label>
        );
    }
);

Toggle.displayName = 'Toggle';

export default Toggle;