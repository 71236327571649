import * as API from '../utilities/Api';

export function requestAccount(
    email: string
) {
    return API.POST(
        'password/accountRequest',
        {
            body: JSON.stringify({
                email
            })
        }
    );
}

export function resetRequest(
    email: string
) {
    return API.POST(
        'password/resetRequest',
        {
            body: JSON.stringify({
                email
            })
        }   
    );
}

export function reset(
    token: string,
    password: string,
    passwordConfirmation: string
) {
    return API.PUT(
        'password',
        {
            body: JSON.stringify({
                token,
                password,
                passwordConfirmation
            })
        }
    );
}