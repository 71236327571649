import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FaSave, FaUser } from 'react-icons/fa';
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import LabeledTextField from 'components/LabeledTextField';
import LabeledToggle from 'components/LabeledToggle';
import Separator from 'components/Separator';
import Title from 'components/Title';
import * as AccountEndpoints from './AccountEndpoints';
import { toast } from 'react-toastify';
import LabeledSelect from 'components/LabeledSelect';
import SelectOption from 'components/OldSelect/SelectOption';
import { globalContext } from 'app/GlobalContext';
import ValidationError from 'components/ValidationError';
import { ValidationResult } from 'utilities/Api';

interface AccountViewRouteParams {
}

export default function AccountView(props: RouteComponentProps<AccountViewRouteParams>) {
    const { dispatch } = useContext(globalContext);
    const { t } = useTranslation();

    const [account, setAccount] = useState<any>({});
    const [validationResult, setValidationResult] = useState<ValidationResult>();

    useEffect(() => {
        AccountEndpoints.Get()
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;

                const { data } = result;
                setAccount(data);
            });
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValidationResult(undefined);

        const formData = new FormData(event.currentTarget);
        const language = Number(formData.get('language') == null ? account.language : formData.get('language'));

        const { result } = await AccountEndpoints.Put({
            email: formData.get('email') as string,
            phoneNumber: formData.get('phoneNumber') as string,
            language,
            showIdentity: Boolean(formData.get('showIdentity')),
            tfaDeliveryMethod: Number(formData.get('tfaDeliveryMethod')),
            emailNotificationsEnabled: Boolean(formData.get('emailNotificationsEnabled')),
            pushNotificationsEnabled: Boolean(formData.get('pushNotificationsEnabled')),
            currentPassword: formData.get('currentPassword') as string,
            newPassword: formData.get('newPassword') as string,
            newPasswordConfirmation: formData.get('newPasswordConfirmation') as string
        });

        if (!result?.success) {
            setValidationResult(result?.validationResult);
            return;
        }

        dispatch({
            type: 'user.setLanguage', 
            payload: language
        });

        toast.success(
            t(
                'account.saved',
                { lng: language == 0 ? 'en' : 'fr' }
            )
        );
    };

    return (
        <div
            className="
                m-auto
                max-w-[800px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('account.myAccount')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <Title
                    icon={FaUser}
                >
                    {t('account.myAccount')}
                </Title>
                <form
                    onSubmit={onSubmit}
                >
                    <Title>
                        {t('account.info')}
                    </Title>
                    <LabeledTextField 
                        name="email"
                        label={t('account.emailAddress')}
                        type="email"
                        required
                        defaultValue={account.email}
                    />
                    <ValidationError
                        name="email"
                        result={validationResult}
                    />
                    <LabeledTextField 
                        name="phoneNumber"
                        label={t('account.phoneNumber')}
                        type="tel"
                        pattern="[0-9]{10,11}"
                        defaultValue={account.phoneNumber}
                    />
                    <ValidationError
                        name="phoneNumber"
                        result={validationResult}
                    />
                    <LabeledSelect<number>
                        name="language"
                        label={t('language')}
                        required
                        disabled={true}
                    >
                        <SelectOption<number>
                            value={1}
                            selectedValue={account.language}
                        >
                            {t('languages.en')}
                        </SelectOption>
                        <SelectOption<number>
                            value={2}
                            selectedValue={account.language}
                        >
                            {t('languages.fr')}
                        </SelectOption>
                    </LabeledSelect>
                    <LabeledToggle
                        name="showIdentity"
                        label={t('account.showIdentity')}
                        defaultChecked={account.showIdentity}
                        className="mt-10"
                    />
                    <Separator />
                    <Title>
                        {t('account.tfa')}
                    </Title>
                    <LabeledSelect<number>
                        name="tfaDeliveryMethod"
                        label={t('account.tfaDeliveryMethod')}
                        required
                    >
                        <SelectOption<number>
                            value={0}
                            selectedValue={account.tfaDeliveryMethod}
                        >
                            {t('tfaDeliveryMethod.email')}
                        </SelectOption>
                        <SelectOption<number>
                            value={1}
                            selectedValue={account.tfaDeliveryMethod}
                        >
                            {t('tfaDeliveryMethod.sms')}
                        </SelectOption>
                    </LabeledSelect>
                    <Separator />
                    <Title>
                        {t('account.notifications')}
                    </Title>
                    <LabeledToggle 
                        name="emailNotificationsEnabled"
                        label={t('account.receiveNotificationsByEmail')}
                        defaultChecked={account.emailNotificationsEnabled}
                    />
                    <LabeledToggle 
                        name="pushNotificationsEnabled"
                        label={t('account.receiveNotificationsByPush')}
                        defaultChecked={account.pushNotificationsEnabled}
                    />
                    <Separator />
                    <Title>
                        {t('account.passwordChange')}
                    </Title>
                    <LabeledTextField 
                        name="currentPassword"
                        label={t('account.currentPassword')}
                        type="password"
                    />
                    <ValidationError
                        name="currentPassword"
                        result={validationResult}
                    />
                    <LabeledTextField 
                        name="newPassword"
                        label={t('password.newPassword')}
                        type="password"
                    />
                    <LabeledTextField 
                        name="newPasswordConfirmation"
                        label={t('password.newPasswordConfirmation')}
                        type="password"
                    />
                    <ValidationError
                        name="newPasswordConfirmation"
                        result={validationResult}
                    />
                    <div
                        className="flex justify-end"
                    >
                        <Button
                            type="submit"
                            icon={FaSave}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </form>
            </Box>
        </div>
    );
}