import clsx from "clsx";
import React, { forwardRef, ReactElement } from "react";
import { twMerge } from "tailwind-merge";
import SelectOption, { SelectOptionProps } from "./SelectOption";

export type SelectProps<T> = Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'defaultValue' | 'value' | 'onChange'
> & {
    children?: ReactElement<SelectOptionProps<T>> | ReactElement<SelectOptionProps<T>>[];
    defaultValue?: T;
    value?: T;
    onChange?(value: T): void;
};

const Select = <T extends string | number | undefined>(
    {
        children,
        className,
        onChange: change = () => {},
        ...props
    }: SelectProps<T>,
    ref: React.ForwardedRef<HTMLSelectElement>
  ): JSX.Element => {

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        change(event.currentTarget.value as T);
    };

    return (
        <select
            ref={ref}
            {...props}
            onChange={onChange}
            className={clsx(
                'bg-select bg-[right_0.75rem_center] bg-[length:16px_12px]',
                twMerge(
                    `
                        px-2
                        w-full
                        appearance-none
                        bg-[#eef5fc] bg-no-repeat
                        h-[42px]
                    `,
                    className
                )
            )}
        >
            {children}
        </select>
    );
};

export default Object.assign(
    forwardRef(Select) as 
        <T>(
            props: SelectProps<T> & {
                ref?: React.ForwardedRef<HTMLSelectElement>
            }
        ) => ReturnType<typeof Select>,
    {
        Option: SelectOption,
        displayName: 'Select'
    }
);