import * as API from '../utilities/Api';

export function All() {
    return API.GET(`notificationReading/all`);
}

export function MarkAsRead(
    notificationReadingId: number
) {
    return API.POST(
        `notificationReading/markAsRead/${notificationReadingId}`
    );
}

export function MarkAllAsRead() {
    return API.POST(
        `notificationReading/markAllAsRead`
    );
}