import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import SuggestionCategoriesView from './SuggestionCategoriesView';
import SuggestionCategoryListView from '../suggestionCategory/SuggestionCategoryListView';
import SuggestionListView from './SuggestionListView';
import SuggestionDetailView from './SuggestionDetailView';
import MySuggestionListView from './MySuggestionListView';

export default function SuggestionLayout(props: RouteComponentProps) {

    return (
        <Switch>
            <Route path={`${props.match.url}/mySuggestions`} component={MySuggestionListView} />
            <Route path={`${props.match.url}/suggestionCategories`} component={SuggestionCategoryListView} />
            <Route path={`${props.match.url}/categories/:suggestionCategoryID/new`} component={SuggestionDetailView} />
            <Route 
                path={`${props.match.url}/categories/:suggestionCategoryID`} 
                render={props =>
                    <SuggestionListView
                        key={props.match.params.suggestionCategoryID}
                        {...props}
                    />
                }
            />
            <Route
                path={`${props.match.url}/all`}
                render={props =>
                    <SuggestionListView
                        key={'all'}
                        {...props}
                    />
                }
            />
            <Route path={`${props.match.url}/:suggestionID`} component={SuggestionDetailView} />
            <Route path={`${props.match.url}`} component={SuggestionCategoriesView} />
        </Switch>
    );
}