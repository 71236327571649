import * as API from '../utilities/Api';

export function Vote(
    suggestionID: number
) {
    return API.POST(
        `suggestionVote`,
        {
            body: JSON.stringify({
                suggestionID
            })
        }
    );
}

export function Delete(
    suggestionID: number
) {
    return API.DELETE(`suggestionVote`,
    {
        body: JSON.stringify({
            suggestionID
        })
    });
}

export function GetActiveVoteCount() {
    return API.GET(`suggestionVote/activeVoteCount`);
}