import { useTranslation } from 'react-i18next';
import { FaComment, FaPlus } from "react-icons/fa";
import { useContext, useState } from "react";
import { toast } from 'react-toastify';
import Button from "components/Button";
import Error from "components/Error";
import LabeledTextArea from "components/LabeledTextArea";
import LabeledTextField from "components/LabeledTextField";
import TitledModal from "components/TitledModal";
import * as SuggestionEndpoints from './SuggestionEndpoints';
import { globalContext } from 'app/GlobalContext';
import { Permission } from 'permission/Permission';

interface SubmitSuggestionModalProps {
    onSubmit?(): void;
}

const SubmitSuggestionModal = (props: SubmitSuggestionModalProps) => {
    const params = new URLSearchParams(window.location.search);

    const { globalState } = useContext(globalContext);

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(!!params.get('submitSuggestion'));
    const [titleError, setTitleError] = useState<string>();

    const onSubmitSuggestion = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setTitleError(undefined);

        const formData = new FormData(event.currentTarget);
        const { result } = await SuggestionEndpoints.Post(
            formData.get('title') as string,
            formData.get('text') as string
        );

        if (!result?.success) {
            setTitleError(t(result?.message!));
            return;
        }

        setShowModal(false);
        
        toast.success(t('suggestion.suggestionSubmitted'));

        props.onSubmit && props.onSubmit();
    };

    if (globalState.user.permissions.includes(Permission.Suggestion_Write)) {
        return (
            <>
                <Button
                    icon={FaPlus}
                    onClick={() => setShowModal(true)}
                >
                    {t('suggestion.submitSuggestion')}
                </Button>
                <TitledModal
                    title={t('suggestion.submitSuggestionDev')}
                    titleIcon={FaComment}
                    showCloseButton
                    isOpen={showModal}
                    onClickClose={() => setShowModal(false)}
                    containerClassName="w-full max-w-[700px]"
                >
                    <form
                        onSubmit={onSubmitSuggestion}
                    >
                        <LabeledTextField
                            name="title"
                            label={t('suggestion.title')}
                            required
                        />
                        <Error>
                            {titleError}
                        </Error>
                        <LabeledTextArea
                            name="text"
                            label={t('suggestion.description')}
                            textAreaProps={{
                                className: 'h-[128px] resize-none',
                            }}
                            required
                        />
                        <p
                            dangerouslySetInnerHTML={{ __html: t('suggestion.submitAsterisk') }}
                            className="mb-5 text-[#7797aa] text-justify"
                        />
                        <div
                            className="flex justify-end"
                        >
                            <Button
                                type="submit"
                            >
                                {t('partnerSubmit')}
                            </Button>
                        </div>
                    </form>
                </TitledModal>
            </>
        );
    } else {
        return null;
    }
};

export default SubmitSuggestionModal;
