import * as API from '../utilities/Api';

export function All(
    roleID: number
) {
    return API.GET(`rolePermission/${roleID}/all`);
}

export function Put(
    roleID: number,
    permissionIDs: Array<number>

) {
    return API.PUT(
        `rolePermission/${roleID}`,
        {
            body: JSON.stringify({
                permissionIDs
            })
        }
    );
}