import { globalContext } from "app/GlobalContext";
import Box from "components/Box";
import Button from "components/Button";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as DevEndpoints from './DevEndpoints';
import * as Dialog from 'components/Dialog';
import Select from "components/OldSelect";
import LabeledSelect from "components/LabeledSelect";

export default function DevView(props: RouteComponentProps) {
    const { globalState } = useContext(globalContext);
    const [s, setS] = useState('d');

    useEffect(() => {
        new Promise(r => setTimeout(r, 1000 * 3))
            .then(() => {
                setS('b');
            });
    }, []);

    return (
        <Box>
            <Button
                onClick={DevEndpoints.NotifyMe}
            >
                Notifiy me!
            </Button>
            <Button
                className="ml-4"
                onClick={DevEndpoints.NotifyMePush}
            >
                Notify me! (push)
            </Button>
            <Button
                onClick={DevEndpoints.ClearMyNotifications}
                className="ml-4"
            >
                Clear notifs on db. (refresh page afterwards)
            </Button>
            <Button
                onClick={async () => {const r = await Dialog.YesNo(
                    <div>test asd ad sadads da sdadas dadad sa dsa dsad asd sad a da dad asd adsa dsada dad sad sad sa dsa d</div>
                ); alert(r);}}
                className="ml-4"
            >
                YesNoDialog
            </Button>
            <div
                className="h-[400px] overflow-y-auto mt-4"
            >
                {[...globalState.notifications]
                    .sort((a, b) => (a.sentOn > b.sentOn ? 0 : -1))
                    .map(x =>
                        <div
                            key={x.notificationReadingID}
                        >
                            {new Date(x.notification.sentOn).toString()} - {x.notification.data.message}
                        </div>
                )}
            </div>
            <LabeledSelect<string>
                className="mt-4"
                onChange={e => console.log(e)}
                name="test"
                label="test"
            >
                <Select.Option
                    value="a"
                    selectedValue={s}
                >
                    a
                </Select.Option>
                <Select.Option
                    value="b"
                    selectedValue={s}
                >
                    b
                </Select.Option>
                <Select.Option
                    value="c"
                    selectedValue={s}
                >
                    c
                </Select.Option>
                <Select.Option
                    value="d"
                    selectedValue={s}
                >
                    d
                </Select.Option>
            </LabeledSelect>
        </Box>
    );
}