import { useReducer, createContext, ReactNode, ReactElement, Dispatch } from "react";
import i18next from "i18next";

export interface GlobalState {
    notifications: Array<any>;
    user: {
        firstName: string,
        language: number,
        permissions: Array<string>,
        isAnonymous: boolean
    };
    suggestionActiveVotesCapped: boolean;
    suggestionActiveVotesCap: number;
    suggestionActiveVoteCount: number;
    suggestionDisplayUnreadFields: boolean;
    isAuthenticated: boolean;
    isAccessToken: boolean;
}

const initialState: GlobalState = {
    notifications: new Array<any>(),
    user: {
        firstName: '',
        language: 0,
        permissions: new Array<string>(),
        isAnonymous: false
    },
    suggestionActiveVotesCapped: true,
    suggestionActiveVotesCap: 3,
    suggestionActiveVoteCount: 0,
    suggestionDisplayUnreadFields: false,
    isAuthenticated: false,
    isAccessToken: false
};

export const Reducer = (state: GlobalState, action: ActionType) => {
    switch(action.type) {

        case 'notifications.clear':
            return {
                ...state,
                notifications: new Array<any>()
            };

        case 'notifications.add':
            return { 
                ...state,
                notifications: [
                    ...state.notifications,
                    ...(Array.isArray(action.payload) ? action.payload : [action.payload])
                ]
            };

        case 'notifications.markAsRead':
            const notification = state.notifications.find(x => x.notificationReadingID == action.payload)
            notification.isRead = true;
            return { ...state };

        case 'notifications.markAllAsRead':
            state.notifications.filter(x => !x.isRead).forEach(x => x.isRead = true)
            return { ...state };

        case 'user.set':
            const user = action.payload;
            i18next.changeLanguage(user.language == 2 ? 'fr' : 'en');
            return {
                ...state,
                user
            };

        case 'user.setLanguage':
            const language = action.payload;
            i18next.changeLanguage(language == 2 ? 'fr' : 'en');
            return {
                ...state,
                user: {
                    ...state.user,
                    language
                }
            };

        case 'suggestion.setActiveVoteCount':
            return {
                ...state,
                suggestionActiveVoteCount: action.payload
            }

        case 'suggestion.addActiveVote':
            return {
                ...state,
                suggestionActiveVoteCount: state.suggestionActiveVoteCount + 1
            }

        case 'suggestion.removeActiveVote':
            return {
                ...state,
                suggestionActiveVoteCount: state.suggestionActiveVoteCount - 1
            }

        case 'suggestion.setActiveVoteSettings':
            return {
                ...state,
                suggestionActiveVotesCapped: action.payload.activeVotesCapped,
                suggestionActiveVotesCap: action.payload.activeVotesCap
            }

        case 'suggestion.setDisplayUnreadFields':
            return {
                ...state,
                suggestionDisplayUnreadFields: action.payload.displayUnreadFields
            }

        case 'isAuthenticated.set':
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isAccessToken: action.payload.isAccessToken
            }

        default:
            return state;
    }
};

export type ActionType = {
    type: 'notifications.clear'
        | 'notifications.add'
        | 'notifications.markAsRead'
        | 'notifications.markAllAsRead'
        | 'user.set'
        | 'user.setLanguage'
        | 'suggestion.setActiveVoteCount'
        | 'suggestion.addActiveVote'
        | 'suggestion.removeActiveVote'
        | 'suggestion.setActiveVoteSettings'
        | 'suggestion.setDisplayUnreadFields'
        | 'isAuthenticated.set'
    payload?: any
}

export type ContextType = {
    globalState: GlobalState;
    dispatch: Dispatch<ActionType>;
};

export const globalContext = createContext({} as ContextType);

export function GlobalContext(
    { children } : { children: ReactNode }
): ReactElement {
    const [globalState, dispatch] = useReducer(Reducer, initialState);

    return (
        <globalContext.Provider
            value={{ globalState, dispatch }}
        >
            {children}
        </globalContext.Provider>
    );
};