import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import Button from 'components/Button';
import Box from 'components/Box';
import { FaKey, FaPaperPlane } from 'react-icons/fa';
import Title from 'components/Title';
import TextField from 'components/TextField';
import Link from 'components/Link';
import * as AccountEndpoints from './CreateAccountEndpoints';

export default function CreateAccountView(props: RouteComponentProps) {
    const { t } = useTranslation();

    const [disabled, setDisabled] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisabled(true);

        const formData = new FormData(event.currentTarget);
        const { result } = await AccountEndpoints.requestAccount(formData.get('email') as string);
        setDisabled(false);

        if (!result?.success) {
            return;
        }

        setEmailSent(true);
    };

    const returnLink = (
        <Link
            to="/login"
        >
            {t('account.return')}
        </Link>
    );

    return (
        <>
            <Box
                className="md:bg-white/80"
            >
                <Title
                    icon={FaKey}
                >
                    {t('account.accountCreationRequest')}
                </Title>
                {!emailSent &&
                    <>
                        <p
                            className="mb-5"
                        >
                            {t('account.messageWillBeSent')}
                        </p>
                        <form
                            onSubmit={onSubmit}
                        >
                            <TextField
                                name="email"
                                placeholder={t('account.email')}
                                required
                                autoFocus
                                className="mb-5"
                            />
                            <div
                                className="flex justify-end items-center gap-6"
                            >
                                {returnLink}
                                <Button
                                    type="submit"
                                    icon={FaPaperPlane}
                                    disabled={disabled}
                                >
                                    {t('account.send')}
                                </Button>
                            </div>
                        </form>
                    </>
                }
                {emailSent &&
                    <>
                        <p
                            className="mb-5"
                        >
                            {t('account.messageHasBeenSent')}
                        </p>
                        <div
                            className="flex justify-end items-center"
                        >
                            {returnLink}
                        </div>
                    </>
                }
            </Box>
        </>
    );
}