import { useTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router';
import { useState, useEffect, useContext } from 'react';
import { FaEye, FaPaperPlane, FaPen, FaPlus, FaTrash } from "react-icons/fa";
import * as Path from '../utilities/Path';
import * as PublicationEndpoints from './PublicationEndpoints';
import * as PublicationActions from './PublicationActions';
import Breadcrumbs from 'components/Breadcrumbs';
import Title from "components/Title";
import Button from "components/Button";
import DataTable, { Media, TableColumn } from "components/DataTable";
import Box from "components/Box";
import { globalContext } from 'app/GlobalContext';
import { Permission } from 'permission/Permission';

export default function PublicationListView(props: RouteComponentProps) {
    const { t } = useTranslation();
    const { globalState } = useContext(globalContext);

    const [rows, setRows] = useState(new Array<TableColumn<any>>());

    const loadPublications = async () => {
        const { result } = await PublicationEndpoints.All();
        if (!result?.success)
            return;

        const rows = result.data.map((publication: any) => ({
            id: publication.publicationID,
            title: publication?.publicationTranslations.find(x => x.langId == globalState.user.language)?.title
        }));
        setRows(rows);
    };

    useEffect(() => {
        loadPublications();
    }, []);

    const onClickDelete = async row => {
        const deleted = await PublicationActions.Delete(row.id);
        if (!deleted)
            return;
        
        await loadPublications();
    };

    const columns: TableColumn<any>[] = [
        {
            name: t('publication.title'),
            selector: row => row.title,
            sortable: true
        },
        {
            cell: row => <>
                <Button
                    variant="link"
                    icon={FaEye}
                    onClick={() => props.history.push(Path.Resolve(props.match.url, 'show', row.id.toString()))}
                >
                    {t('show')}
                </Button>
                <Button
                    variant="link"
                    icon={FaPen}
                    onClick={() => props.history.push(Path.Resolve(props.match.url, 'edit', row.id.toString()))}
                >
                    {t('edit')}
                </Button>
                <Button
                    variant="link"
                    icon={FaTrash}
                    onClick={() => onClickDelete(row)}
                >
                    {t('delete')}
                </Button>
            </>,
            right: true
        }
    ];

    if (!globalState.user.permissions.includes(Permission.Publication_Manage))
        return null;

    return (
        <div
            className="
                m-auto
                max-w-[800px]
            "
        >
            <Breadcrumbs>
                <Breadcrumbs.Item to="/">{t('home.home')}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{t('publication.publications')}</Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-10
                    "
                >
                    <Title
                        icon={FaPaperPlane}
                    >
                        {t('publication.publications')}
                    </Title>
                    <Button
                        icon={FaPlus}
                        onClick={() => props.history.push(Path.Resolve(props.match.url, 'new'))}
                    >
                        {t('publication.newPublication')}
                    </Button>
                </div>
                <DataTable
                    columns={columns}
                    data={rows}
                />
            </Box>
        </div>
    );
}