import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';
import { BsGearFill } from 'react-icons/bs';
import { FaCalendarAlt, FaCheck, FaTimes, FaTrash } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router';
import Breadcrumbs from '../components/Breadcrumbs';
import * as SuggestionEndpoints from './SuggestionEndpoints';
import * as SuggestionActions from './SuggestionActions';
import * as SuggestionCategoryEndpoints from '../suggestionCategory/SuggestionCategoryEndpoints';
import Title from 'components/Title';
import Box from 'components/Box';
import { useEffect, useContext, useRef, useState } from 'react';
import { SuggestionStatus } from './SuggestionStatus';
import LabeledTextField from 'components/LabeledTextField';
import LabeledTextArea from 'components/LabeledTextArea';
import LabeledSelect from 'components/LabeledSelect';
import SelectOption from 'components/OldSelect/SelectOption';
import Button from 'components/Button';
import Separator from 'components/Separator';
import { LabeledTextAreaRef } from 'components/LabeledTextArea/LabeledTextArea';
import { SuggestionCommentStatus } from 'suggestionComment/SuggestionCommentStatus';
import * as SuggestionCommentEndpoints from '../suggestionComment/SuggestionCommentEndpoints';
import Link from 'components/Link';
import { Permission } from "permission/Permission";
import { globalContext } from "app/GlobalContext";

interface SuggestionUnderReviewListViewRouteParams {
}

export default function SuggestionUnderReviewListView(props: RouteComponentProps<SuggestionUnderReviewListViewRouteParams>) {
    const { t } = useTranslation();
    const [categories, setCategories] = useState(new Array());
    const [suggestions, setSuggestions] = useState(new Array());
    const [commentGroups, setCommentGroups] = useState({});

    const loadSuggestionsAndComments = async () => {

        SuggestionEndpoints.All({
            statuses: [SuggestionStatus.UnderReview]
        })
            .then(async response => {
                const { result } = response;
                if (!result?.success)
                    return;
   
                setSuggestions(result.data);
            });

        SuggestionCommentEndpoints.All({
            statuses: [SuggestionCommentStatus.UnderReview]
        }).then(async response => {
            const { result } = response;
            if (!result?.success)
                return;

            const commentGroups = result.data.reduce((resultObj, comment) => {
                (resultObj[comment.suggestion.suggestionID] = resultObj[comment.suggestion.suggestionID] || new Array()).push(comment);
                return resultObj;
            }, {});

            setCommentGroups(commentGroups);
        });
    };

    useEffect(() => {
        loadSuggestionsAndComments();

        SuggestionCategoryEndpoints.All()
            .then(async response => {
                const { result } = response
                if (!result?.success)
                    return;
   
                setCategories(result.data);
            });
    }, []);
    
    return (
        <div
            className="max-w-[1100px] m-auto"
        >
            <Breadcrumbs>
                <Breadcrumbs.Item
                    to="/"
                >
                    {t('home.home')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                    {t('suggestion.suggestionStatuses.0')}
                </Breadcrumbs.Item>
            </Breadcrumbs>
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-5
                    "
                >
                    <Title
                        className={twMerge(
                            suggestions.length > 0 && 'mb-0'
                        )}
                    >
                        {t('suggestion.suggestionStatuses.0')}
                    </Title>
                    <Button
                        variant="outlined"
                        icon={BsGearFill}
                        onClick={() => props.history.push('/suggestiontApprovalSettings')}
                    >
                        {t('settings')}
                    </Button>
                </div>
                {!suggestions.length && (
                    <div>
                        {t('suggestion.noSuggestionsOrCommentsToApprove')}
                    </div>
                )}
            </Box>
            {suggestions.map(suggestion => (
                <Suggestion 
                    key={suggestion.suggestionID}
                    suggestion={suggestion}
                    categories={categories}
                    onApproved={loadSuggestionsAndComments}
                    onDenied={loadSuggestionsAndComments}
                    onDeleted={loadSuggestionsAndComments}
                />
            ))}
            <Box>
                {Object.getOwnPropertyNames(commentGroups).map(suggestionID => (
                    <div
                        key={suggestionID}
                        className="
                            flex flex-1 flex-col justify-between
                            px-4 py-7 lg:px-7
                            mb-8
                            bg-[#eef5fc]
                            order-1 md:order-2
                            border-l-0 md:border-l-4 md:border-b-0
                            border-white
                        "
                    >
                        <div
                            className="
                                flex flex-col md:flex-row justify-between items-start gap-x-4 gap-y-5
                                mb-6
                            "
                        >
                            <Link
                                to={`/suggestions/${suggestionID}`}
                                className="font-bold text-lg"
                            >
                                {commentGroups[suggestionID][0].suggestion.title}
                            </Link>
                            <div
                                className="flex items-center gap-x-2"
                            >
                                <FaCalendarAlt 
                                    className="text-[#4dc7f5]"
                                />
                                {new Date(commentGroups[suggestionID][0].suggestion.suggestedOn).toLocaleDateString()}
                            </div>
                        </div>
                        <p
                            className="mb-8"
                        >
                            {commentGroups[suggestionID][0].suggestion.text}
                        </p>
                        {commentGroups[suggestionID].map(comment => (
                            <Comment
                                key={comment.suggestionCommentID}
                                comment={comment}
                                onApproved={loadSuggestionsAndComments}
                                onDeleted={loadSuggestionsAndComments}
                            />
                        ))}
                    </div>
                ))}
            </Box>
        </div>
    );
}

function Suggestion({
    suggestion,
    categories,
    onApproved = () => {},
    onDenied = ()=> {},
    onDeleted = () => {}
}: {
    suggestion: any;
    categories: Array<any>;
    onApproved?(suggestion: any): void;
    onDenied?(suggestion: any): void;
    onDeleted?(suggestion: any): void;
    }) {
    const { globalState } = useContext(globalContext);
    const { t } = useTranslation();
    const formElement = useRef<HTMLFormElement>(null);
    const denyReasonElement = useRef<LabeledTextAreaRef>(null);

    const getFormData = () => {
        const formData = new FormData(formElement.current!);
        return {
            suggestionID: suggestion.suggestionID,
            title: formData.get('title') as string,
            suggestionCategoryID: Number(formData.get('category')),
            text: formData.get('text') as string,
            denyReason: formData.get('denyReason') as string,
            adminNote: formData.get('adminNote') as string
        };
    };

    const onClickApprove = async () => {
        denyReasonElement?.current && denyReasonElement?.current.removeAttribute('required');

        if (!formElement.current?.reportValidity())
            return;

        const { result } = await SuggestionEndpoints.Approve(getFormData());
        if (!result?.success) {
            result?.message && toast.error(t(result.message));
            return;
        }

        toast.success(t('suggestion.suggestionApproved'));
        await onApproved(suggestion);
    };

    const onClickSaveAdminNote = async () => {
        const { result } = await SuggestionEndpoints.SaveAdminNote(getFormData());
        if (!result?.success) {
            result?.message && toast.error(t(result.message));
            return;
        }

        toast.success(t('suggestion.noteSaved'));
        await onApproved(suggestion);
    };

    const onClickDeny = async () => {
        denyReasonElement?.current && denyReasonElement?.current.setAttribute('required', '');
        if (!formElement.current?.reportValidity())
            return;

        const { result } = await SuggestionEndpoints.Deny(getFormData());
        if (!result?.success) {
            result?.message && toast.error(t(result.message));
            return;
        }

        toast.success(t('suggestion.suggestionDenied'));
        await onDenied(suggestion);
    };
    
    const onClickDelete = async () => {
        const deleted = await SuggestionActions.Delete(suggestion.suggestionID);
        if (!deleted)
            return;

        await onDeleted(suggestion);
    };

    return <Box>
        <form
            onSubmit={event => event.preventDefault()}
            ref={formElement}
        >
            <div
                className="mb-5"
            >
                {globalState.user.permissions.includes(Permission.Suggestion_Review) &&
                    <div
                        className="inline font-bold"
                    >
                        #{`${suggestion.suggestionID}`},&nbsp;
                    </div>
                }
                {t('suggestion.submittedBy')}&nbsp;
                <div
                    className="inline font-bold"
                >
                    {`${suggestion.suggestedBy.firstName} ${suggestion.suggestedBy.lastName}`}
                </div>
                &nbsp;{t('suggestion.from')}&nbsp;
                <div
                    className="inline font-bold"
                >
                    {`${suggestion.suggestedBy.clientName}`}
                </div>
            </div>
            <LabeledTextField
                label={t('suggestion.title')}
                name="title"
                defaultValue={suggestion.title}
                required
            />
            <LabeledSelect
                label={t('suggestionCategory.category')}
                name="category"
                required
            >
                {categories.map(category => (
                    <SelectOption<number>
                        key={category.suggestionCategoryID}
                        value={category.suggestionCategoryID}
                        selectedValue={suggestion.category?.suggestionCategoryID}
                    >
                        {category.name}
                    </SelectOption>
                ))}
            </LabeledSelect>
            <LabeledTextArea
                label={t('suggestion.description')}
                name="text"
                defaultValue={suggestion.text}
                textAreaProps={{
                    className: 'h-[128px] resize-none',
                }}
                required
            />
            {globalState.user.permissions.includes(Permission.Suggestion_Review) ?
                [
                    <LabeledTextArea
                        label={t('suggestion.adminNote')}
                        name="adminNote"
                        defaultValue={suggestion.adminNote}
                        textAreaProps={{
                            className: 'h-[64px] resize-none',
                        }}
                    />,
                    <Button
                        icon={FaCheck}
                        onClick={onClickSaveAdminNote}
                        >
                        {t('suggestion.saveNote')}
                    </Button>
                ] : null
            }
            <Separator />
            <LabeledTextArea
                label={t('suggestion.denyReason')}
                name="denyReason"
                textAreaProps={{
                    className: 'h-[128px] resize-none',
                }}
                ref={denyReasonElement}
            />
            <div
                className="flex flex-wrap justify-end gap-2.5 2xl:gap-6"
            >
                <Button
                    icon={FaCheck}
                    onClick={onClickApprove}
                >
                    {t('approve')}
                </Button>
                <Button
                    icon={FaTimes}
                    variant="outlined"
                    onClick={onClickDeny}
                >
                    {t('deny')}
                </Button>
                <Button
                    variant="link"
                    icon={FaTrash}
                    onClick={onClickDelete}
                >
                    {t('delete')}
                </Button>
            </div>
        </form>
    </Box>
}

function Comment({
    comment,
    onApproved = () => {},
    onDeleted = () => {}
}: {
    comment: any,
    onApproved?(suggestion: any): void;
    onDeleted?(suggestion: any): void;
}) {
    const { t } = useTranslation();

    const onClickApprove = async () => {
        const { result } = await SuggestionCommentEndpoints.Approve(comment.suggestionCommentID);
        if (!result?.success) {
            result?.message && toast.error(t(result.message));
            return;
        }

        toast.success(t('suggestionComment.commentApproved'));
        await onApproved(comment);
    };

    const onClickDelete = async () => {
        // TODO: confirm message
        // TODO: turn into action
        const { result } = await SuggestionCommentEndpoints.Delete(comment.suggestionCommentID);
        if (!result?.success)
            return;

        toast.success(t('suggestionComment.commentDeleted'));
        await onDeleted(comment);
    };

    return (
        <Box>
            <div
                className="mb-5"
            >
                {t('suggestionComment.commentedBy')}&nbsp;
                <div
                    className="inline font-bold"
                >
                    {`${comment.commentedBy.firstName} ${comment.commentedBy.lastName}`}
                </div>
            </div>
            <LabeledTextArea
                name="text"
                label={t('suggestionComment.comment')}
                defaultValue={comment.text}
                disabled
            />
            <div
                className="flex flex-wrap justify-end gap-2.5 2xl:gap-6"
            >
                <Button
                    icon={FaCheck}
                    onClick={onClickApprove}
                >
                    {t('approve')}
                </Button>
                <Button
                    variant="link"
                    icon={FaTrash}
                    onClick={onClickDelete}
                >
                    {t('delete')}
                </Button>
            </div>
        </Box>
    );
}