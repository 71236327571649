import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import Breadcrumbs from '../components/Breadcrumbs';
import * as SuggestionEndpoints from './SuggestionEndpoints';
import Title from 'components/Title';
import Box from 'components/Box';
import { FaList } from 'react-icons/fa';
import Suggestion from './Suggestion';
import { globalContext } from 'app/GlobalContext';
import SuggestionVoteCount from './SuggestionVoteCount';
import { Permission } from 'permission/Permission';
import SubmitSuggestionModal from './SubmitSuggestionModal';
import SuggestionStatusFilter from './StatusFilter';
import { allSuggestionStatuses, SuggestionStatus } from './SuggestionStatus';

export default function MySuggestionListView(props: RouteComponentProps) {
    const { globalState, dispatch } = useContext(globalContext);
    const { t } = useTranslation();

    const defaultStatuses = [
        SuggestionStatus.Open,
        SuggestionStatus.UnderAnalysis,
        SuggestionStatus.Planned,
        SuggestionStatus.UnderDevelopment,
        SuggestionStatus.Completed,
    ];

    const [suggestions, setSuggestions] = useState(new Array());
    const [statusFilter, setStatusFilter] = useState(defaultStatuses);

    const loadSuggestions = async () => {
        
        const { result } = await SuggestionEndpoints.All({
            orderBy: 'date',
            onlySuggestedByUser: true,
            statuses: statusFilter
        });

        if (!result?.success)
            return;

        setSuggestions(result.data);
    };

    useEffect(() => {
        loadSuggestions();
    }, [statusFilter]);
    
    return (
        <div
            className="max-w-[1100px] m-auto"
        >
            <Breadcrumbs>
                <Breadcrumbs.Item
                    to="/"
                >
                    {t('home.home')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item
                    to="/suggestions"
                >
                    {t('suggestion.suggestions')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                    {t('suggestion.mySuggestions')}
                </Breadcrumbs.Item>
            </Breadcrumbs>
            {globalState.user.permissions.includes(Permission.Suggestion_Write) && <SuggestionVoteCount />}
            <Box>
                <div
                    className="
                        flex flex-row justify-between items-center flex-wrap
                        mb-10
                    "
                >
                    <Title
                        icon={FaList}
                    >
                        {t('suggestion.mySuggestions')}
                    </Title>
                    <div
                        className="
                            mb-5
                            flex items-center justify-end gap-x-2.5 2xl:gap-x-6 gap-y-2 flex-wrap
                        "
                    >
                        <SubmitSuggestionModal
                            onSubmit={loadSuggestions}
                        />
                    </div>
                </div>
                <div
                        className="
                            mb-10
                            flex items-center justify-start gap-x-2.5 2xl:gap-x-6 gap-y-4 flex-wrap
                        "
                    >
                        <SuggestionStatusFilter
                            statuses={allSuggestionStatuses}
                            InitialSelectedStatuses={defaultStatuses}
                            onFilterChange={statuses => setStatusFilter(statuses)}
                        />
                    </div>
                <div>
                    {suggestions.map(suggestion => (
                        <Suggestion
                            key={suggestion.suggestionID}
                            suggestion={suggestion}
                            onDeleted={loadSuggestions}
                            className="mb-8"
                        />
                    ))}
                </div>
            </Box>
        </div>
    );
}