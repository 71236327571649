import { ArraySchema } from 'yup';
import * as API from '../utilities/Api';
import { SuggestionStatus } from './SuggestionStatus';

export function All(
    args: {
        suggestionCategoryID?: number,
        statuses?: Array<SuggestionStatus>,
        orderBy?: 'date' | 'popularity',
        onlySuggestedByUser?: boolean,
        onlyShownOnRoadmap?: boolean,
        onlyShowUnread?: boolean
    }
) {
    args = {
        statuses: new Array(),
        orderBy: 'date',
        onlySuggestedByUser: false,
        onlyShownOnRoadmap: false,
        onlyShowUnread: false,
        ...args
    }

    const { statuses, orderBy, suggestionCategoryID, onlySuggestedByUser, onlyShownOnRoadmap, onlyShowUnread } = args as Required<typeof args>;

    const params = new URLSearchParams();
    statuses.forEach(status => params.append('statuses', status.toString()));
    params.append('orderby', orderBy);
    params.append('onlySuggestedByUser', onlySuggestedByUser ? 'true' : 'false');
    params.append('onlyShownOnRoadmap', onlyShownOnRoadmap ? 'true' : 'false');
    params.append('onlyShowUnread', onlyShowUnread ? 'true' : 'false');

    return API.GET(`suggestion/all/${suggestionCategoryID !== undefined ? suggestionCategoryID : ''}?${params}`);
}

export function Get(suggestionID: number) {
    return API.GET(`suggestion/${suggestionID}`);
}

export function GetSuggestionReadUnread() {
    return API.GET(`suggestion/getSuggestionReadUnread`);
}

export function Post(
    title: string,
    text: string
) {
    return API.POST(
        `suggestion`,
        {
            body: JSON.stringify({
                title,
                text
            })
        }
    );
}

export function Put(
    suggestionID: number,
    title: string,
    text: string
) {
    return API.PUT(
        `suggestion`,
        {
            body: JSON.stringify({
                suggestionID,
                title,
                text
            })
        }
    );
}

export function Approve(
    args: {
        suggestionID: number,
        title: string,
        suggestionCategoryID: number,
        text: string
    }
)
{
    return API.POST(
        'suggestion/approve',
        {
            body: JSON.stringify(args)
        }
    );
}

export function SaveAdminNote(
    args: {
        suggestionID: number,
        adminNote: string
    }
) {
    return API.POST(
        'suggestion/saveAdminNote',
        {
            body: JSON.stringify(args)
        }
    );
}

export function Deny(
    args: {
        suggestionID: number,
        denyReason: string
    }
)
{
    return API.POST(
        'suggestion/deny',
        {
            body: JSON.stringify(args)
        }
    );
}

export function Delete(
    suggestionID: number
) {
    return API.DELETE(
        `suggestion/${suggestionID}`
    );
}

export function UpdateStatus(
    args: {
        suggestionID: number,
        status: SuggestionStatus,
        suggestionCategoryID: number,
        showOnRoadmap: boolean,
        links: Array<{
            suggestionLinkID?: number;
            title?: string;
            link: string;
        }>,
        title: string,
        text: string,
        denyReason?: string,
        workOrder: string,
        newsUrl: string,
        adminNote: string
    }
) {
    return API.POST(
        `suggestion/${args.suggestionID}/updateStatus`,
        {
            body: JSON.stringify({
                status: args.status,
                suggestionCategoryID: args.suggestionCategoryID,
                showOnRoadmap: args.showOnRoadmap,
                links: args.links,
                title: args.title,
                text: args.text,
                denyReason: args.denyReason,
                workOrder: args.workOrder,
                newsUrl: args.newsUrl,
                adminNote: args.adminNote
            })
        }
    );
}

export function MarkAsRead(
    suggestionID: number
) {
    return API.POST(
        `suggestion/markAsRead/${suggestionID}`
    );
}

export function MarkAllAsRead() {
    return API.POST(
        `suggestion/markAllAsRead`
    );
}