import { Fragment, PropsWithChildren, ReactElement } from "react";

export type ChildSeparatorProps = PropsWithChildren<{
    separator?: ReactElement;
}>;

const ChildSeparator = ({
    separator,
    children
}: ChildSeparatorProps) => {

    if (!Array.isArray(children))
        return (
            <>
                {children}
            </>
        );

    const _children = children.filter(child => !!child);
    return (
        <>
            {_children.flatMap((child, i) => {
                return [
                    child,
                    i !== _children.length - 1 && (
                        <Fragment key={i}>
                            {separator}
                        </Fragment>
                    )
                ];
            })}
        </>
    );
};

ChildSeparator.displayName = 'ChildSeparator';

export default ChildSeparator;