export enum Permission {

    // client
    Client_Manage = 'Portal.Client.Manage',

    // role
    Role_Manage = 'Portal.Role.Manage',

    // user
    User_Manage = 'Portal.User.Manage',

    // suggestion category
    SuggestionCategory_Manage = 'Portal.SuggestionCategory.Manage',

    // suggestion
    Suggestion_Review = 'Portal.Suggestion.Review',
    Suggestion_Write = 'Portal.Suggestion.Write',
    Suggestion_Read = 'Portal.Suggestion.Read',
    Suggestion_ProjectManager = 'Portal.Suggestion.ProjectManager',

    // publication
    Publication_Manage = 'Portal.Publication.Manage',
};