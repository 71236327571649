import React, { forwardRef, useState } from "react";
import { IconBaseProps } from "react-icons";
import { ImSpinner7 } from 'react-icons/im';
import { twMerge } from 'tailwind-merge';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon?: React.FC<IconBaseProps>;
    iconClassName?: string;
    variant?: 'default' | 'outlined' | 'link';
    visible?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            type = 'button',
            children,
            icon,
            iconClassName: iconClass,
            onClick: click,
            variant = 'default',
            className,
            visible = true,
            ...props
        },
        ref
    ): JSX.Element => {
        const [processing, setProcessing] = useState(false);

        const Icon = icon;
        const iconClassName = twMerge(
            `
                w-4 h-4
                transition-colors duration-300
            `,
            props.disabled && 'fill-[#8A9198] text-[#8A9198]',

            // default
            variant == 'default' && !props.disabled && 'fill-[#4dc7f5] text-[#4dc7f5]',
            variant == 'default' && !props.disabled && `group-hover:fill-white group-hover:text-white`,
            
            // outlined
            variant == 'outlined' && 'fill-[#4dc7f5] text-[#4dc7f5]',
            variant == 'outlined' && props.disabled && 'fill-[#8A9198aa] text-[#8A9198aa]',

            // link
            variant == 'link' && 'w-3 h-3',

            iconClass
        );

        const onClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!click)
                return;

            if (processing)
                return;

            setProcessing(true);
            await click(event);
            setProcessing(false);
        };

        if (!visible)
            return <></>;

        return (
            <button
                {...props}
                ref={ref}
                type={type}
                className={twMerge(
                    `
                        inline-flex items-center justify-center gap-x-2
                        relative

                        px-[0.9em] py-[0.5em]
                        lg:py-[1.1em]
                        border-0

                        text-sm font-bold text-white

                        transition-all duration-300
                        group
                    `,

                    // default
                    variant == 'default' && 'bg-[#3d6a86] disabled:bg-[#BCC3CA]',
                    variant == 'default' && !props.disabled && 'hover:bg-[#4dc7f5]',

                    // outlined
                    variant == 'outlined' && `
                        bg-white disabled:bg-white
                        border-4 
                        border-[#eef5fc] disabled:border-[#e4e7eaaa]
                        text-[#3d6a86] disabled:text-[#3d6a86aa]

                        py-[calc(0.5em_-_4px)]
                        lg:py-[calc(1.1em_-_4px)]
                    `,
                    variant == 'outlined' && !props.disabled && 'hover:bg-[#eef5fc]',

                    // link
                    variant == 'link' && `
                        text-[#84aec7] disabled:text-[#8A9198]
                        hover:underline
                    `,

                    className,
                )}
                onClick={onClick}
            >
                {
                    Icon && !processing &&
                    <Icon
                        className={iconClassName}
                    />
                }
                {
                    processing &&
                    <ImSpinner7
                        className={`${iconClassName} animate-spin`}
                    />
                }
                {children}
            </button>
        );
    }
);

Button.displayName = 'Button';

export default Button;